import React from "react";
import { Image } from "react-bootstrap";
import DataCard from "components/Site/DataCard";
import DataList from "components/Site/DataList";
import { automatic_double_side_sticker_labelling_machine } from "assets/images/equipments";
import BulletPointContainer, { VARIANTS } from "components/Commons/BulletPoint";

const AutomaticDoubleSideStickerLabellingMachineData = () => {

    const keyFeatures = [
        <div><b>Versatile Double Side Labelling:</b> Designed for precise double-side labelling on flat, oval, or square bottles made of materials such as PET, Plastic, and Glass.</div>,
        <div><b>Microprocessor Control:</b> The machine is equipped with advanced microprocessor control for accurate and user-friendly operation.</div>,
        <div><b>Sturdy Main Structure:</b> Constructed with a robust main structure, typically made of stainless steel (SS 304), ensuring durability and stability.</div>,
        <div><b>Product Handling Components:</b> Features a product in-feed screw and a product transmission belt with guide adjustment, facilitating efficient handling of bottles.</div>,
        <div><b>Top Product Holding Conveyor:</b> Includes a top product holding conveyor with height adjustment, allowing proper alignment during the labelling process.</div>,
        <div><b>Dual Label Dispensing Units:</b> Utilizes two label dispensing units for simultaneous application of labels on both sides of the bottles, enhancing efficiency.</div>,
        <div><b>Label Pasting Unit:</b> Incorporates a label pasting unit to ensure accurate and secure adhesion of labels onto the designated areas of the bottles.</div>,
        <div><b>Motorized Components:</b> Utilizes motors for various functions, contributing to the overall automation and reliability of the machine.</div>,
        <div><b>Sensors and AC Drives:</b> Equipped with sensors for precise detection and AC drives for variable speed control, providing operational flexibility.</div>,
        <div><b>Electrical Panel:</b> Features an electrical panel housing essential components for controlling and monitoring the machine's functions.</div>,
        <div><b>Emergency Switch:</b> Includes an emergency switch for immediate halting of operations in case of unforeseen situations, prioritizing safety.</div>,
        <div><b>PLC/HMI Control System:</b> Utilizes a PLC (Programmable Logic Controller) with an HMI (Human-Machine Interface) for seamless and user-friendly control.</div>,
    ];

    return (
        <>
            <div className="text-center">
                <Image className="w-50 my-3" src={automatic_double_side_sticker_labelling_machine} alt="Lab Blender Equipment" style={{ borderRadius: "10px" }} />
            </div>
            {/* <DataCard
                title="Key Features"
                component={<DataList dataList={keyFeatures} />}
            /> */}
            <BulletPointContainer
                heading={<div className="text-start">{"Key Features"}</div>}
                bulletPointArray={keyFeatures.map(r => ({ description: r }))}
                variant={VARIANTS.BULLETS}
                numberOfColumns={2}
                justify="flex-start"
                lastRowFullWidth={true}
            />
            <div>
                In summary, the Automatic Double Side (Sticker) Labelling machine combines versatility,
                durability, and intelligent control systems to meet the specific requirements of double-side
                labelling on various bottle shapes and profiles.
            </div>
        </>
    );
};

export default AutomaticDoubleSideStickerLabellingMachineData;
