import React from "react";
import { Image } from "react-bootstrap";
import DataCard from "components/Site/DataCard";
import DataList from "components/Site/DataList";
import { semi_automatic_tube_filling_machine } from "assets/images/equipments";
import BulletPointContainer, { VARIANTS } from "components/Commons/BulletPoint";

const SemiAutomaticTubeFillingMachineData = () => {

    const keyFeatures = [
        "Tube Cooling System: Incorporates a water pump to circulate water around the tube, enhancing the sealing quality by cooling.",
        "Quick Changeover: Enables swift transition from filling aluminium tubes to lami tubes, ensuring operational efficiency.",
        "Tail - Free Filling: Ensures no tails are left after the filling process, contributing to a clean and precise operation.",
        "Quality Material Construction: All contact parts crafted from high - quality materials, offering options such as S.S. 304, 316, or 316L based on your requirements.",
        "Production Counter: Features a production counter on the control panel, providing a clear indication of the number of tubes filled for efficient monitoring.",
        "Low Maintenance: Designed for lower maintenance requirements, ensuring prolonged and hassle - free operation.",
        "Automatic Tube Ejection: Streamlines the process with automatic tube ejection, enhancing operational efficiency and reducing manual intervention.",
    ];

    const optionalFeatures = [
        "Jacketed Hopper with Stirrer: Adds the option of a jacketed hopper with a stirrer, enhancing temperature control and material consistency during the filling process.",
        "Tube Orientation with I Mark: Includes the feature of tube orientation with an I mark, ensuring precise alignment for accurate filling and coding.",
        "Increased Filling Capacity: Offers the flexibility to increase filling capacity to 300 gm, catering to a wider range of product requirements.",
    ]

    return (
        <div className="text-justify">
            <div>
                <Image className="float-right ms-2" src={semi_automatic_tube_filling_machine} alt="Lab Blender Equipment" style={{ borderRadius: "10px", width: 320, height: "100%" }} />
                <div>
                    This tube filling machine is a comprehensive solution for a variety of materials and applications. The incorporation of auto and semi-auto tube filling machines, capable of handling high viscosity materials such as paint, adhesive, cosmetics, lotion, toothpaste, shampoo, demonstrates its versatility.
                </div>
                <br />
                <div>
                    The construction of the machine body using SS pipes and plates, painted to a smooth finish and covered with stainless steel, ensures durability and cleanliness. The use of Stainless Steel 304 for filling area contact parts, including the hopper, adds to the quality and hygiene of the machine.
                </div>
            </div>
            <br />
            <div>
                The mechanical drive with a positive suction pump assembly for the filling mechanism, along with the provision of different syringes for various fill volumes, showcases the adaptability of the machine. The incorporation of no-tube-no-filling sensors connected to a pneumatic cylinder ensures precise and efficient filling.
            </div>

            <br />
            <div>
                The heating process with a standard heater, capable of reaching temperatures up to 700°C with constant airflow, highlights the precision in sealing. The mechanism of lifting the tube inside the heater for targeted heating without affecting the filled material is a thoughtful design choice. The sealing and trimming processes with pneumatic jaws and blades contribute to a smooth and efficient finishing touch.
            </div>
            <br />
            <div>
                Overall, your machine seems well-equipped to handle a diverse range of materials and deliver reliable performance in the tube filling process.
            </div>
            <div className="text-center">
                <Image className="w-75 d-md-none" src={semi_automatic_tube_filling_machine} alt="Lab Blender Equipment" style={{ borderRadius: "10px" }} />
            </div>
            {/* <DataCard
                title="Key Features"
                component={<DataList dataList={keyFeatures} />}
            /> */}
            <BulletPointContainer
                heading={<div className="text-start">{"Key Features"}</div>}
                bulletPointArray={keyFeatures.map(r => ({ description: r }))}
                variant={VARIANTS.BULLETS}
                numberOfColumns={2}
                justify="flex-start"
                lastRowFullWidth={true}
            />
            {/* <DataCard
                title="Optional Features"
                component={<DataList dataList={optionalFeatures} />}
            /> */}
            <BulletPointContainer
                heading={<div className="text-start">{"Optional Features"}</div>}
                bulletPointArray={optionalFeatures.map(r => ({ description: r }))}
                variant={VARIANTS.BULLETS}
                numberOfColumns={2}
                justify="flex-start"
                lastRowFullWidth={true}
            />
        </div>
    );
};

export default SemiAutomaticTubeFillingMachineData;
