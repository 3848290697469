import BulletPointContainer, { VARIANTS } from "components/Commons/BulletPoint";
import React from "react";

const ArchitecturalAndCivilData = () => {
    return (
        <div className="text-justify">
            {/* <Image src="" alt="" /> */}
            <div>
                The comprehensive master plan encompasses all proposed structures on the site,
                considering future spaces and the strategic positioning of buildings, roadways,
                and site preparation. This plan is meticulously crafted based on the program
                requirements, with a focus on developing efficient site logistics. The conceptualization
                phase holds utmost significance in our projects, as critical decisions are made
                that ultimately determine the project's success.
            </div>
            <br />
            <div>
                With expertise in designing and executing civil activities in the pharmaceutical
                sector and various other industries, we bring a wealth of experience to the table.
                Our track record includes the successful completion of industrial construction
                projects, showcasing our commitment to delivering top-notch workmanship
                and meeting client timelines with precision.
            </div>
            <br />
            <div>
                Explore our diverse range of services encompassing Architectural, Civil,
                and Structural expertise:
                <BulletPointContainer
                    variant={VARIANTS.BULLETS}
                    numberOfColumns={1}
                    bulletPointArray={[
                        {
                            title: "Civil Engineering Design",
                            description: "Crafting meticulous plans for civil infrastructure to ensure functionality and stability."
                        },
                        {
                            title: "Structural Engineering Design",
                            description: "Designing robust and efficient structures to meet safety and performance standards."
                        },
                        {
                            title: "On-Site Surveys",
                            description: "Conducting detailed assessments of project sites to inform the design and planning process."
                        },
                        {
                            title: "Construction Observations",
                            description: "Overseeing construction activities to ensure adherence to design specifications and quality standards."
                        },
                        {
                            title: "Conceptual Design Services",
                            description: "Providing creative and strategic design concepts to lay the foundation for the project."
                        },
                        {
                            title: "Cost Estimating",
                            description: "Estimating project costs accurately to facilitate budget planning and management."
                        },
                        {
                            title: "Permit Drawings for Governmental Agency Reviews",
                            description: "Creating drawings and documentation necessary for regulatory approvals."
                        },
                        {
                            title: "Code Review/Compliance Review",
                            description: "Ensuring designs comply with relevant building codes and regulations."
                        },
                        {
                            title: "Construction Specifications",
                            description: "Clearly defining materials, methods, and standards for construction processes."
                        },
                        {
                            title: "Interior Design",
                            description: "Crafting appealing and functional interior spaces that align with the overall project vision."
                        },
                        {
                            title: "Service Integration",
                            description: "Coordinating and integrating various services for a seamless and cohesive project execution."
                        },
                        
                    ]}
                />
            </div>
            <div>
                Each of these services is tailored to contribute to the success and efficiency
                of your project, addressing both aesthetic and functional considerations.
            </div>
        </div>
    );
};

export default ArchitecturalAndCivilData;
