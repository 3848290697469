import React from "react";
import { Image } from "react-bootstrap";
import DataCard from "components/Site/DataCard";
import DataList from "components/Site/DataList";
import { volumetric_liquid_vial_filling_with_rubber_stoppering_machine } from "assets/images/equipments";
import BulletPointContainer, { VARIANTS } from "components/Commons/BulletPoint";

const VolumetricLiquidVialFillingwithRubberStopperingMachineData = () => {

    const keyFeatures = [
        "S.S. Frame Structure (GMP Model) ensures compliance with Good Manufacturing Practices.",
        "Filling accuracy better than 1%, ensuring precision in dosage.",
        "Contact Parts crafted from SS-316, ensuring durability and adherence to quality standards.",
        `Variable Speed Infeed Turn Table (30" Dia.) for flexible and efficient vial handling.`,
        `PLC with MMI- 3.8" Mono Screen for intuitive and user-friendly operation.`,
        "High Precision Manufactured Syringes (10 Nos.) for consistent and reliable performance.",
        "Servo Motor-driven Syringes, contributing to enhanced accuracy and efficiency.",
        "Fill volume setting through PLC, allowing for easy customization.",
        "Separate Variable Speed Conveyor for optimized production flow.",
        "Pre & Post Gassing System for product freshness and longevity.",
        "Disc Type Rubber Stoppering System (Vacuum-Based) for versatile stoppering solutions.",
        "No Vial - No Fill Arrangement for process integrity and waste reduction.",
        "Suitable for Plain as well as Slotted Stoppers, facilitated by change parts.",
        "Acrylic Cabinet (optional), providing an additional layer of visibility and aesthetic appeal.",
        "Vacuum pump for Rubber Stoppering Unit (optional), enhancing the stoppering process.",
        "Extra Servo System for Syringe (optional), offering additional control and precision.",
    ];

    return (
        <div className="text-justify">
            <div>
                The Automatic Volumetric Liquid Vial Filling with Rubber Stoppering Machine is designed for round vials, offering a maximum speed of 240 vials per minute. The operational efficiency depends on factors such as the nature of liquids, vial neck size, fill volume, and rubber stopper size. The machine features built-in turntables at both the in-feed and out-feed stages, each equipped with individual drives to synchronize with the speed of incoming and outgoing vials, ensuring a seamless and efficient operation.
            </div>
            <div>
                Additionally, this machine is available with a vacuumetric-type Rubber Stoppering System, providing a versatile solution to meet specific production requirements.
            </div>
            <div className="d-flex justify-content-center my-3">
                <Image className="d-none d-md-block" src={volumetric_liquid_vial_filling_with_rubber_stoppering_machine} alt="Lab Blender Equipment" style={{ borderRadius: "10px", width: 400 }} />

            </div>
            {/* <br />
            <DataCard
                title="Key Features"
                component={<DataList dataList={keyFeatures} />}
            /> */}
            <BulletPointContainer
                heading={<div className="text-start">{"Key Features"}</div>}
                bulletPointArray={keyFeatures.map(r => ({ description: r }))}
                variant={VARIANTS.BULLETS}
                numberOfColumns={2}
                justify="flex-start"
                lastRowFullWidth={true}
            />
            <div className="text-center d-md-none">
                <Image className="w-75" src={volumetric_liquid_vial_filling_with_rubber_stoppering_machine} alt="Lab Blender Equipment" style={{ borderRadius: "10px" }} />
            </div>
        </div>
    );
};

export default VolumetricLiquidVialFillingwithRubberStopperingMachineData;
