const Map = () => {
    return (
        // <iframe
        //     title="Proactimo"
        //     src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15068.303230835207!2d72.8311471133392!3d19.235527662032666!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7b0d883d894b7%3A0xdff4349174715e43!2sBorivali%2C%20Mumbai%2C%20Maharashtra%20400091!5e0!3m2!1sen!2sin!4v1699882698871!5m2!1sen!2sin"
        //     width="100%"
        //     height="400"
        //     style={{ border: 0 }}
        //     allowFullScreen=""
        //     loading="lazy"
        //     referrerPolicy="no-referrer-when-downgrade"
        // ></iframe>
        <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d235.4472752449926!2d72.82878847204935!3d19.232014243143343!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7b138655f86a1%3A0xab8bf583c7bb3b3c!2sZoom%20Plaza%20Mall%2C%20Gorai%201%2C%20Borivali%2C%20Mumbai%2C%20Maharashtra%20400092!5e0!3m2!1sen!2sin!4v1700915434061!5m2!1sen!2sin"
            width="100%"
            height="375"
            style={{ border: 0 }}
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
    );
};

export default Map;
