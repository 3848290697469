import React from "react";
import { Image } from "react-bootstrap";
import DataCard from "components/Site/DataCard";
import DataList from "components/Site/DataList";
import { vibro_sifter } from "assets/images/equipments";
import BulletPointContainer, { VARIANTS } from "components/Commons/BulletPoint";

const VibroSifterData = () => {

    const keyFeatures = [
        "All contact parts made of stainless steel (SS).",
        "Designed for quick and easy dismantling of contact parts for cleaning without hassle.",
        "Portable, mounted on sturdy PU wheels with SS 304 for convenient mobility.",
        "Silicon-molded SS 316 screen (40 mesh) with a viewing glass.",
    ];

    const optionalEnhancements = [
        "Anti-blinding arrangement for enhanced performance.",
        "Silicon/Teflon balls for specific applications.",
        "Motor mounting plate for added stability.",
        "Magnetic grill separator at charging/discharging port for material purity.",
        "Double and triple deck options available for customized use.",
        "Air vent for improved ventilation.",
    ];

    return (
        <>
            <div>
                The Vibro Sifter machine operates on the principle of gyratory vibrations to effectively separate materials based on their particle size. Here's how it works:
            </div>
            <BulletPointContainer
                heading={<div className="text-start">The Combo-Feeder, an optimized feeding system, introduces several enhancements to the compaction process:</div>}
                justify="flex-start"
                variant={VARIANTS.CARD}
                hideNumbers={false}
                numberOfColumns={3}
                lastRowFullWidth={true}
                bulletPointArray={[
                    {
                        title: "Gyratory Vibrations",
                        description: (
                            <ul className="ps-4 mb-2">
                                <li className="text-start">
                                    The machine utilizes gyratory vibrations to separate materials.
                                </li>
                                <li className="text-start">
                                    When the motor is energized, it induces vibrations in the screen or sieve.
                                </li>
                            </ul>
                        )
                    },
                    {
                        title: "Particle Size Separation",
                        description: (
                            <ul className="ps-4 mb-2">
                                <li className="text-start">
                                    Material travels across the sieves according to its particle size in response to the vibratory motion.
                                </li>
                                <li className="text-start">
                                    The sieves are designed to allow only particles of a certain size to pass through.
                                </li>
                            </ul>
                        )
                    },
                    {
                        title: "Three-Plane Vibration:",
                        description: (
                            <ul className="ps-4 mb-2">
                                <li className="text-start">
                                    The machine generates vibrations along three different planes.
                                </li>
                                <li className="text-start">
                                    This is achieved using a specially designed vibratory motor to meet various output requirements.u
                                </li>
                            </ul>
                        )
                    },
                    {
                        title: "Rotary Motion and Dispersion:",
                        description: (
                            <ul className="ps-4 mb-2">
                                <li className="text-start">
                                    The vibratory motor causes 100% rotary motion, leading to the dispersion, stirring, and stratification of the material to sift.
                                </li>
                            </ul>
                        )
                    },
                    {
                        title: "Horizontal Movement:",
                        description: (
                            <ul className="ps-4 mb-2">
                                <li className="text-start">
                                    The feed material flows horizontally along with a loop pattern on the Vibro screen.
                                </li>
                            </ul>
                        )
                    },
                ]}
            />
            <div className="text-justify">
                <div>
                    <div>
                        The Vibro Sifter machine's gyratory vibrations, three-plane motion, and rotary
                        motion contribute to efficient material separation, making it a versatile tool for
                        various industries requiring precise particle size classification.
                    </div>
                    <div className="text-center my-3">
                        <Image src={vibro_sifter} alt="Lab Blender Equipment" style={{ borderRadius: "10px", width: 220 }} />
                    </div>
                </div>
            </div>
            {/* <DataCard
                title="Key Features"
                component={<DataList dataList={keyFeatures} />}
            /> */}
            <BulletPointContainer
                heading={<div className="text-start">Key Features</div>}
                variant={VARIANTS.BULLETS}
                numberOfColumns={4}
                justify="flex-start"
                lastRowFullWidth={true}
                bulletPointArray={keyFeatures.map(r => ({ description: r }))}
            />
            {/* <DataCard
                title="Optional Enhancements"
                component={<DataList dataList={optionalEnhancements} />}
            /> */}
            <BulletPointContainer
                heading={<div className="text-start">Optional Enhancements</div>}
                variant={VARIANTS.BULLETS}
                numberOfColumns={3}
                justify="flex-start"
                lastRowFullWidth={true}
                bulletPointArray={optionalEnhancements.map(r => ({ description: r }))}
            />
            <div>
                These features and optional enhancements make the Vibro Sifter a versatile
                and user-friendly solution, ensuring easy maintenance, effective cleaning,
                and adaptability to specific operational needs.
            </div>
            <br />
        </>
    );
};

export default VibroSifterData;
