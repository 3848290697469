import React from "react";
import { Image } from "react-bootstrap";
import DataCard from "components/Site/DataCard";
import DataList from "components/Site/DataList";
import { automatic_round_bottle_labeling_machine } from "assets/images/equipments";
import BulletPointContainer, { VARIANTS } from "components/Commons/BulletPoint";

const AutomaticRoundBottleLabelingMachineData = () => {

    const keyFeatures = [
        <div><b>Stainless Steel Structure(SS 304):</b> The machine is constructed with a robust and corrosion - resistant Stainless Steel(SS 304) structure, ensuring durability and compliance with industry standards.</div>,
        <div><b>Safety guards covering all moving parts:</b> All moving parts of the machine are securely covered with safety guards, prioritizing operator safety and preventing accidents during operation.</div>,
        <div><b>MS powder - coated / nickel - plated exposed parts:</b> Exposed parts of the machine are either powder - coated with Mild Steel(MS) or nickel - plated, providing a protective layer against environmental elements and enhancing resistance to wear and tear.</div>,
        <div><b>Long rubberized endless conveyor belt:</b> The machine features a long rubberized endless conveyor belt, facilitating the smooth and reliable movement of products through the labeling process.</div>,
        <div><b>Adjustable side guide for easy conveying system:</b> An adjustable side guide is incorporated into the machine's design, offering ease of use in the conveying system and allowing for flexibility to accommodate various product sizes.</div>,
        <div><b>AC frequency variable drive for variable speed with constant torque:</b> The machine is equipped with an AC frequency variable drive, enabling variable speed control with consistent torque.This feature ensures optimal performance and adaptability to different production requirements.</div>,
        <div><b>Full / partial labelling capabilities:</b> The machine supports both full and partial labeling functionalities, providing versatility to meet diverse labeling needs for products.</div>,
        <div><b>Suitable for round products(vials, bottles, jars, tins, cans, containers):</b> Designed to cater to round - shaped products, the machine is versatile and applicable to various industries, including pharmaceuticals, food, and more.</div>,
        <div><b>Dispensing unit for efficient label dispensing:</b> The machine is equipped with a dispensing unit, optimizing the label dispensing process for efficiency and accuracy during labeling operations.</div>,
    ];

    const optionalEnhancements = [
        <div><b>Specially designed drive mechanism for Label Release:</b> The machine incorporates a specialized mechanism for smooth and precise release of labels, ensuring accuracy in the labeling process.</div>,
        <div><b>Reel mechanism(Size: 300 mm) for hanging the roll with a core ID of 76 mm(3”):</b> The labeling machine features a reel mechanism with a size of 300 mm, providing a convenient setup for hanging label rolls with a core inner diameter of 76 mm(3 inches).</div>,
        <div><b>Brake assembly to prevent variation during roll release:</b> A brake assembly is integrated into the machine to prevent variations and ensure consistent label release, contributing to the overall reliability of the labeling process.</div>,
        <div><b>Various label position adjustments with sliding pipe assembly:</b> The machine offers flexibility in label positioning, allowing for precise adjustments through a sliding pipe assembly.This feature accommodates different product sizes and labeling requirements.</div>,
        <div><b>Specially designed wrapping unit for efficient label wrapping on the product:</b> An optimized wrapping unit is implemented to ensure efficient and effective wrapping of labels onto the product, enhancing the overall quality of the labeling application.</div>,
        <div><b>Wrapping belt made of specialized nylon sponge material for longer life:</b> The wrapping belt, crucial for the labeling process, is constructed from a specialized nylon sponge material.This choice of material enhances durability, ensuring a longer operational life for the wrapping component.</div>,
        <div><b>Reliable and proven pneumatic & electrical / electronic components from reputed make:</b> The labeling machine is equipped with pneumatic and electrical / electronic components sourced from reputable manufacturers.This selection ensures reliability and performance, contributing to the overall efficiency and longevity of the machine.</div>,
    ];

    return (
        <div className="text-justify">
            <div>
                <Image className="w-50 d-none d-md-block ms-2" src={automatic_round_bottle_labeling_machine} alt="Lab Blender Equipment" style={{ borderRadius: "10px", float: "right" }} />
                <div>
                    The Automatic Round Bottle (Sticker) Labeling Machine is a high-performance
                    solution designed for efficient and precise labelling of round bottles at a
                    maximum rate of 120 bottles per minute. Built with a robust main structure,
                    it ensures durability and longevity, while the adjustable product transmission
                    belt with a guide facilitates seamless operation. The machine's label dispenser
                    unit is designed for optimal efficiency, ensuring accurate and uniform label
                    application. The label wrapping unit complements the process, providing a smooth
                    and seamless finish. Operated through a user-friendly PLC/HMI system with AC drives,
                    the machine offers both high-speed performance and easy control. Its versatility
                    makes it suitable for various round bottle labelling applications, making it an
                    ideal choice for industries requiring efficient and accurate labelling solutions.
                </div>
            </div>
            {/* <DataCard
                title="Key Features"
                component={<DataList dataList={keyFeatures} />}
            /> */}
            <BulletPointContainer
                heading={<div className="text-start">{"Key Features"}</div>}
                bulletPointArray={keyFeatures.map(r => ({ description: r }))}
                variant={VARIANTS.BULLETS}
                numberOfColumns={2}
                justify="flex-start"
                lastRowFullWidth={true}
            />
            <div className="text-center">
                <Image className="w-50 d-md-none" src={automatic_round_bottle_labeling_machine} alt="Lab Blender Equipment" style={{ borderRadius: "10px" }} />
            </div>
            {/* <DataCard
                title="Optional Enhancements"
                component={<DataList dataList={optionalEnhancements} />}
            /> */}
            <BulletPointContainer
                heading={<div className="text-start">{"Optional Enhancements"}</div>}
                bulletPointArray={optionalEnhancements.map(r => ({ description: r }))}
                variant={VARIANTS.BULLETS}
                numberOfColumns={2}
                justify="flex-start"
                lastRowFullWidth={true}
            />
        </div>
    );
};

export default AutomaticRoundBottleLabelingMachineData;
