import React from "react";
import { useMediaQuery } from "react-responsive";

export const DiamondCard = ({
  image,
  title,
  className,
  style,
  imageClass,
  imageStyle,
}) => {
  const small = useMediaQuery({ query: "(min-width: 576px)" });
  const medium = useMediaQuery({ query: "(min-width: 768px)" });
  const large = useMediaQuery({ query: "(min-width: 992px)" });

  return (
    <div
      className={`d-flex justify-content-center align-items-center bg-white border-primary p-3 diamond-card ${className}`}
      style={{ margin: medium ? 8 : 4, ...style }}
    >
      <img
        src={image}
        alt={title}
        className={`img-fluid ${imageClass}`}
        style={{
          width: imageStyle?.width ?? 200,
          height: imageStyle?.height ?? 200,
          maxHeight: "50%",
          maxWidth: "60%",
          objectFit: "contain",
          ...imageStyle,
        }}
      />
    </div>
  );
};

export default DiamondCard;
