import React from "react";
import { Image } from "react-bootstrap";
import DataCard from "components/Site/DataCard";
import DataList from "components/Site/DataList";
import { lab_mixer } from "assets/images/equipments";
import BulletPointContainer from "components/Commons/BulletPoint";

const LabMixerData = () => {

    const keyFeatures = [
        "VFD at Impeller and Chopper for variable speed control",
        "Manually operated Top Lid with sensor for easy access",
        "Pneumatically operated Discharge for efficient material discharge",
        "Impeller and Chopper designed for uniform granule formation",
        "Air Purging at impeller and chopper shaft for cleanliness",
        "Tabletop model with user-friendly features for convenience",
        "Ergonomically designed, easy to clean, and maintain for optimal usability.",
    ];

    const optionalFeatures = [
        "PLC and HMI-based control for advanced operation",
        "Flame-Proof Construction for safety in potentially explosive environments",
        "0.2 Micron Filter for Compressed air to ensure clean air supply",
        "Spray Ball Assembly for WIP (Wash In Place) for easy cleaning",
        "Wet Material Transfer System from RMG to FBD for seamless material transfer",
        "Bowl with jacket and temperature sensor for controlled temperature processing",
        "Interchangeable bowl sizes: 3 Ltrs, 5 Ltrs, 10 Ltrs for flexibility in processing volumes.",
    ];

    return (
        <>
            <div className="text-justify">
                <div>
                    The Lab Mixer (RMG) is an ideal solution for processes demanding homogeneous
                    mixing, precise control over granule size, and significant reduction in
                    processing time. The method involves blending active powder and excipients
                    with a binder solution using a high-speed mixing blade and chopper. The
                    interaction of liquid and solid bridges forms dense granules, achieving an
                    enhanced dose distribution through a spray nozzle. The resulting granules
                    are characterized by their compact structure and high bulk density.
                </div>
                <div className="d-flex justify-content-center my-3">
                    <Image className="d-none d-md-block ms-2" src={lab_mixer} alt="Lab Mixer Equipment" style={{ borderRadius: "10px", height: 310 }} />
                </div>
                <BulletPointContainer
                    heading={"Here are the key features of the Lab Mixer (RMG)"}
                    hideNumbers={false}
                    numberOfColumns={4}
                    bulletPointArray={keyFeatures?.map(r => ({ description: r }))}
                    lastRowFullWidth={true}
                />
                {/* <DataCard
                    title="Here are the key features of the Lab Mixer (RMG):"
                    component={<DataList dataList={keyFeatures} />}
                /> */}
                <BulletPointContainer
                    heading={"The optional features for the Lab Mixer (RMG)"}
                    hideNumbers={false}
                    numberOfColumns={4}
                    bulletPointArray={optionalFeatures?.map(r => ({ description: r }))}
                    lastRowFullWidth={true}
                />
                {/* <DataCard
                    title="The optional features for the Lab Mixer (RMG):"
                    component={<DataList dataList={optionalFeatures} />}
                /> */}
            </div>
            <Image className="d-md-none" src={lab_mixer} alt="Lab Mixer Equipment" style={{ borderRadius: "10px", height: 210 }} />
        </>
    );
};

export default LabMixerData;
