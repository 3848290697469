// export const contactInfoData = [
//   {
//     title: "For Rental Call",
//     data: "+98 125 456 456",
//   },
//   {
//     title: "Send us Email",
//     data: "info@proactimo.com",
//   },
//   {
//     title: "Address",
//     data: "Borivali Mumbai- 400091",
//   },
// ];

import { FiPhone } from "react-icons/fi";
import { IoMailOutline } from "react-icons/io5";
import { SlLocationPin } from "react-icons/sl";

export const contactInfoData = [
  {
    title: "Contact Number",
    type: "Contact Number",
    data: "+91 99303 29749",
    icon: <FiPhone size={24} />,
    link: "tel:+91 99303 29749",
  },
  {
    title: "Send us Email",
    type: "Mail",
    data: "info@proactimo.com",
    icon: <IoMailOutline size={24} />,
    link: "mailto:info@proactimo.com?subject=Contact%20us",
  },
  {
    title: "Address",
    type: "Address",
    data: <div>Proactimo Projects Pvt. Ltd., <br />Zoom Plaza Mall, Gorai, <br />Borivali Mumbai, Maharashtra - 400092</div>,
    icon: <SlLocationPin size={24} />,
    link: null,

  },
];
