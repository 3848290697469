import { logo } from "assets";
import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { useMediaQuery } from "react-responsive";
import { useLocation, useNavigate } from "react-router-dom";
import { CgMenu } from "react-icons/cg";
import { Accordion, Offcanvas } from "react-bootstrap";
import { primary } from "Utilities/Colors";
import { siteNavigation } from "navigation/SiteNavigation";

const NavbarMain = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const small = useMediaQuery({ query: "(min-width: 576px)" });
  const medium = useMediaQuery({ query: "(min-width: 768px)" });
  const large = useMediaQuery({ query: "(min-width: 992px)" });

  const [show, setShow] = useState(false);

  const params = location.pathname.split("/").filter((item) => item !== "");
  const param1 = params.length > 0 ? params[0] : "";
  // console.log(param1);
  // const param2 = params.length > 1 ? params[1] : "";
  // const param3 = params.length > 1 ? params[2] : "";
  // const param4 = params.length > 1 ? params[3] : "";

  // const getRouteObject = (identifier) => {
  //   return siteNavigation.find((route) => route.identifier === identifier);
  // };

  // const homeMenuObject = getRouteObject("home");
  // const aboutUsMenuObject = getRouteObject("about_us");
  // const contactUsUsMenuObject = getRouteObject("contact_us");
  // const servicesMenuObject = getRouteObject("services");
  // const equipmentsMenuObject = getRouteObject("equipments");
  // const enggConsultancyMenuObject = getRouteObject("engg_consultancy");
  // const updatesMenuObject = getRouteObject("updates");

  // console.log(
  //   siteNavigation[3].subroutes.find((obj) => obj.path === params[1])
  // );

  return (
    <>
      {/* <Navbar expand="sm" className="bg-body-tertiary mb-3"> */}
      <Navbar
        expand="lg"
        className="bg-white shadow-sm mb-md-0"
        style={{ minHeight: medium ? 92 : small ? 76 : 60 }}
      >
        <Container fluid className="content-wrapper">
          <Navbar.Toggle
            aria-controls="offcanvasNavbar-expand-lg"
            className="focus-none p-1 border-0"
            onClick={() => setShow(!show)}
          >
            <CgMenu size={medium ? 36 : small ? 32 : 28} />
          </Navbar.Toggle>
          <Navbar.Brand
            href="/"
            className="mx-auto me-lg-auto"
            style={{ width: large ? 160 : medium ? 140 : small ? 100 : 80 }}
          >
            <img src={logo} alt="Proactimo" className="img-fluid" />
          </Navbar.Brand>
          <div
            className="d-lg-none"
            style={{ width: medium ? 44 : small ? 40 : 36 }}
          />

          <Navbar.Offcanvas
            id="offcanvasNavbar-expand-lg"
            aria-labelledby="offcanvasNavbarLabel-expand-lg"
            placement="start"
            show={show}
            onHide={() => setShow(!show)}
          >
            <Offcanvas.Header closeButton className="pb-0">
              <Navbar bg="transparent" expand="lg">
                <Navbar.Brand>
                  <img
                    src={logo} // Replace with your image URL
                    alt="Proactimo"
                    className="d-inline-block align-top"
                    style={{ width: medium ? 140 : small ? 120 : 100 }}
                  />
                </Navbar.Brand>
              </Navbar>
            </Offcanvas.Header>

            <Offcanvas.Body>
              <Nav className="justify-content-end flex-grow-1 pe-lg-3">
                {siteNavigation.map((route, i) => {
                  return !route?.subroutes ? (
                    <Nav.Link
                      key={i}
                      href=""
                      onClick={() => {
                        setShow(false);
                        navigate(route.path);
                      }}
                      className="d-flex mx-2 mx-lg-1 text-lg-center"
                      // className={`mx-2 mx-lg-1 text-lg-center ${
                      //   param1 === route.path ? "active-nav" : ""
                      // }`}
                    >
                      <div
                        className={`d-flex pe-2 ${
                          param1 === route.path ? "active-nav" : ""
                        }`}
                      >
                        {route.title}
                      </div>
                    </Nav.Link>
                  ) : (
                    <NavDropdown
                      key={i}
                      title={route.title}
                      id="collapsible-nav-dropdown"
                      className="mx-2 mx-lg-1 text-lg-center outer-dropdown"
                      // className={`mx-2 mx-lg-1 text-lg-center ${param1 === route.path ? "active-dropdown" : ""}`}
                      defaultShow={
                        !large &&
                        route.subroutes.filter((obj) => obj.path === params[1])
                          ?.length > 0
                      }
                    >
                      {/* {console.log(route.subroutes.filter((obj) => obj.path === params[1]))} */}
                      {route?.subroutes?.map((subRoute, i) =>
                        !subRoute?.subroutes ? (
                          <NavDropdown.Item
                            key={i}
                            className="d-flex mb-1 overflow-hidden"
                            onClick={() => {
                              setShow(false);
                              !route.subroutes?.subroutes &&
                                navigate(
                                  `/${route.path}/${
                                    route.subroutes.find(
                                      (obj) => obj === subRoute
                                    ).path
                                  }`
                                );
                            }}
                          >
                            {/* {console.log(route.subroutes)}
                          {console.log(route.subroutes.find((item) => item.path === params[1])?.length)} */}
                            <div
                              className={
                                params[1] === subRoute.path
                                  ? "d-flex active-nav pe-2 pb-2"
                                  : ""
                              }
                            >
                              {subRoute.title}
                            </div>
                          </NavDropdown.Item>
                        ) : !large ? (
                          <NavDropdown
                            key={i}
                            title={subRoute.title}
                            id="collapsible-nav-dropdown"
                            className="mx-2 mx-lg-1 text-lg-center inner-dropdown"
                            // className={`mx-2 mx-lg-1 text-lg-center inner-dropdown ${
                            //   large && params[1] === subRoute.path
                            //     ? "active-tab"
                            //     : ""
                            // }`}
                            // className={`mx-2 mx-lg-1 text-lg-center ${ param1 === subRoute.path ? "active-dropdown" : ""}`}
                            defaultShow={
                              !large &&
                              subRoute.subroutes.filter(
                                (obj) => obj.path === params[2]
                              )?.length > 0
                            }
                            onClick={() => {
                              large &&
                                navigate(
                                  `/${route.path}/${
                                    route.subroutes.find(
                                      (obj) => obj === subRoute
                                    ).path
                                  }`
                                );
                            }}
                          >
                            {/* {console.log(subRoute.subroutes.filter((obj) => obj.path === params[2]))} */}
                            {subRoute?.subroutes?.map((subSubRoute, i) => (
                              <NavDropdown.Item
                                key={i}
                                className="d-flex mb-1 overflow-hidden"
                                onClick={() => {
                                  setShow(false);
                                  navigate(
                                    `/${route.path}/${subRoute.path}/${
                                      subRoute.subroutes.find(
                                        (obj) => obj === subSubRoute
                                      ).path
                                    }`
                                  );
                                }}
                              >
                                {/* {console.log(subRoute.subroutes)}
                                    {console.log(subRoute.subroutes.find((item) => item.path === params[2])?.length)} */}
                                <div
                                  className={
                                    params[2] === subSubRoute.path
                                      ? "d-flex active-nav pe-2 pb-2"
                                      : ""
                                  }
                                >
                                  {`- ${subSubRoute.title}`}
                                </div>
                              </NavDropdown.Item>
                            ))}
                          </NavDropdown>
                        ) : (
                          <NavDropdown.Item
                            key={i}
                            className="d-flex mb-1 overflow-hidden"
                            onClick={() => {
                              setShow(false);
                              navigate(
                                `/${route.path}/${
                                  route.subroutes.find(
                                    (obj) => obj === subRoute
                                  ).path
                                }`
                              );
                            }}
                          >
                            {/* {console.log(route.subroutes)}
                            {console.log(route.subroutes.find((item) => item.path === params[1])?.length)} */}
                            <div
                              className={
                                params[1] === subRoute.path
                                  ? "d-flex active-nav pe-2 pb-2"
                                  : ""
                              }
                            >
                              {subRoute.title}
                            </div>
                          </NavDropdown.Item>
                        )
                      )}
                    </NavDropdown>
                  );
                })}
              </Nav>
              {/* <Nav className="justify-content-end flex-grow-1 pe-lg-3">
                <Nav.Link
                  href=""
                  onClick={() => navigate("/")}
                  className={`mx-2 mx-lg-1 text-lg-center ${
                    param1 === homeMenuObject.path ? "active-nav" : ""
                  }`}
                  // style={
                  //   param1 === homeMenuObject.path ? styles.activeMenu : {}
                  // }
                >
                  Home
                </Nav.Link>
                <Nav.Link
                  onClick={() => navigate("/about-us")}
                  className={`mx-2 mx-lg-1 text-lg-center ${
                    param1 === aboutUsMenuObject.path ? "active-nav" : ""
                  }`}
                  // style={
                  //   param1 === aboutUsMenuObject.path ? styles.activeMenu : {}
                  // }
                >
                  About Us
                </Nav.Link>
                <NavDropdown
                  title="Engineering Consultancy"
                  id="collapsible-nav-dropdown"
                  className={`mx-2 mx-lg-1 text-lg-center ${
                    param1 === enggConsultancyMenuObject.path
                      ? "active-dropdown"
                      : ""
                  }`}
                  // style={
                  //   param1 === enggConsultancyMenuObject.path
                  //     ? styles.activeMenu
                  //     : {}
                  // }
                >
                  {enggConsultancyMenuObject?.subroutes?.map((subRoute) => (
                    <NavDropdown.Item
                      key={subRoute?.identifier}
                      onClick={() =>
                        navigate(
                          `/${enggConsultancyMenuObject.path}/${
                            enggConsultancyMenuObject.subroutes.find(
                              (obj) => obj === subRoute
                            ).path
                          }`
                        )
                      }
                    >
                      {subRoute.title}
                    </NavDropdown.Item>
                  ))}
                </NavDropdown>
                <NavDropdown
                  title="Services"
                  id="collapsible-nav-dropdown"
                  className={`mx-2 mx-lg-1 text-lg-center ${
                    param1 === servicesMenuObject.path ? "active-dropdown" : ""
                  }`}
                  // style={param1 === "services" ? styles.activeMenu : {}}
                >
                  {servicesMenuObject?.subroutes?.map((subRoute) => (
                    <NavDropdown.Item
                      key={subRoute?.identifier}
                      onClick={() => {
                        console.log(
                          `/${servicesMenuObject.path}/${
                            servicesMenuObject.subroutes.find(
                              (obj) => obj === subRoute
                            ).path
                          }`
                        );
                        navigate(
                          `/${servicesMenuObject.path}/${
                            servicesMenuObject.subroutes.find(
                              (obj) => obj === subRoute
                            ).path
                          }`
                        );
                      }}
                      className="overflow-hidden"
                      style={{ textOverflow: "ellipsis" }}
                    >
                      {subRoute.title}
                    </NavDropdown.Item>
                  ))}
                </NavDropdown>
                <NavDropdown
                  title="Equipments"
                  id="collapsible-nav-dropdown"
                  className={`mx-2 mx-lg-1 text-lg-center ${
                    param1 === equipmentsMenuObject.path
                      ? "active-dropdown"
                      : ""
                  }`}
                  // style={
                  //   param1 === equipmentsMenuObject.path
                  //     ? styles.activeMenu
                  //     : {}
                  // }
                >
                  {equipmentsMenuObject?.subroutes?.map((subRoute) => (
                    <NavDropdown.Item
                      key={subRoute?.identifier}
                      onClick={() =>
                        navigate(
                          `/${equipmentsMenuObject.path}/${
                            equipmentsMenuObject.subroutes.find(
                              (obj) => obj === subRoute
                            ).path
                          }`
                        )
                      }
                    >
                      {subRoute.title}
                    </NavDropdown.Item>
                  ))}
                </NavDropdown>
                <NavDropdown
                  title="Updates"
                  id="collapsible-nav-dropdown"
                  className={`mx-2 mx-lg-1 text-lg-center ${
                    param1 === updatesMenuObject.path ? "active-dropdown" : ""
                  }`}
                  // style={
                  //   param1 === updatesMenuObject.path ? styles.activeMenu : {}
                  // }
                >
                  {updatesMenuObject?.subroutes?.map((subRoute) => (
                    <NavDropdown.Item
                      key={subRoute?.identifier}
                      onClick={() =>
                        navigate(
                          `/${updatesMenuObject.path}/${
                            updatesMenuObject.subroutes.find(
                              (obj) => obj === subRoute
                            ).path
                          }`
                        )
                      }
                    >
                      {subRoute.title}
                    </NavDropdown.Item>
                  ))}
                </NavDropdown>
                <Nav.Link
                  href=""
                  onClick={() => navigate("/contact-us")}
                  className={`mx-2 mx-lg-1 text-lg-center ${
                    param1 === updatesMenuObject.path ? "active-nav" : ""
                  }`}
                  // style={
                  //   param1 === contactUsUsMenuObject.path
                  //     ? styles.activeMenu
                  //     : {}
                  // }
                >
                  Contact Us
                </Nav.Link>
              </Nav> */}
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
    </>
  );
};

const styles = {
  activeMenu: {
    borderBottom: `3px solid ${primary}`,
  },
};

export default NavbarMain;
