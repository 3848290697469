import React, { useState } from "react";
import { Image } from "react-bootstrap";
import DataCard from "components/Site/DataCard";
import DataList from "components/Site/DataList";
import { rinsing_filling_and_capping_machine } from "assets/images/equipments";

const RinsingFillingAndCappingMachineData = () => {

    const [active, setActive] = useState(0);

    const data = [
        {
            title: "Bottle In-Feed & Rinsing Section",
            description:
                <>
                    <div>
                        The in-feed star wheel is equipped with spring-loaded neck gripper fingers, providing adaptability to minor variations in bottle-neck diameter. Bottles conveyed by the air conveyor are securely grasped by a fixed-size neck star wheel and seamlessly transferred between the grip fingers. This innovative design eliminates the need for a feed worm and the hassle of changing parts for different bottle body diameters.
                    </div>
                    <div>
                        The neck spider then efficiently conveys the bottles to the rinsing turret, featuring neck-gripping fingers that guide the bottles through the rinsing process. The spray nozzles incorporate a "No Bottle – No Rinsing" spray mechanism, promoting water conservation. Rinsing occurs during a 90-degree rotation of the turret. Subsequently, for the next 90 degrees, the bottles are gently tilted back at a slight angle to allow for optimal draining time.
                    </div>
                </>
            ,
        },
        {
            title: "Bottle Transfer & Filling Section:",
            description:
                <>
                    <div>
                        After undergoing thorough rinsing and drainage, the bottles are once again efficiently picked up by the star wheel and conveyed to the filling lifter gripper platform. The filling lifters operate on the positive cam lift-off principle, eliminating the need for compressed air or spring load. The lift cylinder cam, crafted from stainless steel with a fine finish, follows a simple harmonic motion profile, ensuring a smooth lift for the bottles and precise filling valve engagement.
                    </div>
                    <div>
                        The filling valve operates on a gravity filling system with an added AIR EXHAUST mechanism, effectively expelling stagnant air from the bottle to the outside atmosphere. At the top of the filling bowl/tank, a pneumatically operated filling valve is fitted to a single-line pipe, which can be directed outside the filling room. To maintain liquid levels in the bowl, an electronic level controller is integrated, regulating the process through a pneumatic ON-OFF valve.
                    </div>
                </>
            ,
        },
        {
            title: "Plastic Screw Capper:",
            description:
                <>
                    <div>
                        A foolproof No Bottle No Cap interlock is incorporated into the cap delivery star wheel. Non-contact infrared sensors efficiently detect bottles, prompting an instant halt to cap delivery. The capping chucks, crafted with precision and the finest quality materials, feature a magnetic torque control mechanism, mitigating heat generation typical of friction-based coupling in chucks.
                    </div>
                    <div>
                        Enhancing adaptability, a flexible segmented cap holder adeptly retrieves caps, compensating for minor variations in cap diameter resulting from prolonged storage. Adjusting capping torque is a swift process, thanks to the magnetic torque control mechanism, and once set, the torque is reliably maintained. The capping turret employs a Pick N Place type cap pick-up mechanism, seamlessly delivering caps to the cap holders of the capping chucks.
                    </div>
                </>
            ,
        },
        {
            title: "Cap Elevator cum Hopper:",
            description:
                <>
                    <div>
                        A meticulously designed elevator cum selector hopper, available as an option, boasts unique features for enhanced functionality. Positioned at floor level, this hopper accommodates up to 5000 caps. Bucket-type fingers efficiently elevate the caps, allowing caps in the correct direction to proceed to the delivery chute, while those in the wrong direction are redirected to the main hopper.
                    </div>
                    <div>
                        For added hygiene, the cap delivery chute can be equipped with optional ozonized water rinse and a clean air jet blow to eliminate water residue. Upon request, an inline Ultraviolet cleaning system can be integrated for thorough cleaning. The chute incorporates high-level and low-level infrared sensors, ensuring precision in cap handling. The elevator motor initiates on a low-level signal, while the high-level motor halts, ensuring seamless operation.
                    </div>
                    <div>
                        Safety is paramount, with solenoid valves interlocking the cap rinse water line and clean blow airline with the main motor. In the event of a machine stoppage, both solenoid valves promptly close, discontinuing the supply for optimal safety measures.
                    </div>
                </>
            ,
        },
        {
            title: "Lubrication System:",
            description:
                <>
                    <div>
                        Ensuring smooth operation and minimal maintenance, a centralized lubrication system is integrated, providing consistent oil supply to essential moving components. Additionally, strategically placed manual grease points are conveniently grouped for easy access, streamlining the lubrication process and promoting operational efficiency.
                    </div>
                </>
            ,
        },
    ]

    return (
        <>
            <div className="text-justify">
                <Image className="float-right ms-2 d-none d-md-block" src={rinsing_filling_and_capping_machine} alt="Lab Blender Equipment" style={{ borderRadius: "10px", width: 350, }} />
                <div>
                    Discover the Automatic Rotary Rinsing, Filling, and Capping Machine—an all-in-one, fully automated, multi-functional marvel that seamlessly combines the processes of washing, filling, and capping within a single unit. This versatile machine is designed to efficiently package not only mineral water but also various beverages like fruit juice and squash, even those filled at higher temperatures. With its advanced man-machine interface control and automation features, this machine ensures a user-friendly experience. Utilizing the hanging neck holding method, it provides utmost convenience for swift bottle changeovers.
                </div>
                <Image className="d-md-none mx-4" src={rinsing_filling_and_capping_machine} alt="Lab Blender Equipment" style={{ borderRadius: "10px", width: 350, }} />
            </div>
            <br />
            <h4 className="mb-3">Operation:</h4>
            {data.map((info, i) => (
                <DataCard
                    key={i}
                    title={info?.title}
                    description={info?.description}
                />
            ))}
        </>
    );
};

export default RinsingFillingAndCappingMachineData;
