import { about_us_cover } from "assets";
import PageTitleCover from "components/Site/PageTitleCover";
import React, { useRef } from "react";
import { Container } from "react-bootstrap";
import AboutUsSection from "sections/About/AboutUsSection";
import MoreAboutUsSection from "sections/About/MoreAboutUsSection";

const description =
  "Powering Progress Proactively";

const About = () => {

  const learnMoreRef = useRef(null);

  return (
    <>
      <PageTitleCover
        backgroundImage={about_us_cover}
        title="About Us"
        description={description}
      />
      <div className="content-wrapper">
        <AboutUsSection learnMoreRef={learnMoreRef} />
        {/* <Container fluid ref={learnMoreRef} className="m-0 p-0"> */}
        <Container fluid className="m-0 p-0">
          <MoreAboutUsSection learnMoreRef={learnMoreRef} />
        </Container>
      </div>
      <section className="py-4" />
    </>
  );
};

export default About;
