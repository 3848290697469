import React from "react";
import { Image } from "react-bootstrap";
import DataCard from "components/Site/DataCard";
import DataList from "components/Site/DataList";
import { lab_sifter } from "assets/images/equipments";
import BulletPointContainer from "components/Commons/BulletPoint";

const LabSifterData = () => {

    const salientFeatures = [
        "Enhanced throughput with spring-amplified vibrations.",
        "Portable design mounted on robust PU Wheels with SS 304 Bracket.",
        "Silicon Molded SS 316 Screen for durability and efficiency.",
    ];

    const optionalFeatures = [
        "Anti-blinding arrangement for improved performance.",
        "Inclusion of silicon balls for enhanced screening efficiency.",
        "Double Deck configuration for versatility in sifting.",
        "Motor Mounting Plate made of SS 304 for durability.",
        "Flameproof (FLP) motor for added safety in specific environments.",
    ];

    return (
        <>
            <div>
                <div className="text-justify">
                    The Lab Sifter is designed to meet the demand for dust-free sifting,
                    providing efficient screening, sieving, and grading for solid separation in
                    various industries, including Pharmaceuticals, Chemicals, Food Processing,
                    Dairy, and Nutraceuticals. Whether it's simple security screening or fine grading,
                    the type of sifter needed can vary based on duty rate and product type. The
                    vibrating assembly incorporates a specially designed Vibratory Motor, enhancing
                    the machine's overall performance.
                </div>
                <div className="d-flex justify-content-center my-3">

                    <Image
                        src={lab_sifter}
                        alt="Lab Sifter Equipment"
                        style={{ borderRadius: "10px", height: 400 }}
                        className="ms-2 d-none d-md-block"
                    />
                </div>
                <BulletPointContainer
                    heading={"Salient Features"}
                    hideNumbers={false}
                    bulletPointArray={salientFeatures?.map(r => ({description: r}))}
                />
                {/* <DataCard
                    title="Salient Features"
                    component={<DataList dataList={salientFeatures} />}
                /> */}
                <BulletPointContainer
                    heading={"Optional Features"}
                    hideNumbers={false}
                    bulletPointArray={optionalFeatures?.map(r => ({description: r}))}
                    lastRowFullWidth={true}
                />
                {/* <DataCard
                    title="Optional Features"
                    component={<DataList dataList={optionalFeatures} />}
                /> */}
                <Image
                    src={lab_sifter}
                    alt="Lab Sifter Equipment"
                    style={{ borderRadius: "10px", height: 200 }}
                    className="ms-2 d-md-none"
                />
            </div>

        </>
    );
};

export default LabSifterData;
