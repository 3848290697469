import React from "react";
import { Image } from "react-bootstrap";
import DataCard from "components/Site/DataCard";
import DataList from "components/Site/DataList";
import { lab_multi_mill } from "assets/images/equipments";
import BulletPointContainer from "components/Commons/BulletPoint";

const LabMultiMillData = () => {

    const salientFeatures = [
        <div><b>High-Speed Milling:</b> Ensures rapid and effective size reduction of materials for enhanced efficiency.</div>,
        <div><b>Reversible Impeller Rotation:</b> Offers versatility with a reversible direction of impeller rotation.</div>,
        <div><b>Belt Drive with 3-Speed Options:</b> A flexible belt drive system with three-speed options via a step pulley.</div>,
        <div><b>Dynamic Impeller Design:</b> Impeller equipped with knife edges and impact edges for comprehensive milling capabilities.</div>,
        <div><b>Portable Design:</b> Mounted on sturdy PU Wheels for easy portability and convenience.</div>,
    ];

    const optionalFeatures = [
        <div><b>Customizable Sieves:</b> Sieves available in various sizes ranging from 0.3 to 7mm hole diameter for tailored applications."</div>,
        <div><b>Enhanced Durability:</b> SS 304 Motor Mounting Plate for added strength and durability."</div>,
        <div><b>Magnetic Grill Separator:</b> Incorporates a magnetic grill separator at charging/discharge points for efficient material handling."</div>,
        <div><b>Flameproof (FLP) Motor:</b> FLP motor option ensures safety in potentially hazardous environments."</div>,
        <div><b>FLP Starter:</b> Includes a Flameproof (FLP) starter for secure and controlled motor initiation."</div>,
        <div><b>Variable Frequency Drive (VFD):</b> Facilitates motor speed control for optimized performance."</div>,
    ];

    return (
        <>
            <div className="text-justify">
                <div>
                    Revolutionize your milling and size reduction processes with our Lab Multi Mill,
                    a portable unit designed for efficiency in Pharmaceutical, chemical, cosmetics,
                    Food, and Nutraceutical industries. This dynamic machine operates on the principle
                    of variable force swing beaters, featuring both knife and impact edges within a
                    selected screen to achieve precise size reduction for Milling, shredding, and chopping.
                </div>
            </div>
            <div className="d-flex justify-content-center my-3">
                <Image  src={lab_multi_mill} alt="Lab Multi Mill Equipment" style={{ borderRadius: "10px", height: 300 }} />
            </div>
            <BulletPointContainer
                heading={"Salient Features"}
                lastRowFullWidth={true}
                bulletPointArray={salientFeatures.map(r => ({description: r}))}
            />
            {/* <DataCard
                title="Salient Features"
                component={<DataList dataList={salientFeatures} />}
            /> */}
            <BulletPointContainer
                heading={"Optional Features"}
                lastRowFullWidth={true}
                bulletPointArray={optionalFeatures.map(r => ({description: r}))}
            />
            {/* <DataCard
                title="Optional Features"
                component={<DataList dataList={optionalFeatures} />}
            /> */}
            <div className="text-center">
                Elevate your milling experience with the Lab Multi Mill, where precision meets versatility!
            </div>
            <br />
        </>
    );
};

export default LabMultiMillData;
