import React from "react";
import { Image } from "react-bootstrap";
import DataCard from "components/Site/DataCard";
import DataList from "components/Site/DataList";
import { ointment_manufacturing_plants } from "assets/images/equipments";
import BulletPointContainer, { VARIANTS } from "components/Commons/BulletPoint";

const OintmentManufacturingPlantData = () => {

    const keyFeatures = [
        "Designed for Single Operator Operation: The plant is engineered to be efficiently operated by a single operator with the assistance of one helper, maximizing operational simplicity.",
        "Material Transfers by Vacuum or Transfer Pumps: All material transfers within the plant are conducted seamlessly through vacuum systems or dedicated transfer pumps, ensuring efficiency in the production process.",
        "cGMP Compliant Construction: All vessels are constructed without the use of paint, adhering to cGMP standards for pharmaceutical and cosmetic manufacturing.",
        "Silicon Gaskets for Hygienic Sealing: Utilizes silicon gaskets, known for their food-grade quality, ensuring hygienic and secure sealing.",
        "Stainless Steel 316 Contact Parts: All contact parts are made of stainless steel 316, finished to a class 4B (Mirror) finish, and designed to be crevice-free, maintaining the highest standards of hygiene.",
        "Top Entry Agitators and Bottom/In-line Emulsifiers: Agitators enter from the top, while the High-Speed Emulsifier can enter from the bottom or through an In-line Emulsifier, providing flexibility based on customer demands.",
        "Pressure Resistant Vessels: All vessels are suitable for internal pressures of up to 1 Kg./Sq. cm. and can be sterilized, ensuring product integrity and safety.",
        "Quality Stainless Steel Piping: Pipes, fittings, and valves are made of seamless SS304 or SS316, internally electro-polished, and equipped with tri-clover joints to meet specific customer requirements.",
        "CIP & SIP Connectivity: The entire plant is equipped with Clean-in-Place (CIP) and Sterilize-in-Place (SIP) connections, providing the option for customers with CIP & SIP plants. Alternatively, a complete CIP & SIP system can be supplied based on customer demand.",
        "Digital Monitoring: Ampere, temperature, and time values of the plant are digitally indicated on the control panel, ensuring precise monitoring and control.",
        "Microprocessor or PLC Controlled Operation: The plant can be designed with either a microprocessor or a complete PLC-controlled automatic operating system, catering to specific customer requirements for advanced automation.",
    ];

    const data = [
        {
            title: "Ointment Manufacturing Plant",
            description: "The Ointment Manufacturing Plant is a comprehensive set of equipment specifically designed for the production of ointments, gels, creams, and lotions, accommodating capacities ranging from 5 kg to 1000 kg. For those requiring higher capacities, detailed information and specifications can be provided upon customer request.",
        },
        {
            title: "Water Phase Vessel",
            description: "The Water Phase Vessel is a cylindrical tank with jacketing, featuring Torri-spherical dish ends for optimal structural integrity. It is equipped with a propeller-type stirrer designed to efficiently agitate the liquid within the vessel.",
        },
        {
            title: "Wax Phase Vessel",
            description: "The Wax Phase Vessel is a cylindrical tank with jacketing and Torri-spherical dish ends, ensuring structural stability. It is equipped with a propeller-type stirrer designed to agitate the wax or oil effectively. Additionally, a conical filter is integrated into the system to remove impurities before transferring the wax to the manufacturing vessel, ensuring a purified and high-quality wax phase.",
        },
        {
            title: "Manufacturing Vessel",
            description: "The Manufacturing Vessel is a jacketed cylindrical vessel with a conical bottom and an anchor-type agitator. Its primary function is to blend the viscous product obtained from the Water and Wax Phase Vessels, ensuring thorough mixing and homogeneity in the manufacturing process.",
        },
        {
            title: "Homogenizer",
            description: "The Homogenizer is employed in conjunction with the manufacturing vessel to effectively reduce particle size and achieve a uniform mixing of the product. This crucial step enhances the overall quality and consistency of the final product.",
        },
        {
            title: "Storage Vessel",
            description: "The Storage Vessel is a tank featuring a conical bottom, serving as the container for collecting and storing the product until it is ready for transfer to the filling line. This vessel can be customized to include an anchor-type stirrer and a jacket, meeting specific requirements and ensuring optimal storage conditions for the product.",
        },
        {
            title: "Transfer Pump",
            description: "The Transfer Pump (twin lobe) plays a crucial role in transferring the product from the manufacturing vessel to the storage vessel. This type of pump is specifically employed for efficient and reliable movement of the product within the production process.",
        },
        {
            title: "Metering Pump",
            description: "The Metering Pump is a positive displacement pump that enables the controlled transfer of the product to the filling line at a specified flow rate. This precision pump ensures accurate and consistent dispensing of the product during the filling process.",
        },
        {
            title: "Inter-Connecting Pipeline",
            description: "The Inter-Connecting Pipeline is meticulously designed and incorporated into the plant according to the specified layout. To meet high hygiene standards, all pipes are electropolished from the inside, ensuring a smooth and clean surface for the seamless transfer of materials within the manufacturing process.",
        },
        {
            title: "Working Platform",
            description: "A purpose-built Working Platform is included to facilitate the maintenance and operation of the plant. This platform is designed to provide a convenient and safe environment for personnel to carry out necessary tasks, ensuring the efficient functioning of the manufacturing facility.",
        },
        {
            title: "Control Panel",
            description: "The electrical control panel serves as the centralized hub for all essential electrical components and controls required to operate the plant. It is custom-built to accommodate specific automation needs, enabling a reduction in human interventions and enhancing the overall efficiency of plant operations.",
        },
    ]

    return (
        <div className="text-justify">
            <Image className="d-none d-md-block float-right ms-2" src={ointment_manufacturing_plants} alt="Lab Blender Equipment" style={{ borderRadius: "10px", width: 400 }} />
            <div>
                The Ointment Manufacturing Plants serve as essential assets for the pharmaceutical and cosmetic industries, facilitating the production of ointments, creams, lotions, toothpaste, shampoo, and other emulsions and homogenizations. Specifically crafted to address two crucial factors that directly impact the quality of ointments and creams, our machine is tailored to minimize manual handling of the ointment and ensures negligible vacuum drop during mixing and blending.
            </div>
            <br />
            <div>
                Our system is designed with precision to optimize the utilization of manpower effectively, providing benefits such as reduced manual intervention and streamlined processes. The focus on minimizing vacuum drop ensures the integrity of the mixing and blending process, contributing to the overall quality of the produced ointments and creams.
            </div>
            <div>
                With a commitment to quality and efficiency, our Ointment Manufacturing Plants stand as reliable tools for meeting the diverse needs of the pharmaceutical and cosmetic industries.
            </div>
            <div className="text-center d-md-none">
                <Image className="w-75" src={ointment_manufacturing_plants} alt="Lab Blender Equipment" style={{ borderRadius: "10px" }} />
            </div>
            {/* <DataCard
                title="Key Features"
                component={<DataList dataList={keyFeatures} />}
            /> */}
            <BulletPointContainer
                heading={<div className="text-start">Key Features</div>}
                variant={VARIANTS.BULLETS}
                firstRowFullWidth={true}
                numberOfColumns={2}
                bulletPointArray={keyFeatures.map(r => ({ description: r }))}
            />
            {data.map((info, i) => (
                <DataCard
                    key={i}
                    title={info?.title}
                    description={info?.description}
                />
            ))}
        </div>
    );
};

export default OintmentManufacturingPlantData;
