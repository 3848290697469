import { blog_image1, blogs_cover } from "assets";
import BlogCard from "components/Blogs/BlogCard";
import PageTitleCover from "components/Site/PageTitleCover";
import React, { useState } from "react";
import { Card, CardGroup, Col, Row } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";

const description =
  "Capitalise on low hanging fruit to identify a ballpark value added activity to beta test. Override the digital divide with additional clickthroughs.";

const blogsData = [
  {
    title: "Round and round like a carousel",
    image: blog_image1,
    content:
      "Lab Multi Mill is a portable unit used for Milling and size reduction in Pharmaceutical, chemical, cosmetics, Food and Nutraceutical industries. ",
    date: "October 4, 2022",
  },
  {
    title: "Round and round like a carousel",
    image: blog_image1,
    content:
      "Lab Multi Mill is a portable unit used for Milling and size reduction in chemical, cosmetics, Food. ",
    date: "October 4, 2022",
  },
  {
    title: "Round and round like a carousel",
    image: blog_image1,
    content:
      "Lab Multi Mill is a portable unit used for Milling and size reduction in Pharmaceutical, chemical, cosmetics, Food and Nutraceutical industries. ",
    date: "October 4, 2022",
  },
  {
    title: "Round and round like a carousel",
    image: blog_image1,
    content:
      "Lab Multi Mill is a portable unit used for Milling and size reduction in Pharmaceutical, chemical, cosmetics, Food and Nutraceutical industries. ",
    date: "October 4, 2022",
  },
];

const Blogs = () => {
  const small = useMediaQuery({ query: "(min-width: 576px)" });
  const medium = useMediaQuery({ query: "(min-width: 768px)" });
  const large = useMediaQuery({ query: "(min-width: 992px)" });
  // const [blogsData, setBlogsData] = useState([]);

  return (
    <>
      <PageTitleCover
        backgroundImage={blogs_cover}
        title="Blogs"
        description={description}
      />
      <div className="content-wrapper py-4">
        <CardGroup>
          <Row xs={1} md={2} className="g-4 w-100">
            {blogsData?.map((blog, i) => {
              return (
                <Col xs={12} md={6} lg={4} key={i}>
                  <BlogCard blog={blog} />
                </Col>
              );
            })}
          </Row>
        </CardGroup>
      </div>
      <section className="py-4" />
    </>
  );
};

export default Blogs;
