import React from "react";
import { Image } from "react-bootstrap";
import DataCard from "components/Site/DataCard";
import DataList from "components/Site/DataList";
import { vial_capping_machine } from "assets/images/equipments";
import BulletPointContainer, { VARIANTS } from "components/Commons/BulletPoint";

const VialCappingMachineData = () => {

    const keyFeatures = [
        "Adjustable Conveyor Belt Height: Allows for the adjustable height of the conveyor belt, facilitating alignment with other machines in the production line.",
        "Variable Sealing Pressure: Offers the flexibility to vary sealing pressure, catering to different gauges and sizes of caps for optimal sealing results.",
        "Self-Lubricating Guide Profile: Utilizes self-lubricating UHMW-PE guide profiles for a low-friction wear surface, ensuring smooth and noiseless conveying during the sealing process.",
        "Synchronized A/C Drives: Incorporates synchronized A/C drives to coordinate the movement of the conveyor, star wheel, platform turret, and capping bowl, contributing to seamless and efficient operation.",
        "Adjustable Vials Height Gauge: Provides an adjustable vials height gauge, facilitating easy and quick setting for enhanced user convenience and setup efficiency.",
    ];

    return (
        <div className="text-justify">
            <div>
                <div>
                    <Image className="w-50 ms-3 d-none d-md-block" src={vial_capping_machine} alt="Lab Blender Equipment" style={{ borderRadius: "10px", height: "100%", float: "right" }} />
                    <div>
                        Automatic Multi-head Vial Cap Sealing Machine is a robust and efficient solution for sealing vials. The self-supported stainless steel structure with adjustable height, along with stainless steel enclosures and doors, reflects durability and hygiene in its design.
                    </div>
                    <br />
                    <div>
                        The feeding process, where vials move on a conveyor belt and are directed into an infeed star wheel through an infeed worm, showcases a systematic and organized approach to handling vials. The subsequent indexing part positions the vial below the sealing head, and simultaneously, a cap is picked up from the delivery chute of the cap filling bowl.
                    </div>
                    <br />
                    <div>
                        The synchronized movement of the vial and cap ensures that the body and neck of the vial are precisely positioned below the rotating head, where the sealing operation takes place. This design promotes efficiency and accuracy in the sealing process.
                    </div>
                    <br />
                    <div>
                        Overall, your machine appears to offer a seamless and reliable solution for vial cap sealing, combining precision with user-friendly features.
                    </div>
                </div>
            </div>
            {/* <DataCard
                title="Key Features"
                component={<DataList dataList={keyFeatures} />}
            /> */}
            <BulletPointContainer
                heading={<div className="text-start">{"Key Features"}</div>}
                bulletPointArray={keyFeatures.map(r => ({ description: r }))}
                variant={VARIANTS.BULLETS}
                numberOfColumns={2}
                justify="flex-start"
                lastRowFullWidth={true}
            />
            <div className="text-center">
                <Image className="w-50 d-md-none" src={vial_capping_machine} alt="Lab Blender Equipment" style={{ borderRadius: "10px" }} />
            </div>
        </div>
    );
};

export default VialCappingMachineData;
