import BulletPointContainer, { VARIANTS } from "components/Commons/BulletPoint";
import React from "react";

const ElectricalMechanicalWorkData = () => {
    return (
        <div className="text-justify">
            {/* <Image src="" alt="" /> */}
            <div>
                Our Mechanical Design Department specializes in providing comprehensive
                design and detailed engineering services for static and rotating equipment,
                packages, and HVAC systems. Our team is well-versed in various international
                codes and standards, ensuring that our designs adhere to the highest industry
                benchmarks. Whether it's the meticulous planning of static structures or the
                dynamic engineering of rotating equipment, we bring a wealth of expertise to
                every project. Our commitment to excellence extends to HVAC systems, where we
                integrate cutting-edge design principles to create efficient and reliable solutions.
            </div>
            <br />
            <div>
                In our Mechanical Engineering document arsenal, we cover a comprehensive range of services:
                <BulletPointContainer
                    variant={VARIANTS.BULLETS}
                    numberOfColumns={2}
                    justify="flex-start"
                    bulletPointArray={[
                        {
                            title: "Mechanical Data Sheet Preparation",
                            description: "Crafting detailed documents outlining essential mechanical specifications."
                        },
                        {
                            title: "Preparation of General Arrangement Drawings",
                            description: "Creating comprehensive visual representations of mechanical systems."
                        },
                        {
                            title: "Technical Clarifications During Contract Finalization",
                            description: "Providing expertise and clarifications to ensure smooth contract finalization."
                        },
                        {
                            title: "Final Mechanical Design Verification of Compliance with International Codes",
                            description: "Rigorous verification to guarantee compliance with international codes and standards."
                        },
                        {
                            title: "Strength Calculations",
                            description: "Conducting thorough strength calculations to ensure structural integrity."
                        },
                        {
                            title: "Detailed Manufacturing Drawings",
                            description: "Producing intricate drawings to guide the manufacturing process."
                        },
                        {
                            title: "Preparation of Bill of Materials",
                            description: "Creating detailed lists of materials required for construction or manufacturing."
                        },
                        {
                            title: "Specifications for Raw Materials",
                            description: "Defining specifications for the raw materials used in mechanical components."
                        },
                        {
                            title: "Purchase Specifications",
                            description: "Outlining specifications for the purchase of mechanical equipment and components."
                        },
                        {
                            title: "HVAC",
                            description: "Incorporating expertise in Heating, Ventilation, and Air Conditioning systems to enhance overall project functionality."
                        },
                    ]}
                />
            </div>
            <div>
                These documents collectively form a robust foundation, ensuring the precision,
                compliance, and efficiency of mechanical engineering processes.
            </div>
            <br />
            <div>
                In our Electrical Department, we pride ourselves on delivering design
                engineering services across various industry segments. Here's an overview
                of the comprehensive Electrical Engineering documents we provide:
                <BulletPointContainer
                    numberOfColumns={1}
                    variant={VARIANTS.BULLETS}
                    justify="flex-start"
                    lastRowFullWidth={false}
                    bulletPointArray={[
                        {
                            "title": "Project Specification",
                            "description": "Outlining detailed specifications for electrical projects."
                        },
                        {
                            "title": "Load Flow Studies",
                            "description": "Conducting studies to analyze and optimize the flow of electrical power."
                        },
                        {
                            "title": "Selection of HV/LV Switchgear and Motor Control Centers",
                            "description": "Expertly choosing high-voltage/low-voltage switchgear and motor control centers."
                        },
                        {
                            "title": "Selection of AC/DC UPS",
                            "description": "Optimal selection of AC/DC Uninterruptible Power Supply systems."
                        },
                        {
                            "title": "Selection of Distribution Transformers",
                            "description": "Choosing the right distribution transformers for efficient power distribution."
                        },
                        {
                            "title": "Cable Tray/Trench Layouts",
                            "description": "Designing layouts for cable trays and trenches to organize and protect cables."
                        },
                        {
                            "title": "Cable Routing Layouts",
                            "description": "Planning the routes for efficient cable placement."
                        },
                        {
                            "title": "Lighting (Indoor/Outdoor) Layouts",
                            "description": "Designing lighting layouts for both indoor and outdoor spaces."
                        },
                        {
                            "title": "Power and Control Cable Schedules",
                            "description": "Creating schedules for power and control cables to facilitate accurate installations."
                        },
                        {
                            "title": "SCADA I/O List",
                            "description": "Compiling a comprehensive list of inputs and outputs for Supervisory Control and Data Acquisition systems."
                        },
                        {
                            "title": "Selection of Feeder/Motor/Transformer Protection Relays",
                            "description": "Expertly choosing protection relays for feeders, motors, and transformers."
                        },
                        {
                            "title": "Switchyard/Transformer Layouts",
                            "description": "Designing layouts for switchyards and transformers."
                        },
                        {
                            "title": "Switchgear/MCC Room Layout",
                            "description": "Planning the layout for switchgear and Motor Control Center rooms."
                        }
                    ]
                    }
                />
            </div>
            <div>
                These documents collectively ensure the precision, reliability, and
                efficiency of electrical systems across diverse industry applications.
            </div>
        </div>
    );
};

export default ElectricalMechanicalWorkData;
