import React, { useState } from "react";
import { Image, Row, Col } from "react-bootstrap";
import DataCard from "components/Site/DataCard";
import DataList from "components/Site/DataList";
import { auger_type_1, auger_type_2, dry_syrup, injectable_powder_vial_filling, injectable_powder_vial_filling_420 } from "assets/images/equipments";
import BulletPointContainer, { VARIANTS } from "components/Commons/BulletPoint";

const PowderFillingData = () => {

    const [active, setActive] = useState(0);

    const augerSalientFeatures = [
        "No Bottle – No fill System ensures accurate and efficient filling.",
        "Low Air – Machine Stop System enhances safety and operational control.",
        "All contact parts made of SS316 and Nylon/HDPE material, ensuring hygienic and durable performance.",
        "Easy removal system for cleaning, facilitating maintenance and sanitation.",
        "Machine construction in SS304 material, providing durability and corrosion resistance.",
        "AC Variable Frequency Drive Speed Control for precise and customizable operation.",
        "Servo Driven Filling Head (optional) for enhanced accuracy and control.",
        "Built-in Turn Tables with Separate Drive for speed control (optional), contributing to the efficiency of the feeding process.",
    ]

    const drySyrupSalientFeatures = [
        "Stainless Steel structure with all contact parts made from SS 316 Materials and HDPE Funnels, ensuring durability and hygiene.",
        "No Bottle - No Fill System for precise and efficient filling operation.",
        "Unique continuous motion Powder Wheel movement for smoother operation.",
        "Rotary Bottle Movement System with Rotary Funnel System for optimized performance.",
        "Funnel with Unique Vibratory system for filling semi-free-flow powder at higher speed, enhancing efficiency.",
        "Easy removal system for contact parts cleaning, auto claving, or sterilization, facilitating maintenance.",
        "Filling Accuracy of +1% to 2% depending on powder at controlled humidity below 30% RH, ensuring precision.",
        "Automatic Infeed and Exit of Bottles through Star Wheel System for streamlined operation.",
        "No Bottle at infeed conveyor - Automatic Machine on/off system for operational control.",
        "Low Air - Machine Stop System for enhanced safety.",
        "Low Vacuum - Machine Stop System for operational control.",
        "Low Cap Level - Machine Stop System for improved reliability.",
        "Automatic Cap Feeder On - Off System to avoid damage to the cap, reducing unnecessary movement.",
        "Toughened Glass Safety Cabinet for added safety.",
        "Blower for dust extraction, maintaining cleanliness in the working environment.",
    ]

    const injectionSalientFeatures = [
        "Easily removable contact parts for Autoclaving / Sterilization.",
        "All parts in contact with the powder are made of S.S 316L, ensuring hygiene and durability.",
        "Individual drives for Infeed and Outfeed turntables, providing precise control.",
        "Safety clutch system in place to prevent vial breakage and ensure smooth operation.",
        "A / C Frequency Drive for adjustable speed control.",
        "Pure Air Filters for both air and vacuum systems, maintaining a clean environment.",
        "Online Pre - Media Filling System for enhanced efficiency.",
        "Pre & Post Gassing System to optimize the filling process.",
        "Multiple Dosing System for versatility in handling different dosages and container sizes.",
    ]

    const injectionOptionalFeatures = [
        <div>
            <b>Canister Based Powder Loading System:</b>
            <ul>
                <li>Efficient and precise powder loading.</li>
                <li>Minimizes wastage.</li>
                <li>Enhances overall accuracy.</li>
            </ul>
        </div>,
        <div>
            <b>Laminar Air Flow System:</b>
            <ul>
                <li>Creates a controlled, sterile environment.</li>
                <li>Maintains product integrity.</li>
            </ul>
        </div>,
        <div>
            <b>PLC with Touchscreen HMI:</b>
            <ul>
                <li>Advanced control system.</li>
                <li>User-friendly operation.</li>
                <li>Optimizes machine efficiency.</li>
            </ul>
        </div>,
        <div>
            <b>Dust Extraction Unit:</b>
            <ul>
                <li>Promotes a cleaner and safer working atmosphere.</li>
                <li>Contributes to a healthier workspace.</li>
            </ul>
        </div>,
        <div>
            <b>Swing Type Conveyor:</b>
            <ul>
                <li>Provides flexibility and convenience.</li>
                <li>Adapts to dynamic production requirements.</li>
            </ul>
        </div>,
    ]

    const data = [
        {
            title: "Auger Powder Filling",
            description:
                <>
                    <div>
                        The Automatic Auger Powder Filling Machine is designed for filling dry syrup, powder, and granules into various types and shapes of bottles, achieving a maximum speed of up to 60 bottles per minute. The filling capacity depends on factors such as the type of powder, filling volume, and bottle size. The machine features a proven Augur type Filling System, making it suitable for filling high grammage of powder in single doses. Additionally, the machine incorporates a no-change-parts system to accommodate variations in bottle sizes, facilitated by a pneumatically operated bottle separator system. A built-in turntable for round bottles at the infeed ensures a continuous and efficient feeding process.
                    </div>
                    {/* <DataCard
                        title=""
                        component={<DataList dataList={augerSalientFeatures} />}
                    /> */}
                    <BulletPointContainer
                        heading={<div className="text-start">Salient Features of the Automatic Auger Powder Filling Machine:</div>}
                        variant={VARIANTS.BULLETS}
                        lastRowFullWidth={true}
                        bulletPointArray={augerSalientFeatures.map(r => ({description: r}))}
                    />
                    <br />
                    <Row>
                        <Col className="text-center">
                            <div>
                                <Image className="w-75" src={auger_type_1} alt="Auger Type 1" style={{ borderRadius: "10px" }} />
                            </div>
                            <div className="mt-2">
                                <i>Automatic Servo Base Auger Type 1 - Head</i>
                            </div>
                        </Col>
                        <Col className="text-center">
                            <div>
                                <Image className="w-75" src={auger_type_2} alt="Auger Type 2" style={{ borderRadius: "10px" }} />
                            </div>
                            <div className="mt-2">
                                <i>Automatic Servo Base Auger Type 2 - Head</i>
                            </div>
                        </Col>
                    </Row>
                </>
            ,
        },
        {
            title: "Dry Syrup Powder Filling",
            description:
                <div className="text-justify">
                    <div>
                        The Automatic Rotary Dry Syrup Powder Filling & Capping Machine (monobloc) is designed for filling and capping Glass, Plastic, PET, HDPE, and LDPE bottles, with a maximum speed of 100 bottles per minute. The filling speed depends on factors such as powder fill volume, type of powder, bottle diameter, and bottle neck diameter. The machine utilizes time-tested Vacuum and Air Systems to achieve higher speed and accuracy in powder filling. It features a unique Bottle Lifting and Neck Holding system to prevent spillage of powder on the bottle shoulder, ensuring precision and cleanliness in the filling process.
                    </div>
                    <br />
                    <b></b>
                    <div className="text-center my-3">
                        <Image src={dry_syrup} alt="Dry Syrup Powder Filling" style={{ borderRadius: "10px", width: 400 }} />
                    </div>
                    {/* <DataCard
                        title=""
                        component={<DataList dataList={drySyrupSalientFeatures} />}
                    /> */}
                    <BulletPointContainer
                        heading={<div className="text-start">Key Features of the Automatic Rotary Dry Syrup Powder Filling & Capping Machine:</div>}
                        variant={VARIANTS.BULLETS}
                        justify="flex-start"
                        bulletPointArray={drySyrupSalientFeatures.map(r => ({description: r}))}
                    />
                </div>
            ,
        },
        {
            title: "Injectable Dry Powder Filling",
            description:
                <>
                    <div>
                        Injectable Dry Powder Filling Machine is designed for efficiently filling dry syrup powder and granules into various types and shapes of bottles at a maximum speed of 240 bottles per minute. Here's how it operates:
                    </div>
                    <BulletPointContainer
                        numberOfColumns={3}
                        variant={VARIANTS.BULLETS}
                        lastRowFullWidth={true}
                        justify="flex-start"
                        bulletPointArray={[
                            {
                                title: "Sterilized Dry Container Placement",
                                description: "Sterilized dry containers are guided on a Delrin flat conveyor belt at the required speed, positioned below the powder wheel."
                            },
                            {
                                title: "Agitation and Powder Storage",
                                description: "The sterilized powder stored in the hopper is agitated by a pair of mechanical agitators to maintain a consistent powder flow."
                            },
                            {
                                title: "Powder Wheel Operation",
                                description: "The powder wheel, equipped with eight ports, rotates at a pre-determined speed. During the vacuum phase, a precise volume of powder is drawn into the port of the powder wheel."
                            },
                            {
                                title: "Dosing and Filling",
                                description: "Compressed air (or Nitrogen Gas) is used to flush out the powder from the port of the powder wheel into the containers one by one. Different fill sizes can be achieved based on the piston length."
                            },
                            {
                                title: "Container Movement",
                                description: "Filled containers move forward for the stoppering operation. Various grooves separators can be used depending on the container diameter or multiple dosing systems."
                            },
                            {
                                title: "Rubber Stopper Placement",
                                description: "Sterilized rubber stoppers, stored in the vibrator bowl, move to the vibratory bowl and are stacked vertically in the rubber stopper chute."
                            },
                            {
                                title: "Stopper Fixing",
                                description: "The container is held firmly between a pair of timing belts to pick up a rubber stopper from the exit end of the chute. The container then passes between two pressing rollers for a tight fit and fixing of the rubber stopper."
                            },
                        ]}
                    />
                    <div>
                        This process ensures precise and efficient filling of dry syrup powder into containers, maintaining sterility and accuracy throughout the operation.
                    </div>
                    {/* <DataCard
                        title=""
                        component={<DataList dataList={airJetKeyFeatures} />}
                    /> */}
                    <div className="d-flex flex-column text-center my-3">
                        <div className="text-center">
                            <Image className="w-50" src={injectable_powder_vial_filling} alt="Injectable Powder Vial Filling" style={{ borderRadius: "10px" }} />
                        </div>
                        <div className="mt-2">
                            <i>Injectable Powder Vial Filling</i>
                        </div>
                    </div>
                    <BulletPointContainer
                        heading={<div className="text-start">Salient Features:</div>}
                        bulletPointArray={injectionSalientFeatures.map(r => ({description: r}))}
                        variant={VARIANTS.BULLETS}
                        justify="flex-start"
                    />
                    <div className="d-flex flex-column text-center">
                        <div className="text-center">
                            <Image className="w-50" src={injectable_powder_vial_filling_420} alt="Injectable Powder Vial Filling 420" style={{ borderRadius: "10px" }} />
                        </div>
                        <div className="mt-2">
                            <i>Injectable Powder Vial Filling 420</i>
                        </div>
                    </div>
                    {/* <DataCard
                        title=""
                        component={<DataList dataList={injectionOptionalFeatures} />}
                    /> */}
                    <BulletPointContainer
                        heading={<div className="text-start">Optional Features:</div>}
                        bulletPointArray={injectionOptionalFeatures.map(r => ({description: r}))}
                        variant={VARIANTS.BULLETS}
                        lastRowFullWidth={true}
                        justify="flex-start"
                    />
                </>
            ,
        },
    ]

    return (
        <>
            <div className="d-flex align-items-center py-1 overflow-scroll scrollbar-none">
                {data.map((tab, i) => {
                    return (
                        <div
                            className={`d-flex align-items-center px-2 px-sm-3 px-lg-4 pb-2 text-center ${active === i ? "text-primary active-tab px-sm-" : ""
                                }`}
                            style={{ minWidth: 120, alignSelf: "stretch" }}
                            onClick={() => setActive(i)}
                        >
                            <p>{tab.title}</p>
                        </div>
                    )
                })}
            </div>
            <div className="d-flex flex-column py-2">
                {data[active]?.description}
            </div>
        </>
    );
};

export default PowderFillingData;
