import { logo_dark } from "assets";
import PrimaryLine from "components/Site/PrimaryLine";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import { FaFacebookF, FaTwitter, FaLinkedin } from "react-icons/fa";
import { FiInstagram, FiPhone } from "react-icons/fi";
import { IoMailOutline } from "react-icons/io5";
import { SlLocationPin } from "react-icons/sl";
import { contactInfoData } from "pages/ContactUs/ContactInfoData";

const socialLinks = [
  { icon: <FaFacebookF size={20} />, link: null },
  { icon: <FaTwitter size={20} />, link: null },
  { icon: <FiInstagram size={20} />, link: null },
  {
    icon: <FaLinkedin size={20} />,
    link: "https://www.linkedin.com/company/proactimo-projects-pvt-ltd/?viewAsMember=true",
  },
];

const helpLinksData = [
  // { title: "Customers Services", link: "" },
  { title: "Home", link: "" },
  { title: "About Us", link: "about-us" },
  { title: "Clients", link: "clients" },
  { title: "Contact Us", link: "contact-us" },
];

const servicesData = [
  { title: "Engineering Consultancy", link: "" },
  { title: "Services", link: "" },
  { title: "Equipment", link: "" },
];

const FooterMain = () => {
  const small = useMediaQuery({ query: "(min-width: 576px)" });
  const medium = useMediaQuery({ query: "(min-width: 768px)" });
  const large = useMediaQuery({ query: "(min-width: 992px)" });

  return (
    <div className="bg-black text-white py-5">
      <div className="content-wrapper text-white mx-auto ">
        <Row xs={1} lg={4} className="text-white g-2">
          {/* ----------col 1---------- */}
          <Col>
            <div className="mb-4">
              <div
                href="/"
                className="me-1 mb-4"
                style={{
                  width: large ? 200 : medium ? 160 : small ? 140 : 120,
                }}
              >
                <img src={logo_dark} alt="Proactimo" className="img-fluid" />
              </div>
              {/* <p className="mb-4 me-3">
                Powering Progress Proactively
              </p> */}
              <div className="d-flex align-items-center mb-3">
                {socialLinks?.map((item, i) => {
                  return (
                    <div
                      key={i}
                      className="d-flex justify-content-center align-items-center bg-white text-black me-3"
                      style={{ width: 32, height: 32, borderRadius: "50%" }}
                    >
                      <a
                        href={item.link}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-black"
                      >
                        {item.icon}
                      </a>
                    </div>
                  );
                })}
              </div>
            </div>
          </Col>
          {/* ----------col 2---------- */}
          <Col>
            <div className="mb-4">
              <div className="d-flex align-items-center mb-4">
                <h4 className="me-3">Help Links</h4>
                <PrimaryLine allowMargin={false} style={{ width: 50 }} />
              </div>
              {helpLinksData?.map((item, i) => {
                return (
                  <a
                    key={i}
                    href={`/${item.link}`}
                    target="_blank"
                    className="text-decoration-none"
                  >
                    <p className="mb-2 p-0 text-white">{item.title}</p>
                  </a>
                );
              })}
            </div>
          </Col>
          {/* ----------col 3---------- */}
          <Col>
            <div className="mb-4">
              <div className="d-flex align-items-center mb-4">
                <h4 className="me-3">Services</h4>
                <PrimaryLine allowMargin={false} style={{ width: 50 }} />
              </div>
              {servicesData?.map((item, i) => {
                return (
                  <p key={i} className="mb-2">
                    {item.title}
                  </p>
                );
              })}
            </div>
          </Col>
          {/* ----------col 4---------- */}
          <Col>
            <div className="mb-4">
              <div className="d-flex align-items-center mb-4">
                <h4 className="me-3">Official Info</h4>
                <PrimaryLine allowMargin={false} style={{ width: 50 }} />
              </div>
              {contactInfoData?.map((item, i) => {
                return (
                  <a
                    key={i}
                    className="d-flex mb-3 text-white text-decoration-none"
                    href={item.link}
                  >
                    <div
                      className="d-flex justify-content-center align-items-center bg-primary rounded mt-2 me-3"
                      style={{ minWidth: 40, height: 40 }}
                    >
                      {item.icon}
                    </div>
                    <div>
                      <p className="mb-2">{item.title}</p>
                      <p className="d-flex">{item.data}</p>
                    </div>
                  </a>
                );
              })}
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default FooterMain;
