import React from "react";
import HomeCover from "sections/Home/HomeCover";
import AboutUsSection from "sections/About/AboutUsSection";
import OurServicesSection from "sections/About/OurServicesSection";
import ClientsSection from "sections/Home/ClientsSection";
import { HomeCarousel } from "components/Home/HomeCarousel";

const Home = () => {
  return (
    <>
      {/* <HomeCover /> */}
      <HomeCarousel />
      <div className="content-wrapper">
        <AboutUsSection />
        <OurServicesSection />
        <ClientsSection />
      </div>
      <section className="my-4 py-4"></section>
    </>
  );
};

export default Home;
