import React from "react";
import { Col } from "react-bootstrap";

const PageTitleCover = ({
  backgroundImage,
  title,
  description,
  centeredContent,
  //   buttonTitle,
  //   link,
  //   minHeight,
  //   className,
  //   style,
}) => {
  return (
    <section
      className="d-flex align-items-center py-3"
      style={{
        background: `url(${backgroundImage})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        minHeight: 310,
        // minHeight: minHeight ?? 310,
      }}
    >
      <div className="content-wrapper w-100">
        <Col
          lg={centeredContent ? 12 : 7}
          className="text-center text-lg-start"
        >
          <div className={`text-white ${centeredContent ? "text-center" : ""}`}>
            <h1 className="mb-2" style={{ lineHeight: 1.3 }}>
              <div className={`${centeredContent ? "d-flex justify-content-center" : ""}`}>
                <div className={`blur-text-background`}>
                  {title}
                </div>
              </div>
            </h1>
            {description && (
              <p className="blur-text-background">
                {description}
              </p>
            )}
            {/* {buttonTitle && <SiteButton title={buttonTitle} onclick={{}} />} */}
          </div>
        </Col>
      </div>
    </section>
  );
};

export default PageTitleCover;
