import React from "react";
import { Image } from "react-bootstrap";
import DataCard from "components/Site/DataCard";
import DataList from "components/Site/DataList";
import { volumetric_liquid_filling_machines } from "assets/images/equipments";
import BulletPointContainer, { VARIANTS } from "components/Commons/BulletPoint";

const WeighMetricOilFillingMachineData = () => {

    const keyFeatures = [
        "Filling Range: 500ml to 5 litres & 2 to 15 litres & Kg.",
        "Sleek and Elegant Matt Finished Body.",
        "Nozzle Type: Diving Nozzle with positive cut - off, ensuring precise filling.",
        "Accuracy of Filling: +/- 0.25% of the filled volume, guaranteeing precision.",
        "Volume Setting: Easily adjustable on the HMI with a 7 - inch LCD display for user convenience.",
        "Mitsubishi Make PLC & HMI, ensuring reliable performance.",
        "No Container No Fill: Non - contact sensors facilitate a foolproof No Container No Fill System Arrangement.",
        "Models: Available in 4, 6, and 8 head configurations, catering to various production requirements.",
        "Drip - Arresting Tray: Positioned below the nozzle to catch accidental drips, maintaining cleanliness and preventing wastage.",
    ];

    return (
        <div className="text-justify">
            <div>
                The Automatic Weigh Metric Oil Filling Machine stands out as an ideal choice for filling edible oil, jars, tins, and other free-flowing materials, either by volume or weight. The weight is meticulously controlled by a load cell paired with a microprocessor programmable system, ensuring exceptional accuracy in the feeding process. This versatile machine accommodates a filling range from 500 ml to 5 liters, with higher capacity models also readily available.
            </div>
            <div className="text-center my-3">
                <Image src={volumetric_liquid_filling_machines} alt="Lab Blender Equipment" style={{ borderRadius: "10px", width: 400 }} />
            </div>
            {/* <DataCard
                title="Key Features"
                component={<DataList dataList={keyFeatures} />}
            /> */}
            <BulletPointContainer
                heading={<div className="text-start">{"Key Features"}</div>}
                bulletPointArray={keyFeatures.map(r => ({ description: r }))}
                variant={VARIANTS.BULLETS}
                justify="flex-start"
                lastRowFullWidth={true}
            />
        </div>
    );
};

export default WeighMetricOilFillingMachineData;
