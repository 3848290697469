import { blog_image1, blogs_cover } from "assets";
import PageTitleCover from "components/Site/PageTitleCover";
import PrimaryLine from "components/Site/PrimaryLine";
import { siteNavigation } from "navigation/SiteNavigation";
import React, { useState } from "react";
import { Accordion, Col, Row } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import { useLocation, useNavigate } from "react-router-dom";

const SidebarNavItem = ({ title, isActive, onClick }) => {
  return (
    <div
      className={`p-3 py-2 mb-3 cursor-pointer ${
        isActive ? "bg-primary text-white" : "bg-white"
      }`}
      onClick={onClick}
    >
      <p>{title}</p>
    </div>
  );
};

// function getRouteObject(path) {
//   return siteNavigation.find((route) => route.path === path);
// }

function findObjectByPath(data, targetPath) {
  for (const item of data) {
    if (item.path === targetPath) return item;

    if (item.subroutes && item.subroutes.length > 0) {
      const foundObject = findObjectByPath(item.subroutes, targetPath);
      if (foundObject) return foundObject;
    }
  }
  return null;
}

const CustomPageView = ({ dataComponent }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const params = location.pathname.split("/").filter((item) => item !== "");
  // const params = params.split("/");
  const param1 = params[0].split("-").join(" ");
  console.log(location, params, param1);
  // console.log("CustomPageView: ", data);

  const small = useMediaQuery({ query: "(min-width: 576px)" });
  const medium = useMediaQuery({ query: "(min-width: 768px)" });
  const large = useMediaQuery({ query: "(min-width: 992px)" });

  const currentRouteParentObject = findObjectByPath(siteNavigation, params[0]);

  const currentPageObject = findObjectByPath(
    siteNavigation,
    params[params?.length - 1]
  );
  // console.log(currentPageObject, currentRouteParentObject);

  return (
    <>
      <PageTitleCover
        backgroundImage={currentPageObject?.cover_image ?? blogs_cover}
        title={currentPageObject.title}
        description={currentPageObject?.description}
      />
      <section className="content-wrapper py-4">
        <div className="d-flex justify-content-between">
          <div
            // lg={4}
            className="d-none d-lg-inline-block"
            style={{ width: "27%", position: "sticky", top : 0 }}
          >
            <div className="p-3 mb-3" style={{ background: "#f2f1ed" }}>
              <div className="d-flex align-items-center mb-3">
                <PrimaryLine
                  allowMargin={false}
                  className="me-3"
                  style={{ width: 40 }}
                />
                <p className="fw-bold text-capitalize">{param1}</p>
              </div>
              <Accordion
                defaultActiveKey={() =>
                  currentRouteParentObject?.subroutes?.findIndex(
                    (route) => route.path === params[1]
                  )
                }
              >
                {currentRouteParentObject?.subroutes?.map((subRoute, i) =>
                  // const activeItemIndexToSet
                  !subRoute.subroutes ? (
                    <SidebarNavItem
                      key={i}
                      title={subRoute.title}
                      isActive={params[1] === subRoute.path}
                      onClick={() =>
                        navigate(
                          `/${currentRouteParentObject.path}/${subRoute.path}`
                        )
                      }
                    />
                  ) : (
                    <Accordion.Item
                      key={i}
                      eventKey={i}
                      className="mb-2"
                      as={"div"}
                    >
                      <Accordion.Header>{subRoute.title}</Accordion.Header>
                      <Accordion.Body>
                        {subRoute?.subroutes?.map((item, j) => (
                          <SidebarNavItem
                            key={j}
                            title={item.title}
                            isActive={params[2] === item.path}
                            // isActive={j === activeSubItemIndex}
                            className="cursor-pointer"
                            onClick={() =>
                              navigate(
                                `/${currentRouteParentObject.path}/${subRoute.path}/${item.path}`
                              )
                            }
                          />
                        ))}
                      </Accordion.Body>
                    </Accordion.Item>
                  )
                )}
              </Accordion>
            </div>
          </div>
          {/* ---------- data ---------- */}
          <div
            // lg={8}
            className="pb-4 mx-auto"
            style={{
              width: large ? "70%" : medium ? "88%" : "100%",
              minHeight: "50vh",
            }}
          >
            {dataComponent}
          </div>
        </div>
      </section>
    </>
  );
};

export default CustomPageView;
