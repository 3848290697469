import React, { useState } from "react";
import { Image } from "react-bootstrap";
import DataCard from "components/Site/DataCard";
import DataList from "components/Site/DataList";
import { top_spraying_1, top_spraying_2, bottom_spraying } from "assets/images/equipments";
import BulletPointContainer, { VARIANTS } from "components/Commons/BulletPoint";

const FluidBedProcessorData = () => {

    const [active, setActive] = useState(0);

    const keyFeatures = [
        "Single Pot technology seamlessly integrates mixing, granulating, and drying processes.",
        "Granulation excellence achieved through the Top Spraying system.",
        `User-friendly Color HMI 7" interface for intuitive operation.`,
        "Various batch size capacity models cater to diverse production needs.",
        "Advanced PLC controls for precision in the granulation system.",
        "Atomized spraying gun and high-precision flow peristaltic pump ensure optimal spraying.",
        "Convenient sampling port for withdrawal of samples during the process.",
        "Pneumatic sealing of Filter Bag and Product container with inflatable silicon tube (food grade).",
        "Inlet Air handler equipped with Micro and HEPA Air Filters and Heat Exchanger.",
        "21 CFR Part 11 compliance available on request for regulatory adherence.",
    ];

    const optionalEnhancements = [
        "Air velocity sensor for precise control.",
        "Exhaust air filter section with Eu4 filter for enhanced air filtration.",
        "Inlet and outlet duct for optimized air flow.",
        "Pneumatic finger bag lifting and lowering for efficient handling.",
        "Flame Proof (FLP) construction for added safety in specific environments.",
        "A.C. driving for exhaust blower for reliable performance.",
        "Sound relaxation silencer for blower for a quieter operation.",
        "Additional SS-316L bowl and SS-304 trolley for increased capacity.",
        "Wet In-Place (WIP) system for easy cleaning and maintenance.",
        "Extra PC satin finger bag for extended use.",
        `Upgraded Color HMI 10" for an enhanced user interface.`,
    ];

    const data = [
        {
            title: "Top Spray",
            description:
                <>
                    <div>
                        The fluid bed process is a dynamic technique employed to transform fine powder into cohesive granules within fluidized beds. This method involves wetting the fluidized powder with a binder through a top spraying system, equipped with a spraying nozzle and precise dosing peristaltic pump. The powder is wetted until liquid bridges form between particles. Notably, segregation is minimized as different powders bond on a micro scale without impinging forces, resulting in weak yet highly soluble granules.
                    </div>
                    <div>
                        After granule formation, the next step involves drying the granules within the equipment using hot air. This technology is particularly valuable for creating granules tailored for tableting, capsules, ensuring a dust-free process, promoting free-flow properties, and enhancing solubility through the porous structure of the granules.
                    </div>
                    <div>
                        Two distinct modes of fluid bed granulation exist: wet stage and dry stage. In dry stage granulation, particles require minimal wetting to become tacky and adhere to one another. The granulating solution is applied at a rate equal to or less than its evaporation rate, maintaining "dry" particles throughout the process. Conversely, wet stage granulation involves significant moisture or granulating solution application, surpassing the evaporation rate until particles accumulate enough moisture for granulation to occur. This versatile process offers flexibility in adapting to different granulation requirements.
                    </div>
                </>
            ,
            image1: top_spraying_1,
            image2: top_spraying_2,
        },
        {
            title: "Bottom Spray",
            description:
                <>
                    <div>
                        The distinctive features of bottom-spraying include an air distribution plate and a partition that collaborate to organize the fluidization of particles through the partition, creating a designated coating zone. Positioned at the bottom of the product container and centered in the coating zone, the nozzle plays a key role. The proximity between the coating materials and particles minimizes spray-drying, ensuring high coating uniformity and efficiency.
                    </div>
                    <div>
                        During the coating process, particles are suspended in the air by establishing a fluidized bed within a heated air stream, and a coating solution is precisely sprayed onto the particles through a Wurster column. Our specially modified bottom spray inserts act as a safeguard for heat-sensitive products, preventing excessive heat exposure while ensuring an even coating on pellets or granules. Critical design parameters, such as chamber geometry, air velocity, and the mass flow ratio of air to product, are meticulously matched in our equipment. This attention to detail ensures optimal performance and consistent results in the coating process.
                    </div>
                </>
            ,
            image1: bottom_spraying,
            image2: null,
        },
    ]

    return (
        <>
            <div className="text-justify">
                The Fluid Bed Processor presents a highly effective and efficient solution
                for the drying, agglomeration, granulation, and pellet coating of particulate
                materials. Its versatile applications make it an ideal choice for a wide range
                of products in industries such as Pharmaceuticals, Biochemicals, Food,
                Confectionery, Agrochemicals, Herbals, and Pharma Institutes. Whether you're
                dealing with pharmaceutical formulations or working in the food and confectionery
                sector, our Fluid Bed Processor delivers superior performance across diverse applications.
            </div>
            {/* <DataCard
                title="Key Features"
                component={<DataList dataList={keyFeatures} />}
            /> */}
            <BulletPointContainer
                heading={<div className="text-start">Key Features</div>}
                justify="flex-start"
                variant={VARIANTS.BULLETS}
                numberOfColumns={2}
                bulletPointArray={keyFeatures.map(r => ({ description: r }))}
            />
            <div>
                Experience the power of seamless integration and precision in granulation with our Fluid
                Bed Processor, offering a range of features to elevate your processing capabilities.
            </div>
            {/* <br /> */}
            {/* <DataCard
                title="Optional Enhancements"
                component={<DataList dataList={optionalEnhancements} />}
            /> */}
            <BulletPointContainer
                heading={<div className="text-start">Optional Enhancements</div>}
                justify="flex-start"
                variant={VARIANTS.BULLETS}
                numberOfColumns={3}
                lastRowFullWidth={true}
                bulletPointArray={optionalEnhancements.map(r => ({ description: r }))}
            />
            <div>
                Customize your Fluid Bed Processor with these optional features to meet specific
                requirements and elevate performance across various applications.
            </div>
            <br />
            <h5>
                Top Spraying & Bottom Spraying System for agglomeration and granulation process:
            </h5>
            <div className="p-2">
                <div className="d-flex align-items-center py-3 overflow-scroll scrollbar-none">
                    {data.map((tab, i) => {
                        return (
                            <div
                                className={`d-flex align-items-center px-2 px-sm-3 px-lg-4 pb-2 text-center ${active === i ? "text-primary active-tab px-sm-" : ""
                                    }`}
                                style={{ minWidth: 120, alignSelf: "stretch" }}
                                onClick={() => setActive(i)}
                            >
                                <p>{tab.title}</p>
                            </div>
                        )
                    })}
                </div>
                <br />
                <div className="text-center d-md-none">
                    <Image className="w-50" src={data[active]?.image1} alt={data[active]?.title} style={{ borderRadius: "10px" }} />
                </div>
                <div className="text-justify">
                    <Image className="d-none d-md-block ms-2 float-right" src={data[active]?.image1} alt={data[active]?.title} style={{ borderRadius: "10px", width: 350 }} />
                    {data[active]?.description}
                </div>
            </div>
            {data[active]?.image2 && <div className="text-center">
                <Image className="w-100" src={data[active]?.image2} alt={data[active]?.title} style={{ borderRadius: "10px" }} />
            </div>}
        </>
    );
};

export default FluidBedProcessorData;
