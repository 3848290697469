import BulletPointContainer, { VARIANTS } from "components/Commons/BulletPoint";
import React from "react";

const UtilitiesData = () => {
    return (
        <div className="text-justify">
            {/* <Image src="" alt="" /> */}
            <div>
                Water, steam, and compressed air are crucial elements delivered through
                partnerships with world-class providers, managed by our in-house design and
                execution teams. The correct design and implementation of process and utility
                piping are foundational to the success of pharmaceutical and biopharmaceutical
                installations. All systems, including process equipment and piping, are
                meticulously designed to be fully drainable, cleanable, and sterilizable,
                ensuring the seamless production of pharmaceuticals.
            </div>
            <br />
            <div>
                Before commencing construction, the Proactimo engineering team offers a
                clear vision of the system's appearance and functionality. Utilizing computer
                simulations, our team visualizes the project, providing valuable insights
                before the project takes off.
            </div>
            <br />
            <div>
                Proactimo's experienced team employs the latest fabrication technology for
                process piping installation. Defined procedures and minimized weld "cut-outs"
                result in cleaner documentation submitted for FDA approval, leading to heightened
                productivity.
            </div>
            <br />
            <div>
                Our experienced site team strictly follows Standard Operating Procedures (SOPs),
                ensuring a systematic approach to welding procedures. This includes consistent
                steps for material handling, tubing cutting and end-prepping, inert gas purging,
                orbital welding, and more.
            </div>
            <br />
            <div>
                <div>
                    Our primary focus is on utility generation and distribution systems, ensuring
                    the efficient and reliable operation of essential utilities in pharmaceutical
                    and biopharmaceutical facilities:
                </div>
                <br />
                <h4>Compressed Air:</h4>
                <div>
                    The utilization of compressed air in the pharmaceutical industry is extensive and
                    diverse. It serves critical functions in various production and packaging applications,
                    such as blowing off and drying bottles before filling, conveying pills, supplying air for
                    pneumatically controlled valves and cylinders, and contributing to breathing air systems.
                </div>
                <div>
                    Given the distinct requirements of each application, there is a need for varying
                    levels of air quality and specific combinations of air treatment to meet the desired standards.
                    The absence of a universal standard underscores the industry's recognition that the uses of
                    compressed air are highly varied, and each process requires a tailored approach to ensure the
                    highest quality and efficiency for pharmaceutical production.
                </div>
                <br />
                <h4>Steam:</h4>
                <div>
                    In the realm of Pharmaceutical steam, there are two fundamental types:
                </div>
                <BulletPointContainer
                    numberOfColumns={1}
                    variant={VARIANTS.BULLETS}
                    bulletPointArray={[
                        {
                            title: "Industrial Steam (Black Steam)",
                            description: <ul className="ps-4 mb-0">
                                <li>Produced in boilers, industrial steam serves as a utility.</li>
                                <li>It does not come into contact with the product or process equipment.</li>
                                <li>Contains additives to safeguard boilers and pipes from precipitation and corrosion.</li>
                            </ul>
                        },
                        {
                            title: "Pharmaceutical Steam (Pure Steam or Clean Steam)",
                            description: <ul className="ps-4 mb-0">
                            <li>Generated from treated water, specifically Purified Water.</li>
                            <li>The steam condensation adheres to pharmacopoeia parameters, meeting the standards for Water for Injection (WFI) grade water.</li>
                            <li>Designed for applications where steam comes into direct contact with pharmaceutical products and process equipment, ensuring high purity and meeting stringent quality requirements.</li>
                        </ul>
                        }
                    ]}
                />

                <h4>Water:</h4>
                <div>
                    We offer comprehensive solutions and complete systems for the generation,
                    storage, and distribution of Purified Water (PW) and steam sterilization,
                    all in accordance with the latest pharmacopeia standards. Our services cover
                    the entire spectrum, starting from pre-treatment processes and encompassing
                    double-pass Reverse Osmosis systems, Electro Deionization systems, water for
                    injection Multi-column Distillation plants, and pure steam generators (PSG).
                    These systems are meticulously designed and implemented to ensure the highest
                    quality and compliance with pharmaceutical standards, meeting the stringent
                    requirements of the latest pharmacopeia guidelines.
                </div>
            </div>
        </div>
    );
};

export default UtilitiesData;
