import { blogs_cover, video_card_cover } from "assets";
import PageTitleCover from "components/Site/PageTitleCover";
import React from "react";
import { Card, CardGroup, Col, Row } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";

const description =
  "Capitalise on low hanging fruit to identify a ballpark value added activity to beta test. Override the digital divide with additional clickthroughs.";

const mediaData = [
  {
    title: "Round and round like a carousel",
    link: "",
    image: video_card_cover,
  },
  {
    title: "Round and round like a carousel",
    link: "",
    image: video_card_cover,
  },
  {
    title: "Round and round like a carousel",
    link: "",
    image: video_card_cover,
  },
  {
    title: "Round and round like a carousel",
    link: "",
    image: video_card_cover,
  },
];

const Media = () => {
  const small = useMediaQuery({ query: "(min-width: 576px)" });
  const medium = useMediaQuery({ query: "(min-width: 768px)" });
  const large = useMediaQuery({ query: "(min-width: 992px)" });
  const xlarge = useMediaQuery({ query: "(min-width: 1200px)" });

  return (
    <>
      <PageTitleCover
        backgroundImage={blogs_cover}
        title="Media"
        description={description}
      />
      <div className="content-wrapper py-4">
        <CardGroup>
          <Row xs={1} md={2} className="g-4">
            {mediaData.map((blog, i) => (
              <Col xs={12} md={6} lg={4} key={i} className="mt-0">
                <Card className="p-1 border-0 rounded-0 mb-2">
                  <Card.Img
                    variant="top"
                    src={blog.image}
                    className="mb-3 rounded-0"
                    style={{
                      width: large ? "100%" : medium ? "268" : "250",
                      height: xlarge
                        ? 240
                        : large
                        ? 200
                        : medium
                        ? 200
                        : small
                        ? 200
                        : 160,
                    }}
                  />
                  <Card.Body className="p-0">
                    <Card.Title className="max-lines-1">
                      {blog.title}
                    </Card.Title>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </CardGroup>
      </div>
      <section className="py-4" />
    </>
  );
};

export default Media;
