import React from "react";
import { Image } from "react-bootstrap";
import DataCard from "components/Site/DataCard";
import DataList from "components/Site/DataList";
import { liquid_manufacturing_plant } from "assets/images/equipments";
import BulletPointContainer, { VARIANTS } from "components/Commons/BulletPoint";

const LiquidManufacturingPlantData = () => {

    const keyFeatures = [
        "Mirror Finished Internal Surface: The plant boasts a mirror-finished internal surface, ensuring a hygienic and smooth environment for the manufacturing process.",
        "Optional Electro-Polished Piping: For enhanced aesthetics and corrosion resistance, the plant offers the option of electro-polished piping throughout.",
        "Rigid Stairs and Walkable Platform: Designed for operational convenience, the plant features rigid stairs and a walkable platform, ensuring safe and accessible usage.",
        "100% Drain Design: All vessels are meticulously designed for 100% drainability, facilitated by flush bottom valves, minimizing residue and ensuring efficient cleaning.",
        "Process Control Instruments: Equipped with necessary temperature indicators, controllers, and timers, the plant ensures precise process control. Centralized operation panels streamline monitoring and management.",
        "Bottom Entry Propeller Type Agitators: To guarantee effective agitation throughout the mass, the plant incorporates bottom-entry propeller type agitators.",
        "Water Cooled Double Mechanical Seals: Ensures leak-proof operation through the utilization of water-cooled double mechanical seals, enhancing reliability.",
        "High Shear Inline Homogenizer: Featuring a high shear inline homogenizer with a dedicated mixing vessel, the plant facilitates homogeneous mixing of suspensions for consistent product quality.",
        "Safety Features: Each vessel is equipped with necessary pressure gauges, safety valves, and air vents, prioritizing safety in the manufacturing process.",
        "Thermodynamic Steam Jackets: Incorporates thermodynamic steam jackets, optimizing temperature control for precise and efficient manufacturing.",
        "WIP System Connectivity: The plant includes provisions for connecting a WIP (Wash In Place) system, streamlining the cleaning process and ensuring hygiene standards are met.",
    ];

    return (
        <>
            <div className="text-justify">
                <Image className="d-none d-md-block ms-2 float-right" src={liquid_manufacturing_plant} alt="Lab Blender Equipment" style={{ borderRadius: "10px", width: 350 }} />
                <div>
                    The Liquid Manufacturing Plant we offer is a comprehensive solution designed specifically for the pharmaceutical industry, enabling the efficient production of sugar-based oral syrups and suspensions. Crafting oral liquids is a complex process that demands precise control over various parameters throughout multiple stages.
                </div>
                <br />
                <div>
                    Our manufacturing plant comprises a well-coordinated set of components, including diverse vessels, agitation and homogenization equipment, filtration tools, interconnecting piping, essential process control valves, and a sturdy walkable platform for accessibility and safety. All these elements work in tandem to ensure a seamless and controlled manufacturing process.
                </div>
                <br />
                <div>
                    At the heart of our system is a centralized control panel that serves as the command center, overseeing and regulating every aspect of the manufacturing process. This integrated solution is not just a collection of equipment; it's a thoughtfully designed and meticulously engineered system that meets the exacting standards of the pharmaceutical industry.
                </div>
                <br />
                <div>
                    When you choose our Liquid Manufacturing Plant, you're investing in a reliable and efficient solution that streamlines the complexities of oral liquid production, providing a solid foundation for pharmaceutical manufacturing excellence.
                </div>
                <div className="text-center d-md-none">
                    <Image className="w-75" src={liquid_manufacturing_plant} alt="Lab Blender Equipment" style={{ borderRadius: "10px" }} />
                </div>
                {/* <DataCard
                    title="Key Features"
                    component={<DataList dataList={keyFeatures} />}
                /> */}
                <BulletPointContainer
                    heading={"Key Features"}
                    variant={VARIANTS.BULLETS}
                    firstRowFullWidth={true}
                    numberOfColumns={2}
                    bulletPointArray={keyFeatures.map(r => ({ description: r }))}
                />
            </div>
        </>
    );
};

export default LiquidManufacturingPlantData;
