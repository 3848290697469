import SiteButton from "components/Site/SiteButton";
import React, { useRef, useState } from "react";
import { Form } from "react-bootstrap";
import { toast, Slide } from "react-toastify";


import saveContact from "api/ContactUsForm";


export const ContactUsForm = () => {
  const formRef = useRef(null);
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const CRM_URL = process.env.REACT_APP_CRM_URL;
  const [formBeingSubmitted, setFormBeingSubmitted] = useState(false);

  const notify = (message, type) => {
    if (type === "success") {
      toast.success(message, {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "dark",
        transition: Slide,
      });
    } else if (type === "error") {
      toast.error(message, {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "dark",
        transition: Slide,
      });
    }
  };


  const submitHandler = async (e) => {
    console.log("audhfsduhf");
    e.preventDefault();
    setFormBeingSubmitted(true);
    const response = await saveContact(e, new FormData(formRef.current), CRM_URL);
    if (response) {
      notify("Thank you for contacting us! We'll get back to you soon.", "success");
      setFormBeingSubmitted(false);
      formRef.current.reset();
    } else {
      notify("Some Error Occurred. Please try again later.", "error");
      setFormBeingSubmitted(false);
    }
  };


  // const handleSubmit = async (e) => {
  //   e.preventDefault();

  //   const user = {
  //     name: name,
  //     email: email,
  //     mobile: mobile,
  //     message: message,
  //   };
  //   // console.log(user);

  //   // const response = await axios.post("", user);
  //   // if (response.status === 200) {
  //   //   toast.success("Message sent! We'll get back to you soon.");
  //   // } else {
  //   //   console.log(response);
  //   //   // toast.error("Message could not be sent! Please try again");
  //   //   toast.error("Message could not be sent! Please try again");
  //   // }
  // };

  return (
    <Form ref={formRef} onSubmit={submitHandler}>
      <Form.Group className="mb-3">
        <Form.Label className="mb-1 mb-sm-2">Your Name</Form.Label>
        <Form.Control
          type="text"
          className="py-2 rounded-0"
          id="name"
          placeholder=""
          name="name"
          required
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label className="mb-1 mb-sm-2">Mobile Number</Form.Label>
        <Form.Control
          type="phone"
          className="py-2 rounded-0"
          id="phone"
          name="phone"
          placeholder=""
          required
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label className="mb-1 mb-sm-2">Email</Form.Label>
        <Form.Control
          type="email"
          className="py-2 rounded-0"
          id="email"
          name="email"
          placeholder=""
          required
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label className="mb-1 mb-sm-2">Message</Form.Label>
        <Form.Control
          as="textarea"
          className="py-2 rounded-0"
          id="message"
          rows="5"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        ></Form.Control>
      </Form.Group>
      <div className="d-flex justify-content-center mt-5">
        <SiteButton title="Send" type="submit" style={{ minWidth: 150 }} />
      </div>
    </Form>
  );
};

export default ContactUsForm;
