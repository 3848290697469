import React from "react";
import { Image } from "react-bootstrap";
import DataCard from "components/Site/DataCard";
import DataList from "components/Site/DataList";
import { double_head_tube_filling_machine } from "assets/images/equipments";
import BulletPointContainer, { VARIANTS } from "components/Commons/BulletPoint";

const DoubleHeadTubeFillingMachineData = () => {

    const keyFeatures = [
        "Filling Capacity: Versatile filling capacity ranging from 5 gm to 200 gm of cream/ointment.",
        "Tube Sealing/Closing: Capable of sealing/closing Lami tubes up to 40 mm diameter and Aluminium tubes up to 28 mm diameter.",
        "Oval Race Track: Features an oval race track with Aluminium tube holder for precise handling.",
        "Fully Enclosed Body: The body is fully enclosed from all sides, providing added safety if required.",
        "Premium Cladding: Cladded with pre-buffed SS sheet for a durable and aesthetic exterior.",
        "Conveyor Protection: Overload clutch protection on the conveyor ensures safety and prevents overloading.",
        "Barcode Reading Provision: Optional provision for barcode reading, enhancing traceability if required.",
        "Stainless Steel Construction: All stationary parts above the tabletop are made of SS 304, ensuring hygiene and durability.",
        "Variable Frequency Drive: Equipped with a variable frequency drive for speed adjustment.",
        "Advanced Control System: Incorporates PLC and touch screen HMI for seamless operation and control.",
        "Hardened Cams and Bearing Support: All cams are hardened, and shafts are supported on bearings for robust performance.",
        "Material Quality: Contact parts are made of SS 316, and non-contact parts are made of SS 304, ensuring compatibility and longevity.",
    ];

    const optionalFeatures = [
        "Saddle Fold: Option to include a saddle fold feature, enhancing tube presentation and aesthetics.",
        "Multi-Color Filling: Capability for multi-color filling, providing flexibility in product presentation.",
        "Designer Seal in Plastic Tubes: Option for designer seals in plastic tubes, enhancing branding and visual appeal.",
        "Gas Flushing Before & After Filling: Incorporation of gas flushing before and after filling, contributing to product freshness and optimizing shelf life.",
    ]

    return (
        <div className="text-justify">
            <div>
                <Image className="d-none d-md-block float-right ms-2" src={double_head_tube_filling_machine} alt="Lab Blender Equipment" style={{ borderRadius: "10px", width: 320, height: "100%" }} />
                <div>
                    The Fully Automatic Tube Filling Machine is designed for the efficient filling and sealing of tubes, accommodating both LAMI and Plastic tube materials. Widely utilized in the Pharmaceutical, Cosmetics, Chemical, Agriculture, and Food industries, this machine is versatile and caters to a broad range of applications.
                </div>
                <br />
                <div>
                    Capable of filling various materials, including Cosmetic Creams, Pastes, Honey, Gel, Gum, Balm, Ointments, etc., into LAMI, Plastic, or Aluminium tubes, this machine streamlines the entire process in a single operation with indexing. The operation includes tube feeding, filling, sealing, and coding (manufacturing date, expiry, lot number, etc.).
                </div>
            </div>
            <div className="text-center">
                <Image className="w-75 d-md-none" src={double_head_tube_filling_machine} alt="Lab Blender Equipment" style={{ borderRadius: "10px" }} />
            </div>
            <br />
            <div>
                Our product line includes both fully automatic and semi-automatic tube filling machines, catering to a diverse range of viscosity levels. Materials handled by our machines encompass paint, adhesive, cosmetics, lotion, toothpaste, shampoo, and more. Our machine stands as a reliable and versatile solution for a wide spectrum of tube-filling applications.
            </div>

            <BulletPointContainer
                heading={<div className="text-start">{"Key Features"}</div>}
                bulletPointArray={keyFeatures.map(r => ({ description: r }))}
                variant={VARIANTS.BULLETS}
                numberOfColumns={2}
                justify="flex-start"
                lastRowFullWidth={true}
            />
            {/* <DataCard
                title="Key Features"
                component={<DataList dataList={keyFeatures} />}
            /> */}
            {/* <DataCard
                title="Optional Features"
                component={<DataList dataList={optionalFeatures} />}
            /> */}
            <BulletPointContainer
                heading={<div className="text-start">{"Optional Features"}</div>}
                bulletPointArray={optionalFeatures.map(r => ({ description: r }))}
                variant={VARIANTS.BULLETS}
                numberOfColumns={2}
                justify="flex-start"
                lastRowFullWidth={true}
            />

        </div>
    );
};

export default DoubleHeadTubeFillingMachineData;
