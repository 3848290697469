import React from "react";
import { Image } from "react-bootstrap";
import DataCard from "components/Site/DataCard";
import DataList from "components/Site/DataList";
import { lab_blender } from "assets/images/equipments";
import BulletPointContainer, { VARIANTS } from "components/Commons/BulletPoint";

const LabBlenderData = () => {

    const salientFeatures = [
        <div><b>Common Drive Unit (Interchangeable Design):</b> Facilitates easy switching between different blender shapes for enhanced versatility.</div>,
        <div><b>Accommodates Various Blender Shapes:</b> Bin, Octagonal, Double Cone, and V type blender shapes for diverse applications.</div>,
        <div><b>RPM Control via VFD:</b> Allows precise control of the blender's speed through Variable Frequency Drive (VFD).</div>,
        <div><b>Auto Stops at Home Position:</b> Ensures convenient and automated operation with auto-stop functionality at the home position.</div>,
        <div><b>Leak-Proof Discharge Valve:</b> Manually operated discharge butterfly valve with a leak-proof design for secure handling.</div>,
        <div><b>Safety Railing with Proxy Sensor:</b> Incorporates safety measures with a railing system and proxy sensor for added protection.</div>,
        <div><b>Control via Manual Push Button:</b> User-friendly manual push-button control for seamless operation.</div>,
    ];

    const optionalFeatures = [
        <div><b>Advanced Control System:</b> PLC and HMI-based control with printing options for enhanced automation and monitoring.</div>,
        <div><b>Interchangeable Blender Bins:</b> Offers flexibility with interchangeable bins of different shapes and sizes.</div>,
        <div><b>Mobile Trolley and Stand:</b> Provides mobility and a stable resting platform for blender bins, ensuring convenience in movement and operation.</div>,
    ];

    return (
        <>
            <div>
                <div className="text-justify">
                    Unleash the power of effective and uniform mixing with our Lab Blender,
                    designed for the meticulous blending of dry powder and granules to enhance
                    material flowability for tablet and capsule production. This versatile
                    blender features a Top Bottom & Single Drive unit with interchangeable
                    Blender bodies in shapes such as Double Cone, Bin, Octagonal, and V type.
                    It ensures a seamless and homogeneous blend, promoting optimal product performance.
                </div>
                <div className="d-flex justify-content-center my-3">
                    <Image className="w-50" src={lab_blender} alt="Lab Blender Equipment" style={{ borderRadius: "10px" }} />
                </div>
                <BulletPointContainer
                    heading={"Salient Features"}
                    variant={VARIANTS.BULLETS}
                    numberOfColumns={2}
                    firstRowFullWidth={true}
                    bulletPointArray={salientFeatures.map(r => ({ description: r }))}
                />
                {/* <DataCard
                    title="Salient Features"
                    component={<DataList dataList={salientFeatures} />}
                /> */}
                {/* <DataCard
                    title="Optional Features"
                    component={<DataList dataList={optionalFeatures} />}
                /> */}
                <BulletPointContainer
                    heading={"Optional Features"}
                    variant={VARIANTS.BULLETS}
                    numberOfColumns={2}
                    lastRowFullWidth={true}
                    bulletPointArray={optionalFeatures.map(r => ({ description: r }))}
                />
                <div className="text-center">
                    Elevate your blending processes with the Lab Blender, where precision and adaptability converge for optimal results!
                </div>
            </div>
        </>
    );
};

export default LabBlenderData;
