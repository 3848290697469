import React from "react";
import { Image } from "react-bootstrap";
import DataCard from "components/Site/DataCard";
import DataList from "components/Site/DataList";
import { gear_pump_liquid_filling_machine } from "assets/images/equipments";
import BulletPointContainer, { VARIANTS } from "components/Commons/BulletPoint";

const GearPumpLiquidFillingMachineData = () => {

    const keyFeatures = [
        "Rigid construction with a stainless-steel structure and high-grade anodized aluminum.",
        "Lubricant oil pump for precise and smooth filling.",
        "Servo motor controls each filling nozzle individually, ensuring precision in the filling process.",
        "Selected pneumatic shut-off valve to prevent liquid from dripping down, maintaining cleanliness.",
        "Pneumatic bottle positioning system and diving nozzle for accurate and controlled filling.",
        "Pneumatic drip tray with a surplus oil backflow system, minimizing wastage and ensuring cleanliness.",
    ];

    const optionalFeatures = [
        "Automatic chain washing system for the conveyor belt, ensuring thorough cleanliness.",
        "In - built pre - inspection of empty bottles, enhancing quality control.",
        "Acrylic cabinet for a polished and protective appearance.",
        "Acrylic cover on the conveyor, combining safety with visibility.",
        "Float tank(Holding tank) with or without a stirrer, providing flexibility in liquid handling.",
    ]

    return (
        <div className="text-justify">
            <div>
                Automatic Gear Pump Liquid Filling Machine, a high-speed, multi-head liquid filler designed for efficiently meeting lubricant oil filling requirements. With a filling capacity ranging from 1 to 5 litres, this machine combines power with precision. The servo motor control ensures accuracy, allowing for individual adjustments for each nozzle. Experience seamless and efficient liquid filling tailored for your lubricant oil needs.
            </div>
            <div className="text-center my-3">
                <Image src={gear_pump_liquid_filling_machine} alt="Lab Blender Equipment" style={{ borderRadius: "10px", width: 400 }} />
            </div>
            {/* <DataCard
                title="Key Features"
                component={<DataList dataList={keyFeatures} />}
            /> */}
            <BulletPointContainer
                heading={<div className="text-start">{"Key Features"}</div>}
                bulletPointArray={keyFeatures.map(r => ({ description: r }))}
                variant={VARIANTS.BULLETS}
                justify="flex-start"
                lastRowFullWidth={true}
            />
            <div>
                These salient features showcase the robustness, precision, and efficiency of the Automatic Gear Pump Liquid Filling Machine, making it an ideal choice for lubricant oil filling requirements.
            </div>
            < br />

            <br />
        </div>
    );
};

export default GearPumpLiquidFillingMachineData;
