import BulletPointContainer from "components/Commons/BulletPoint";
import React from "react";

const WaterSystemData = () => {
    return (
        <div className="text-justify">
            {/* <Image src="" alt="" /> */}
            <div>
                In the pharmaceutical industry, water plays a crucial role, primarily in
                liquid form. It serves not only as an ingredient in numerous formulations
                but also as a vital cleaning agent. Recognizing the critical importance of
                producing water to international pharmaceutical standards, including purified
                water, highly purified water, pyrogen-free water, and water for injection,
                we have tailored efficient water purifying systems to meet the specific needs
                of our clients. Our commitment is rooted in understanding the industry's
                demands and providing reliable solutions for water quality in pharmaceutical processes.
            </div>
            <br />
            <div>
                Our extensive experience is dedicated to delivering pharmaceutical water
                systems that not only meet but exceed industry standards. We ensure compliance with:
                <BulletPointContainer
                    numberOfColumns={3}
                    bulletPointArray={[
                        {
                            title: "Latest USP and European Standards",
                            description: "Adhering to the most current standards set by the United States Pharmacopeia and European regulations."
                        },
                        {
                            title: "Validation Control Systems Requirements",
                            description: "Implementing robust validation control systems to guarantee the reliability and accuracy of our pharmaceutical water systems."
                        },
                        {
                            title: "FDA Requirements",
                            description: "Meeting the stringent requirements outlined by the U.S. Food and Drug Administration (FDA) to ensure the safety and efficacy of pharmaceutical water."
                        },
                        {
                            title: "ISPE Engineering Guide",
                            description: "Aligning our processes with the International Society for Pharmaceutical Engineering (ISPE) Engineering Guide for best practices and industry standards."
                        },
                        {
                            title: "CE Marking",
                            description: "Ensuring that our pharmaceutical water systems comply with CE marking requirements, indicating conformity with European health, safety, and environmental protection standards."
                        },
                        {
                            title: "IPPC Environmental Requirements",
                            description: "Addressing environmental considerations by meeting the International Plant Protection Convention (IPPC) requirements."
                        },
                        
                    ]}
                />
            </div>
            <div>
                This commitment to compliance across various international standards and
                regulations underscores our dedication to delivering pharmaceutical water
                systems of the highest quality and reliability.
            </div>
            <br />
            <div>
                Our commitment to excellence extends across various applications, including
                pre-treatment, purified water generation, water for injection production,
                and wastewater treatment. We employ the latest pharmaceutical water systems
                to enhance manufacturing efficiency and reduce costs, all while prioritizing
                process security and product quality. Leveraging our pharmaceutical expertise,
                we deliver the best water purifying systems tailored to meet our clients'
                specific requirements.
            </div>
            <br />
            <div>
                Every aspect of our product development, project management, and service
                offerings is held to a high-quality standard. This ensures that our dedicated
                team of experts remains attuned to market needs, consistently delivering solutions
                that not only meet but exceed the evolving demands of the pharmaceutical industry.
            </div>
            <br />
            <div>
                the escalating threats to our rivers, lakes, and vital drinking water reserves
                necessitate proactive measures. Adequate protection is contingent on the extensive
                decontamination of wastewater. Modern technological advancements are continually
                expanding the possibilities within the realm of environmental protection. Our ecological
                emphasis is on safeguarding water and optimizing the multiple uses of wastewater.
            </div>
            <br />
            <div>
                At Proactimo, we leverage cutting-edge technologies to ensure the safe and
                environmentally compliant operation of sewage treatment plants, regardless of their
                size. Our meticulously designed and engineered plants guarantee that only purified
                water is discharged into rivers. Moreover, by employing a combination of innovative
                treatment processes, we facilitate the reuse of wastewater for both industrial and
                municipal purposes, contributing to the conservation of valuable resources.
            </div>
            <br />
            <div>
                Our solutions are designed to offer customized, efficient, and cost-effective
                approaches that encompass the entire wastewater treatment cycle:
                <BulletPointContainer
                    numberOfColumns={3}
                    bulletPointArray={[
                        {
                            title: "Primary Treatment",
                            description: "Addressing the initial stages of wastewater treatment, effectively removing solids and reducing pollutant levels."
                        },
                        {
                            title: "Secondary Treatment",
                            description: "Employing advanced processes to further treat the wastewater, ensuring the removal of dissolved and suspended contaminants."
                        },
                        {
                            title: "Sludge Treatment",
                            description: "Managing and treating sludge generated during the wastewater treatment process, focusing on minimizing environmental impact."
                        },
                        {
                            title: "Odor Control",
                            description: "Implementing strategies to control and mitigate odors associated with wastewater treatment, ensuring a more pleasant environment."
                        },
                        {
                            title: "Mobile & Packaged Wastewater Treatment",
                            description: "Offering flexible and mobile solutions for wastewater treatment, tailored to specific needs and adaptable to various settings."
                        }
                    ]}
                />
            </div>
            <div>
                These comprehensive solutions cover every aspect of the wastewater treatment cycle,
                providing a holistic approach to water management that is both effective and resource-efficient.
            </div>
            <br />
            <div>
                We take pride in delivering performance that not only meets the stringent limits
                in the wastewater sector but often anticipates future developments. Our commitment
                is evident in the design and construction of plants that adhere to the highest
                standards. Through the application of scientific know-how and ongoing process
                development, we actively contribute to the prevention and removal of environmental
                burdens. Our dedication to continuous improvement ensures that our solutions not only
                meet current environmental standards but are also positioned to address emerging
                challenges in the wastewater treatment landscape.
            </div>
        </div>
    );
};

export default WaterSystemData;
