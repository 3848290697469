import FooterMain from "components/Footer/Footer";
import NavbarMain from "components/Navbar/Navbar";
import { siteNavigation } from "navigation/SiteNavigation";
import PageNotFound from "pages/Errors/PageNotFound";
import OurClients from "pages/Home/OurClients";
import CustomPageView from "pages/Site/CustomPageView";
import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Outlet,
  Navigate,
} from "react-router-dom";

const Routers = () => {
  function renderRoutes(routes) {
    // console.log(routes);
    return (
      routes &&
      routes?.map((cr) => {
        return (
          <Route key={cr.identifier} path={cr.path} element={<Outlet />}>
            <Route
              path=""
              exact
              element={
                cr?.component ? (
                  cr.component
                ) : cr?.dataComponent ? (
                  <CustomPageView dataComponent={cr.dataComponent} />
                ) : cr?.subroutes ? (
                  <Navigate to={cr?.subroutes[0].path} />
                ) : (
                  <PageNotFound />
                )
              }
            />
            {cr?.subroutes && renderRoutes(cr.subroutes)}
          </Route>
        );
      })
    );
  }

  return (
    <Router>
      <NavbarMain />
      <Routes>
        {renderRoutes(siteNavigation)}
        <Route path="clients" element={<OurClients />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
      <FooterMain />
    </Router>
  );
};

export default Routers;
