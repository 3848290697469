import BulletPointContainer, { VARIANTS } from "components/Commons/BulletPoint";
import React from "react";

const ProjectManagementData = () => {
    return (
        <div className="text-justify">
            {/* <Image src="" alt="" /> */}
            <div>
                Proactimo stands as a comprehensive solution provider, capable of serving as
                the single source for Engineering, Procurement, and Construction (EPC). This
                integrated approach allows us to offer consolidated solutions to our clients,
                streamlining the project lifecycle.
            </div>
            <br />
            <div>
                Our extensive network of vendors and experts, both locally and globally,
                plays a pivotal role in our ability to undertake and successfully deliver
                complex projects within strict timelines. Leveraging years of practical experience,
                our professional team is well-equipped to recommend high-quality yet cost-effective
                equipment, machinery, and systems to our clients. This ensures that our solutions
                not only meet the highest standards but also align with the economic considerations
                of our clients, delivering value and efficiency throughout the project.
            </div>
            <br />
            <div>
                Opting for project execution with Proactimo brings several powerful advantages:
                <BulletPointContainer
                    variant={VARIANTS.BULLETS}
                    numberOfColumns={1}
                    bulletPointArray={[
                        {
                            title: "On-time Project Delivery",
                            description: <ul className="mb-0 ps-4">
                                <li>Significant time savings as there is no need to go through the process of floating tenders, identifying contractors, and evaluating and awarding contracts.</li>
                                <li>Elimination of the need to deal with multiple agencies, resulting in quicker project completion.</li>
                            </ul>,
                        },
                        {
                            title: "Single Point Coordination",
                            description: <ul className="mb-0 ps-4">
                                <li>Proactimo takes on the responsibility for timely delivery, cost, and quality, offering a single point of contact for streamlined communication.</li>
                                <li>Clients can experience peace of mind, focusing on other critical matters while we manage the coordination and execution of the project.</li>
                            </ul>,
                        },
                        {
                            title: "Responsibility for Performance and Design",
                            description: <ul className="mb-0 ps-4">
                                <li>Every contractor and vendor directly reports to Proactimo, placing the responsibility for performance and design on our shoulders.</li>
                                <li>We ensure effective collaboration among equipment suppliers, contractors, and their collective performance as a team.</li>
                            </ul>,
                        },
                        {
                            title: "Reduced Administrative Responsibilities",
                            description: <ul className="mb-0 ps-4">
                                <li>Drastically reduced administrative tasks and the need for a large administrative staff.</li>
                                <li>Clients can allocate more time to focus on essential areas such as marketing, strategic hiring, and planning.</li>
                            </ul>,
                        },
                    ]}
                />
            </div>
            <div>
                In essence, choosing Proactimo for project execution streamlines the entire process,
                ensuring efficient coordination, timely delivery, and quality outcomes while relieving
                clients of the burdensome administrative aspects.
            </div>
        </div>
    );
};

export default ProjectManagementData;
