import React from "react";
import { Image } from "react-bootstrap";
import DataCard from "components/Site/DataCard";
import DataList from "components/Site/DataList";
import { automatic_round_bottle_labeling_machine } from "assets/images/equipments";
import BulletPointContainer, { VARIANTS } from "components/Commons/BulletPoint";

const AutomaticSingleSideStickerLabellingMachineData = () => {

    const keyFeatures = [
        <div><b>Precision Instrument Design:</b> The machine is designed as a precision instrument for accurate and efficient single - side labeling.</div>,
        <div><b>Compatibility with Flat and Square Bottles:</b> Specifically engineered for flat and square bottles, typically made of materials like PET and plastic.</div>,
        <div><b>Microprocessor Control:</b> Equipped with a microprocessor control system, ensuring precision and user - friendly operation.</div>,
        <div><b>Sturdy Main Structure:</b> The main structure of the machine is built with stainless steel(SS 304), providing durability and stability.</div>,
        <div><b>Product Handling Components:</b> Utilizes a product in -feed screw and a product transmission belt with adjustable guides for efficient product handling.</div>,
        <div><b>Top Product Holding Conveyor:</b> Features a top product holding conveyor with height adjustment, ensuring proper alignment during the labeling process.</div>,
        <div><b>Dual Label Dispensing Units:</b> Incorporates two label dispensing units for simultaneous labeling, enhancing the machine's speed and efficiency.</div>,
        <div><b>Label Pasting Unit:</b> Includes a label pasting unit to ensure precise and secure adhesion of labels onto the designated areas of the bottles.</div>,
        <div><b>Motorized Components:</b> Utilizes motors for various functions, contributing to the overall automation and reliability of the machine.</div>,
        <div><b>Sensors and AC Drives:</b> Equipped with sensors for accurate detection and AC drives for variable speed control, enhancing operational flexibility.</div>,
        <div><b>Electrical Panel:</b> Features an electrical panel housing essential components for controlling and monitoring the machine's functions.</div>,
        <div><b>Emergency Switch:</b> Incorporates an emergency switch for immediate halting of operations in case of unforeseen situations.</div>,
        <div><b>PLC / HMI Control System:</b> Utilizes a PLC(Programmable Logic Controller) with an HMI(Human - Machine Interface) for streamlined and user - friendly control.</div>,
    ];

    return (
        <>
            {/* <DataCard
                title="Key Features"
                component={<DataList dataList={keyFeatures} />}
            /> */}
            <BulletPointContainer
                heading={<div className="text-start">{"Key Features"}</div>}
                bulletPointArray={keyFeatures.map(r => ({ description: r }))}
                variant={VARIANTS.BULLETS}
                numberOfColumns={2}
                justify="flex-start"
                lastRowFullWidth={true}
            />
            <div>
                In summary, this Automatic Single Side (Sticker) Labelling machine combines
                advanced technology, robust construction, and intelligent control systems to
                meet the labeling needs of flat and square bottles with precision and efficiency.
            </div>
        </>
    );
};

export default AutomaticSingleSideStickerLabellingMachineData;
