import PrimaryLine from "components/Site/PrimaryLine";
import SiteButton from "components/Site/SiteButton";
import { clientsData } from "pages/Home/OurClients";
import React from "react";
import { useNavigate } from "react-router";
import Slider from "react-slick";
import { GrFormPrevious, GrFormNext } from "react-icons/gr";

const ClientsSection = () => {
  const navigate = useNavigate();

  return (
    <section className="text-center py-3 position-relative">
      <p className="text-center text-uppercase mb-2">Our Clients</p>
      <h2 className="text-center mb-3">Meet Our Clients</h2>
      <PrimaryLine className="mx-auto mb-5" />
      <Slider {...settings} className="d-flex mb-5">
        {clientsData.map((item, i) => (
          <div
            key={i}
            className="d-flex justify-content-center align-items-center p-2"
          >
            <img
              src={item.image}
              alt={item.title}
              className="img-fluid"
              style={{ objectFit: "contain", maxWidth: "60%", height: 150 }}
            />
          </div>
        ))}
      </Slider>
      <SiteButton
        title="View All"
        className="mt-4"
        onClick={() => navigate("clients")}
      />
    </section>
  );
};

const settings = {
  autoplay: true,
  infinite: true,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 4,
  initialSlide: 0,
  // dots: true,
  arrows: false,
  // prevArrow: <GrFormPrevious size={20} className="text-danger" />,
  // nextArrow: <GrFormNext size={20} className="text-danger" />,
  responsive: [
    {
      breakpoint: 2000,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        initialSlide: 3,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 576,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        infinite: true,
        dots: true,
      },
    },
  ],
};

export default ClientsSection;
