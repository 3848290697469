import BulletPointContainer, { VARIANTS } from "components/Commons/BulletPoint";
import React from "react";

const DocumentationData = () => {
    return (
        <div className="text-justify">
            {/* <Image src="" alt="" /> */}
            <div>
                Providing peace of mind to our clients, an integral component of every project
                delivered by Proactimo is a one-year warranty. This commitment to quality is often
                extended by clients, reflecting the trust they place in our services.
            </div>
            <br />
            <div>
                Our after-sales service goes beyond standard practices. Proactimo takes responsibility
                for attending to any malfunctions in various facets of the facility, including clean
                rooms, HVAC systems, utilities, control systems, and equipment. In instances of extended
                warranty, our services may encompass comprehensive ongoing maintenance for all systems
                within the facility. This involves periodic check-ups, calibrations, preventive maintenance
                measures, and the supply and installation of spare parts, ensuring the sustained and optimal
                functioning of the facility over time. Our dedication to customer satisfaction extends beyond
                project completion, emphasizing the long-term reliability and efficiency of the systems
                we implement.
            </div>
            <br />
            <div>
                Quality is not just a goal but an inherent aspect that needs to be built into every
                facility. Validation and qualification serve as integral steps in achieving this, and
                at Proactimo, we bring a wealth of experience to ensure the creation of a highly robust
                and efficient documentation system. This system is meticulously crafted to meet the
                stringent requirements of both national and international regulatory bodies.
            </div>
            <br />
            <div>
                Our expert team is well-equipped to guide you through the entire process, from the
                development to the subsequent implementation of a comprehensive validation program.
                We prioritize a cost-effective approach, ensuring that the validation process aligns
                with industry standards while being efficient and economically viable for our clients.
                With Proactimo, you can trust in the expertise and commitment to delivering validated
                and qualified facilities that meet the highest regulatory standards.
            </div>
            <br />
            <h4>Documentation:</h4>
            <div>
                The documentation process at Proactimo involves several key steps
                to ensure a thorough and efficient approach:

                <BulletPointContainer
                    variant={VARIANTS.BULLETS}
                    numberOfColumns={1}
                    bulletPointArray={[
                        {
                            "title": "Project Planning & Management",
                            "description": <ul className="ps-4 mb-0">
                                <li>Establishing a robust project plan to guide the documentation process.</li>
                                <li>Efficient project management to ensure timelines are met and objectives are achieved.</li>
                            </ul>
                        },
                        {
                            "title": "Development of Qualification Protocols, Computer System Validation & Equipment",
                            "description": <ul className="ps-4 mb-0">
                                <li>Crafting comprehensive qualification protocols for various aspects, including computer systems and equipment.</li>
                                <li>Ensuring that all validation processes align with industry standards and regulatory requirements.</li>
                            </ul>
                        },
                        {
                            "title": "Execution of Qualification Process and Final Documentation at Client Site",
                            "description": <ul className="ps-4 mb-0">
                                <li>Implementing the qualification process on-site, meticulously following the developed protocols.</li>
                                <li>Generating final documentation that reflects the successful completion of the qualification process.</li>
                            </ul>
                        },
                        {
                            "title": "Inspection at Vendor Site",
                            "description": <ul className="ps-4 mb-0">
                                <li>Conducting inspections at vendor sites to ensure compliance with established standards and specifications.</li>
                            </ul>
                        },
                        {
                            "title": "Standard Operating Procedures (SOPs)",
                            "description": <ul className="ps-4 mb-0">
                                <li>Developing and implementing Standard Operating Procedures that serve as guidelines for consistent and standardized operations.</li>
                            </ul>
                        },
                        {
                            "title": "Quality Management System (QMS)",
                            "description": <ul className="ps-4 mb-0">
                                <li>Establishing a robust Quality Management System to ensure that all processes and documentation adhere to the highest quality standards.</li>
                            </ul>
                        }
                    ]
                    }
                />

                These steps collectively contribute to a well-structured and comprehensive documentation
                process, reflecting our commitment to quality, compliance, and efficiency in all project aspects.
            </div>
            <br />
            <h4>Training & Skill Development:</h4>
            <div>
                Training and skill development are integral aspects of our commitment to
                excellence. At Proactimo, we offer a diverse range of training programs to
                enhance the capabilities of teams involved in various aspects of the
                pharmaceutical industry:
                <BulletPointContainer
                    numberOfColumns={2}
                    firstRowFullWidth={true}
                    justify="flex-start"
                    bulletPointArray={[
                        {
                            title: "Standard Operating Procedures (SOPs) for Maintenance/Manufacturing/QA/QC",
                            description: "Providing detailed training on the development and implementation of SOPs across maintenance, manufacturing, and quality assurance/control."
                        },
                        {
                            title: "How to Prepare & Face Regulatory Audits",
                            description: "Equipping teams with the knowledge and skills needed to prepare for and navigate regulatory audits effectively."
                        },
                        {
                            title: "Statistical Process Control",
                            description: "Training on the principles and implementation of Statistical Process Control (SPC) to enhance quality control and process improvement."
                        },
                        {
                            title: "WHO Guidelines",
                            description: "Familiarizing teams with the World Health Organization (WHO) guidelines, ensuring compliance with global standards."
                        },
                        {
                            title: "On-the-Job Training for Shop Floor and QA/QC Employees",
                            description: "Providing practical, on-the-job training for shop floor and quality assurance/control employees to enhance their skills and efficiency."
                        },
                        {
                            title: "Process Validation",
                            description: "In-depth training on the principles and processes involved in validating pharmaceutical processes to meet regulatory requirements."
                        },
                        {
                            title: "Purified Water Validation",
                            description: "Specialized training on the validation processes for purified water systems, ensuring compliance with industry standards."
                        },
                    ]}
                />
                These training programs are designed to empower teams with the knowledge and
                skills needed to operate in accordance with the highest industry standards,
                fostering a culture of continuous improvement and excellence.
            </div>
        </div>
    );
};

export default DocumentationData;
