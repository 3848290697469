import PrimaryLine from "components/Site/PrimaryLine";
import React from "react";
import { Col, Row } from "react-bootstrap";
import {
  service1,
  service2,
  service3,
  service4,
} from "assets/images/home_service_cards";
import { useMediaQuery } from "react-responsive";

const serviceCardData = [
  { title: "Engineering Consultancy &   Project Management", image: service1 },
  { title: "Technical Services", image: service2 },
  { title: "Equipment Sourcing", image: service3 },
  { title: "Consumables Sourcing", image: service4 },
];

const OurServicesSection = () => {
  const small = useMediaQuery({ query: "(min-width: 576px)" });
  const medium = useMediaQuery({ query: "(min-width: 768px)" });
  const large = useMediaQuery({ query: "(min-width: 992px)" });

  return (
    <section className="py-3">
      <p className="text-center text-uppercase mb-2">Our Services</p>
      <h2 className="text-center mb-3">We Offer Best Services</h2>
      <PrimaryLine className="mx-auto mb-5" />
      <Row xs={1} lg={2}>
        {serviceCardData?.map((service, i) => {
          return (
            <Col lg={6} key={i} className="mb-4">
              <div
                className="d-flex align-items-end"
                style={{
                  background: `url(${service.image})`,
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  height: medium ? 334 : small ? 282 : 252,
                }}
              >
                <div
                  className="d-flex"
                  style={{ background: "rgba(255, 255, 255, 0.8)" }}
                >
                  <div
                    className="bg-primary py-2 py-sm-3"
                    style={{ width: 8 }}
                  />
                  <div className="px-3 py-2 py-sm-3">
                    <p>{service.title}</p>
                  </div>
                </div>
              </div>
            </Col>
          );
        })}
      </Row>
    </section>
  );
};

export default OurServicesSection;
