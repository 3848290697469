import BulletPointContainer, { VARIANTS } from "components/Commons/BulletPoint";
import React from "react";

const HVACSystemsData = () => {
    return (
        <div className="text-justify">
            {/* <Image src="" alt="" /> */}
            <div>
                HVAC, which stands for Heating, Ventilation, and Air Conditioning,
                encompasses a range of systems designed to facilitate the movement
                of air between outdoor and indoor spaces. This includes the implementation
                of heating and cooling systems crucial for maintaining comfortable
                environments in both commercial establishments and residential homes.
            </div>
            <br />
            <div>
                Here's a breakdown of our comprehensive services:
                <BulletPointContainer
                    variant={VARIANTS.BULLETS}
                    numberOfColumns={1}
                    bulletPointArray={[
                        {
                            title: "Identification of the Correct Air Conditioning System",
                            description: "Assessing manufacturing functions and facility requirements to recommend the optimal air conditioning system."
                        },
                        {
                            title: "Design and Detailed Specifications for HVAC",
                            description: "Crafting detailed plans and specifications for all HVAC-related components.",
                        },
                        {
                            title: "Clean Room",
                            description: "Implementing solutions for maintaining clean and controlled environments.",
                        },
                        {
                            title: "Contamination Control",
                            description: "Implementing measures to control and minimize contamination.",
                        },
                        {
                            title: "Detailed Specifications, Tender Details, BOQs",
                            description: "Providing detailed specifications, tender information, and Bills of Quantities for accurate project planning.",
                        },
                        {
                            title: "Invitation of Bids and Vendor Recommendation",
                            description: "Managing the bidding process and recommending qualified vendors.",
                        },
                        {
                            title: "Checking and Approval of Vendor Documents",
                            description: "Reviewing and approving working documents and drawings from vendors.",
                        },
                        {
                            title: "Checking and Approving the Validation Process",
                            description: "Ensuring the validation process meets quality and safety standards.",
                        },
                        {
                            title: "Air Handling Units",
                            description: "Designing and implementing systems for air circulation and temperature control."
                        },
                        {
                            title: "Dehumidification",
                            description: "Implementing solutions to control humidity levels."
                        },
                        {
                            title: "Insulated Ducting",
                            description: "Designing and installing insulated duct systems."
                        },
                        {
                            title: "Grills and Diffusers",
                            description: "Incorporating components for efficient air distribution."
                        },
                        {
                            title: "Terminal Filter Housings",
                            description: "Providing housing solutions for terminal filters."
                        },
                        {
                            title: "Flow Control Dampers",
                            description: "Installing dampers for precise control of air flow."
                        },
                        {
                            title: "Fire Dampers",
                            description: "Implementing safety measures with fire dampers."
                        },
                        {
                            title: "Exhaust Fans & Blowers",
                            description: "Designing and installing systems for effective air extraction."
                        },
                    ]}
                />
            </div>
            <div>
                Our services cover the entire spectrum of HVAC needs, ensuring a
                comprehensive and tailored approach to meet specific project requirements.
            </div>
        </div>
    );
};

export default HVACSystemsData;
