// import logo from "./logo.svg";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./App.scss";
import Routers from "./Routers";
import { ParallaxProvider } from 'react-scroll-parallax';

import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

function App() {
  return (
    <ParallaxProvider>
      <ToastContainer />
      <Routers />
    </ParallaxProvider>
  );
}

export default App;
