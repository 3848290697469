import BulletPointContainer from "components/Commons/BulletPoint";
import React from "react";


const CleanRoomData = () => {
    return (
        <div className="text-justify">
            {/* <Image src="" alt="" /> */}
            <div>
                Proactimo modular partitions have gained global acclaim for their utilization
                in cleanrooms across diverse applications, ranging from API manufacturing to
                Sterile manufacturing units. The system is highly adaptable, aligning seamlessly
                with the cleanroom classifications outlined in the latest Good Manufacturing
                Practice (GMP) guidelines specific to pharmaceutical applications.
            </div>
            <br />
            <div>
                Our extensive range includes partition walls and ceiling systems, both walkable
                and non-walkable types, designed to meet the exacting technical standards of
                controlled environment rooms. These panels boast resistance to abrasion, chemicals,
                and reagents used in fumigation, as well as durability against steam, water,
                artificial, and UV light. Furthermore, the panels are characterized by their
                non-combustible and non-shedding nature, making them both water and fire retardant
                for enhanced safety and performance.
            </div>
            <br />
            <div>
                <BulletPointContainer
                    heading={"The Proactimo modular partitions stand out for their exceptional qualities"}
                    numberOfColumns={3}
                    bulletPointArray={[
                        {
                            title: "Good Impact Resistance",
                            description: "The panels exhibit robust impact resistance, ensuring durability in demanding environments."
                        },
                        {
                            title: "Particle-Free Emission",
                            description: "The panel surface is designed to emit no particles, maintaining the cleanliness of the controlled environment."
                        },
                        {
                            title: "Balanced Hardness and Flexibility",
                            description: "Achieving an optimal balance between hardness and flexibility, the panels provide structural integrity without compromising adaptability."
                        },
                        {
                            title: "Metal Frames in Periphery",
                            description: "Cleanroom panels are manufactured with metal frames around the periphery, enhancing structural stability and longevity."
                        },
                        {
                            title: "Installation with Interconnecting Profiles",
                            description: "The installation process involves interconnecting profiles, ensuring a seamless and secure fit for the cleanroom panels."
                        },
                    ]}
                />
            </div>
            <div>
                These features collectively contribute to the reliability, cleanliness, and
                structural integrity of Proactimo modular partitions, making them a preferred
                choice in various applications, including API and Sterile manufacturing units.
            </div>
            <br />
            <h4>Partition:</h4>
            <div>
                Proactimo modular partitions are constructed using a diverse range of
                materials, providing flexibility and suitability for various applications:
                <BulletPointContainer
                    heading={`Materials of Construction`}
                    numberOfColumns={4}
                    bulletPointArray={[
                        {
                            title: "Powder Coated GI",
                            description: "Galvanized Iron coated with a protective powder finish for enhanced durability and corrosion resistance."
                        },
                        {
                            title: "Pre Painted GI",
                            description: "Galvanized Iron with a pre-painted surface, combining aesthetics with corrosion protection."
                        },
                        {
                            title: "Stainless Steel",
                            description: "Utilizing corrosion-resistant stainless steel for a sleek and durable finish."
                        },
                        {
                            title: "High-Pressure Laminates",
                            description: "Employing laminates with high-pressure applications, offering both aesthetics and resilience."
                        },
                    ]}
                />
                <BulletPointContainer
                    heading={`Insulation Options`}
                    numberOfColumns={3}
                    bulletPointArray={[
                        {
                            title: "Polyurethane Foam (PUF)",
                            description: "Providing efficient thermal insulation with the lightweight and durable properties of polyurethane foam."
                        },
                        {
                            title: "Rockwool",
                            description: "Using mineral wool insulation for fire resistance and effective temperature control."
                        },
                        {
                            title: "Paper Core Honeycomb",
                            description: "Incorporating honeycomb structures with paper cores for a lightweight yet sturdy insulation option."
                        },
                    ]}
                />
                This diverse combination of construction materials and insulation options
                ensures that Proactimo modular partitions are adaptable, durable,
                and well-suited for a wide range of cleanroom applications.
            </div>
            <br />
            <h4>Flooring:</h4>
            <div>
                Proactimo specializes in developing cutting-edge solutions for the Pharmaceutical
                and related industries, focusing on bonding, sealing, damping, and reinforcing
                applications in manufacturing, packing, and storage facilities.
            </div>
            <br />
            <div>
                Epoxy flooring is a critical component in pharmaceutical settings,
                influencing cleanroom classification and overall cleanliness. Proactimo
                offers a range of epoxy flooring options tailored for specific applications,
                including stores, manufacturing areas, corridors, packing halls, and quality
                control spaces. Our skilled technicians, coupled with top-tier materials,
                are the pillars of our strength. We prioritize eco-friendly materials for epoxy
                applications, offering a wide spectrum from standard coatings to self-levelers
                and highly chemical-resistant, antistatic floors, catering to diverse production needs.
            </div>
            <br />
            <div>
                Proactimo boasts a robust physical and human infrastructure to efficiently
                meet the diverse needs of clients. Teaming up with industry-leading manufacturers,
                we deliver the best products in the market. Our foolproof logistics system
                can handle jobs of any priority. With an excellent team of engineers, supervisors,
                quality controllers, and workers, we ensure the timely delivery of top-quality f
                looring to our clients.
            </div>
            <br />
            <div>
                In addition to epoxy flooring, we also utilize a wide range of Urethane floor
                solutions, including coatings, self-levelers, and screeds with high chemical
                resistance and thermal stability. These solutions are highly sustainable, particularly
                in demanding industrial environments and wet process areas.
            </div>
        </div>
    );
};

export default CleanRoomData;
