import React from "react";
import { Button, Spinner } from "react-bootstrap";

const SiteButton = ({ title, type, onClick, inverted, className, style, loading = false }) => {
  return (
    <Button
      className={`${inverted ? "bg-transparent" : "bg-primary"
        } border-primary btn-radius px-4 ${className}`}
      style={{ height: 52, borderWidth: inverted ? 2 : 1, ...style }}
      type={type}
      onClick={onClick}
      disabled={loading}
    >
      <p className={inverted ? "text-primary" : "text-white"}>{loading ? (<div>Submitting...<Spinner size="sm"/></div>) : title}</p>
    </Button>
  );
};

export default SiteButton;
