import React from "react";
import { Image } from "react-bootstrap";
import DataCard from "components/Site/DataCard";
import DataList from "components/Site/DataList";
import { single_head_tube_filling_machine } from "assets/images/equipments";
import BulletPointContainer, { VARIANTS } from "components/Commons/BulletPoint";

const SingleHeadTubeFillingMachineData = () => {

    const keyFeatures = [
        "Versatile Filling Capacity: Capable of filling from 5 gm to 200 gm of cream / ointment.",
        "Tube Sealing / Closing: Accommodates Lami tubes up to 40 mm diameter and Aluminium tubes up to 28 mm diameter.",
        "Oval Race Track with Aluminium Tube Holder for precise handling during the filling process.",
        "Fully Enclosed Body: The machine features a fully enclosed body for enhanced safety and cleanliness, if required.",
        "Premium Construction: Cladded with pre - buffed SS sheet for a durable and aesthetic exterior.",
        "Overload Clutch Protection: Ensures conveyor safety and prevents overloading.",
        "Barcode Reading Provision: Optional feature for efficient tracking and identification, if required.",
        "Stainless Steel Construction: All stationary parts above the tabletop are made of SS 304, ensuring hygiene and durability.",
        "Variable Frequency Drive: Equipped with a variable frequency drive for adjustable machine speed.",
        "Advanced Control System: The machine incorporates PLC and touch screen HMI for seamless operation and control.",
        "Hardened Cams and Bearing Support: All cams are hardened, and shafts are supported on bearings for robust performance.",
        "Material Quality: Contact parts are made of SS 316, and non - contact parts are made of SS 304, ensuring compatibility and longevity.",
    ];

    const optionalFeatures = [
        "Saddle Fold: Allows for the incorporation of a saddle fold feature, enhancing tube presentation and aesthetics.",
        "Multi - Color Filling: Enables the capability for multi - color filling, providing flexibility in product presentation.",
        "Designer Seal in Plastic Tubes: Adds the option for designer seals in plastic tubes, enhancing branding and visual appeal.",
        "Gas Flushing Before & After Filling: Incorporates gas flushing before and after filling, contributing to product freshness and shelf life optimization.",
    ]

    return (
        <div className="text-justify">
            <div>
                <Image className="d-none d-md-block float-right ms-2" src={single_head_tube_filling_machine} alt="Lab Blender Equipment" style={{ borderRadius: "10px", width: 320, height: "100%" }} />
                <div>
                    The Fully Automatic Tube Filling Machine is designed for efficient filling and sealing of tubes, catering to both LAMI and Plastic tube materials. Widely applicable across the Pharmaceutical, Cosmetics, Chemical, Agriculture, and Food industries, this machine is versatile and capable of handling various materials, including Cosmetic Creams, Pastes, Honey, Gel, Gum, Balm, Ointments, and more.
                    <br />
                    <br />
                    The machine's seamless operation encompasses tube feeding, filling, sealing, and coding (manufacturing date, expiry, lot number, etc.) in a single, indexed operation. Suitable for LAMI, Plastic, and Aluminium tubes, it provides a comprehensive solution for diverse tube-filling needs.
                </div>
            </div>
            <div>
                Our product line includes both fully automatic and semi-automatic tube filling machines, accommodating high viscosity materials such as paint, adhesive, cosmetics, lotion, toothpaste, shampoo, and more. This machine stands as a reliable and versatile solution for a wide range of tube-filling applications.
            </div>
            <div className="text-center">
                <Image className="w-75 d-md-none" src={single_head_tube_filling_machine} alt="Lab Blender Equipment" style={{ borderRadius: "10px" }} />
            </div>
            <BulletPointContainer
                heading={<div className="text-start">{"Key Features"}</div>}
                bulletPointArray={keyFeatures.map(r => ({ description: r }))}
                variant={VARIANTS.BULLETS}
                numberOfColumns={2}
                justify="flex-start"
                lastRowFullWidth={true}
            />
            {/* <DataCard
                title="Key Features"
                component={<DataList dataList={keyFeatures} />}
            /> */}
            <BulletPointContainer
                heading={<div className="text-start">{"Optional Features"}</div>}
                bulletPointArray={optionalFeatures.map(r => ({ description: r }))}
                variant={VARIANTS.BULLETS}
                numberOfColumns={2}
                justify="flex-start"
                lastRowFullWidth={true}
            />
            {/* <DataCard
                title="Optional Features"
                component={<DataList dataList={optionalFeatures} />}
            /> */}
            
        </div>
    );
};

export default SingleHeadTubeFillingMachineData;
