import axios from "axios";

const saveContact = async (e, data, CRM_URL) => {
  e.preventDefault();

  // Google Sheet Code
  // const endpoint = "https://script.google.com/macros/s/AKfycbz6s7sxHnljpNcPnmZ7c6nhvAak2a8goYc20QaX2ptVQmwMZTce5l6aMG6c4xVG7WA-qA/exec";
  // const response = await axios.post(endpoint, data);
  // console.log(response);

  console.log(data);
  const admin_subject = `Lead from website`;
  const admin_message = `Hello Team,<br/>${data.get(
    "name"
  )} is trying to contact you.<br/><br/>Details are as follows.<br/>Name: ${data.get(
    "name"
  )}<br/>Mobile: ${data.get("phone")}<br/>Email: ${data.get(
    "email"
  )}<br/>Message: ${data.get("message")}
  `;
  const subject = `Proactimo Website Team`;
  const message = `Hello ${data.get(
    "name"
  )}, <br/><br/> Thank you for contacting us. <br/> We'll get back to you soon.<br/><br/>Regards,<br/>Team Proactimo.`;
  
  
  const url = `${CRM_URL}/openapi/clientsmtp/	2e7fce68-348c-45f0-bb05-d37b6a3f3b5d/contactus/?subject=${encodeURIComponent(subject)}&message=${encodeURIComponent(message)}&to=${data.get(
    "email"
  )}&admin_subject=${encodeURIComponent(admin_subject)}&admin_message=${encodeURIComponent(admin_message)}`;
  console.log(url);
  // const base_url = CRM_URL + "/api/openapi/clientsmtp/ac368fd3-4978-482f-8082-1815348b4a95/contactus/"
  
  try {
    const response = await axios.post(url, data);
    console.log(response);
    return true;
  } catch (err) {
    // console.log(err);
    return false;
  }
};

export default saveContact;
