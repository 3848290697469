import React from "react";
import { Image } from "react-bootstrap";
import DataCard from "components/Site/DataCard";
import DataList from "components/Site/DataList";
import { tablet_coater } from "assets/images/equipments";
import BulletPointContainer, { VARIANTS } from "components/Commons/BulletPoint";

const TabletCoaterData = () => {

    const keyFeatures = [
        "Modular design for user-friendly operation.",
        "Accurate Temperature Control within ± 1°C for precise coating.",
        "In-built WIP (Wash in Place) system with a pump for easy cleaning.",
        "Easy to clean and maintain, reducing changeover time.",
        "Quick-release plenums facilitate effortless cleaning of the pan and plenums.",
        "Leak-proof sealing of doors ensures efficient operation without energy loss.",
        "Fully automatic operation through PLC with a color HMI for streamlined control.",
        "3 Stage AHU with D.P. Gauge and Puff insulation for optimized air handling.",
        "21 CFR Part 11 compliance available on request for regulatory adherence.",
    ];

    const optionalEnhancements = [
        "Sugar dosing system for precise control over coating ingredients.",
        "Interchangeable pan system for handling a variety of batch sizes in one machine.",
        "Flameproof design for added safety in specific environments.",
        "Dry or wet scrubber at exhaust for efficient air handling.",
        "Inlet and outlet duct for optimized airflow.",
        "Interchangeable drum for flexibility in handling different batch sizes.",
        "Spray nozzle for tablet coating with reduced surface area to prevent bearding.",
        "Automatic charging and discharging of tablets for ease of operation (Optional).",
        "IR Sensor for accurate tablet bed temperature control (Optional).",
        "Inflatable gaskets to seal off the process chamber.",
        "Swivel arm for ease of operation.",
        "Sugar coating attachment for added flexibility (Optional).",
        "MHMD Pump for a uniform spray rate from all the nozzles.",
    ];

    return (
        <div>
            <div className="text-justify">
                The Tablet Coater is meticulously designed with key features aimed at
                enhancing productivity and flexibility in the tablet coating process.
                Its user-friendly operation and adherence to high safety standards make
                it a reliable solution that consistently delivers results with the utmost
                quality and efficiency.
            </div>

            <div className="text-center my-4">
                <Image src={tablet_coater} alt="Lab Blender Equipment" style={{ borderRadius: "10px", width: 300 }} />
            </div>
            {/* <DataCard
                title="Key Features"
                component={<DataList dataList={keyFeatures} />}
            /> */}
            <BulletPointContainer
                heading={<div className="text-start">Key Features</div>}
                justify="flex-start"
                variant={VARIANTS.BULLETS}
                bulletPointArray={keyFeatures.map(r => ({ description: r }))}
            />
            <div>
                Experience the convenience, precision, and compliance features of the Tablet
                oater, designed to elevate the efficiency and reliability of the tablet coating process.
            </div>

            <br />
            {/* <DataCard
                title="Optional Enhancements"
                component={<DataList dataList={optionalEnhancements} />}
            /> */}
            <BulletPointContainer
                heading={<div className="text-start">Optional Enhancements</div>}
                justify="flex-start"
                variant={VARIANTS.BULLETS}
                numberOfColumns={3}
                bulletPointArray={optionalEnhancements.map(r => ({ description: r }))}
            />
            <div>
                Customize your Tablet Coater with these optional features to meet specific
                requirements and enhance the overall functionality of the coating process.
            </div>
            <br />
        </div>
    );
};

export default TabletCoaterData;
