import React from "react";
import { Image } from "react-bootstrap";
import DataCard from "components/Site/DataCard";
import DataList from "components/Site/DataList";
import { roller_compacter } from "assets/images/equipments";
import BulletPointContainer, { VARIANTS } from "components/Commons/BulletPoint";

const RollerCompacterData = () => {

    const dryGranulation = [
        <div><b>Dust Elimination:</b> The process helps eliminate dust, contributing to a cleaner and safer working environment.</div>,
        <div><b>Bulk Density Increase:</b> It increases the bulk density of the material, allowing for more efficient storage and transportation.</div>,
        <div><b>Reduced Storage Volume:</b> The compacted granules occupy less storage space, optimizing warehouse utilization.</div>,
        <div><b>Improved Handling:</b> The granules exhibit improved handling characteristics, making them easier to work with during subsequent processing steps.</div>,
        <div><b>Manipulation of Dissolution Properties:</b> The technique allows for the manipulation of dissolution properties, providing control over how the tablet dissolves in the body.</div>,
    ]

    const keyFeatures = [
        "Accurate and precise dosage for consistent product quality.",
        "Reduction in dust formation at the workplace, promoting a safer working environment.",
        "Positive influence on dispersion and solubility characteristics, enhancing product performance.",
        "Homogeneous distribution ensures uniformity in the final product.",
    ];

    const optionalEnhancements = [
        "Sugar dosing system for precise control over coating ingredients.",
        "Interchangeable pan system for handling a variety of batch sizes in one machine.",
        "Flameproof design for added safety in specific environments.",
        "Dry or wet scrubber at exhaust for efficient air handling.",
        "Inlet and outlet duct for optimized airflow.",
        "Interchangeable drum for flexibility in handling different batch sizes.",
        "Spray nozzle for tablet coating with reduced surface area to prevent bearding.",
        "Automatic charging and discharging of tablets for ease of operation (Optional).",
        "IR Sensor for accurate tablet bed temperature control (Optional).",
        "Inflatable gaskets to seal off the process chamber.",
        "Swivel arm for ease of operation.",
        "Sugar coating attachment for added flexibility (Optional).",
        "MHMD Pump for a uniform spray rate from all the nozzles.",
    ];

    return (
        <div>
            <div className="text-justify">
                Continuous dry granulation by roller compaction has firmly established
                itself as a process in the pharmaceutical, chemical, and life science
                industries. The primary objective is to enhance the flowability of a powder
                or powder blend, facilitating rapid and uniform filling of a tablet press.
                The challenge lies in producing tablets with consistent weight and sufficient
                strength while maintaining an even distribution of the Active Pharmaceutical
                Ingredients (API). Roller compaction achieves this for a wide range of
                products without the need for additional ingredients like binders, moisture,
                or lubricants.
            </div>
            {/* <div>Dry granulation by roller compaction serves multiple purposes, including:</div>
            <DataCard
                title=""
                component={<DataList dataList={dryGranulation} />}
            /> */}
            <BulletPointContainer
                variant={VARIANTS.BULLETS}
                heading={<div className="text-start">Dry granulation by roller compaction serves multiple purposes, including:</div>}
                justify="flex-start"
                bulletPointArray={dryGranulation.map(r => ({ description: r }))}
                lastRowFullWidth={true}
            />
            <div>
                In summary, dry granulation by roller compaction is a versatile and effective
                method that not only addresses processing challenges but also contributes to
                overall efficiency, safety, and product quality in the pharmaceutical and related industries.
            </div>
            <div className="text-center my-3">
                <Image src={roller_compacter} alt="Lab Blender Equipment" style={{ borderRadius: "10px", width: 300 }} />
            </div>
            {/* <DataCard
                title="Key Features"
                component={<DataList dataList={keyFeatures} />}
            /> */}
            <BulletPointContainer
                variant={VARIANTS.BULLETS}
                heading={<div className="text-start">Key Features</div>}
                bulletPointArray={keyFeatures.map(r => ({ description: r }))}
                lastRowFullWidth={true}
                justify="flex-start"
                numberOfColumns={4}
            />
            <div>
                These salient features highlight the advantages of continuous dry granulation
                by roller compaction, emphasizing its role in delivering precise dosages,
                minimizing dust-related issues, and optimizing the distribution and performance
                of the pharmaceutical or chemical product.
            </div>
            <BulletPointContainer
                heading={<div className="text-start">The compaction process unfolds in three consecutive steps</div>}
                justify="flex-start"
                variant={VARIANTS.BULLETS}
                hideNumbers={false}
                numberOfColumns={1}
                bulletPointArray={[
                    {
                        title: "Feeding",
                        description: <ul className="ps-4 mb-0">
                            <li className="text-start">Powder is delivered through a horizontal screw feeding section using a suitable feed-in system.</li>
                            <li className="text-start">This ensures the product is dosed precisely, consistently, and accurately to the rollers.</li>
                        </ul>
                    },
                    {
                        title: "Compaction",
                        description: <ul className="ps-4 mb-0">
                            <li className="text-start">The powder is compacted between two rollers, resulting in the formation of a homogeneous solid flake.</li>
                            <li className="text-start">The bulk density of the flake is significantly increased during this compaction step.</li>
                            <li className="text-start">Proper ventilation is crucial to allow displaced air during compaction to exit the process effectively.</li>
                        </ul>
                    },
                    {
                        title: "Granulation",
                        description: <ul className="ps-4 mb-0">
                            <li className="text-start">The flakes generated through the compaction process undergo granulation using a suitable single or multistage granulation system.</li>
                            <li className="text-start">Granules are formed, adhering to the defined size criteria.</li>
                        </ul>
                    },
                ]}
            />
            <div>
                This three-step process ensures the transformation of powder into compacted
                flakes and further granulation to produce the desired granule size, contributing
                to the overall efficiency and effectiveness of the compaction process.
            </div>
            <BulletPointContainer
                heading={<div className="text-start">The vertical roller arrangement offers several advantages in the compaction process</div>}
                justify="flex-start"
                variant={VARIANTS.BULLETS}
                hideNumbers={false}
                numberOfColumns={1}
                bulletPointArray={[
                    {
                        title: "Controlled Feed Flow",
                        description: <ul className="ps-4 mb-0">
                            <li className="text-start">Enables greater control of feed flow without the effects of gravity.</li>
                            <li className="text-start">Enhances precision in the dosing of raw material to the rollers.</li>
                        </ul>
                    },
                    {
                        title: "Separation of Side Seal Losses",
                        description: <ul className="ps-4 mb-0">
                            <li className="text-start">Side seal losses can be separated from the compacted material during the vertical roller arrangement.</li>
                            <li className="text-start">These losses can be reintroduced to the raw material if necessary.</li>
                        </ul>
                    },
                    {
                        title: "Minimization of Fine Material",
                        description: <ul className="ps-4 mb-0">
                            <li className="text-start">Leads to the minimization of fine material in the finished product.</li>
                            <li className="text-start">Does not compromise the consistency and composition of the batch.</li>
                        </ul>
                    },
                ]}
            />
            <div>
                The vertical roller arrangement, by mitigating the impact of gravity and
                allowing for the separation and controlled reintroduction of side seal losses,
                contributes to the production of a high-quality finished product with minimized
                fine material content.
            </div>
            <BulletPointContainer
                heading={<div className="text-start">The Combo-Feeder, an optimized feeding system, introduces several enhancements to the compaction process:</div>}
                justify="flex-start"
                variant={VARIANTS.BULLETS}
                hideNumbers={false}
                numberOfColumns={1}
                bulletPointArray={[
                    {
                        title: "Air Displacement Optimization",
                        description: <ul className="ps-4 mb-0">
                            <li className="text-start">During the compaction process, displaced air is effectively allowed to escape through a second chamber in the feed hopper.</li>
                            <li className="text-start">This ensures a uniform and uninterrupted feed of raw material into the rollers.</li>
                        </ul>
                    },
                    {
                        title: "Recycling of Granules:",
                        description: <ul className="ps-4 mb-0">
                            <li className="text-start">The second chamber facilitates the recycling of undersize and oversize granules.</li>
                            <li className="text-start">These recycled granules can be seamlessly reintroduced to the raw material, if necessary..</li>
                        </ul>
                    },
                    {
                        title: "Vacuum Assistance",
                        description: <ul className="ps-4 mb-0">
                            <li className="text-start">The feeding unit can be assisted by a vacuum system, which minimizes the product layer on the inside of the screw housing.</li>
                            <li className="text-start">This is particularly beneficial for processing fluidizing products with low bulk density.</li>
                        </ul>
                    },
                ]}
            />
            <div>
                The Combo-Feeder, by optimizing air displacement and incorporating recycling
                capabilities, improves the overall efficiency and versatility of the compaction
                process. The vacuum assistance further enhances the handling of products with
                specific characteristics.
            </div>
            <br />
            <div>
                The two-stage granulation in diagonal design, specifically with Rotor-Fine-Granulators,
                is widely applied in the chemical, food, and pharmaceutical industries for sizing soft
                to medium-hard products. Here's an overview of the process:
            </div>
            <BulletPointContainer
                justify="flex-start"
                variant={VARIANTS.CARD}
                hideNumbers={false}
                numberOfColumns={2}
                bulletPointArray={[
                    {
                        title: "Rotor Screen Granulators:",
                        description: <ul className="ps-4 mb-0">
                            <li className="text-start">Commonly used for sizing soft to medium-hard products in various industries.</li>
                            <li className="text-start">Consists of a rotor with vertical rotor bars and a screen mesh through which the granulate is pushed by the rotor.</li>
                        </ul>
                    },
                    {
                        title: "Working Principle:",
                        description: <ul className="ps-4 mb-0">
                            <li className="text-start">Incoming flakes undergo pre-crushing before being compressed into the working zone.</li>
                            <li className="text-start">In the working zone, the particle size is further reduced as the material is pushed through the screen.</li>
                        </ul>
                    },
                ]}
            />
            <div>
                The diagonal design, with its rotor-fine-granulators, offers an efficient two-stage
                granulation process that ensures precise sizing of the material. This design is
                particularly beneficial for industries requiring controlled particle size distribution
                in their end products.
            </div>
        </div>
    );
};

export default RollerCompacterData;

{/* 
<ol>
    <li>
        <b>Feeding</b>
        <ul>
            <li></li>
            <li></li>
        </ul>
    </li>
    <li>
        <b>Feeding</b>
        <ul>
            <li></li>
            <li></li>
        </ul>
    </li>
    <li>
        <b>Feeding</b>
        <ul>
            <li></li>
            <li></li>
        </ul>
    </li>
</ol>
*/}
