import React from "react";
import { Image } from "react-bootstrap";
import DataCard from "components/Site/DataCard";
import DataList from "components/Site/DataList";
import { viscous_pneumatic_piston_filling_machine } from "assets/images/equipments";
import BulletPointContainer, { VARIANTS } from "components/Commons/BulletPoint";

const ViscousPneumaticPistonFillingMachineData = () => {

    const keyFeatures = [
        "Pneumatic-based Dripping Tray, ensuring efficient handling of potential drips.",
        "Pneumatic Neck Holding System for secure bottle positioning during the filling process.",
        "Stainless Steel Flexible Pipes for hazardous product applications, ensuring safety and durability.",
        "Utilizes reliable and proven pneumatic components for consistent performance.",
    ];

    const optionalFeatures = [
        "Polycarbonate Enclosure in Stainless Steel Structure, offering enhanced visibility and durability.",
        "Flame-Proof Motor & Control Panel for increased safety in hazardous environments.",
        "Pre & Post Nitrogen Flushing System, contributing to product freshness and shelf life.",
        "In-feed Turn Table for continuous bottle feeding, optimizing production efficiency.",
    ]

    return (
        <>
            <div className="text-justify">
                <Image className="float-right ms-2" src={viscous_pneumatic_piston_filling_machine} alt="Lab Blender Equipment" style={{ borderRadius: "10px", width: 310, height: "100%" }} />

                <div>
                    The Automatic Viscous Pneumatic Piston Filling Machine, featuring a Servo-based operating system, caters to a diverse range of industries including Food & Beverage, Cosmetics, Personal Care, Agricultural, Animal Care, Pharmaceutical, and Chemical fields. Renowned for its flexibility, this Servo Piston Filler excels in volumetrically filling liquids across a broad viscosity spectrum—from low thin viscosity liquids to high viscosity liquids or creams, with or without particulates.
                </div>
                <br />
                <div>
                    The product delivery mechanism from the bulk tank to the pistons is highly customizable. It can be configured with a buffer tank utilizing a level-sensing float or through the manifold using direct draw or recirculation methods, offering adaptability to various production requirements.
                </div>
            </div>
            <BulletPointContainer
                heading={<div className="text-start">{"Key Features"}</div>}
                bulletPointArray={keyFeatures.map(r => ({ description: r }))}
                variant={VARIANTS.BULLETS}
                numberOfColumns={2}
                justify="flex-start"
                lastRowFullWidth={true}
            />
            {/* <DataCard
                title="Key Features"
                component={<DataList dataList={keyFeatures} />}
            /> */}
            {/* <DataCard
                title="Optional Features"
                component={<DataList dataList={optionalFeatures} />}
            /> */}
            <BulletPointContainer
                heading={<div className="text-start">{"Optional Features"}</div>}
                bulletPointArray={optionalFeatures.map(r => ({ description: r }))}
                variant={VARIANTS.BULLETS}
                numberOfColumns={2}
                justify="flex-start"
                lastRowFullWidth={true}
            />
        </>
    );
};

export default ViscousPneumaticPistonFillingMachineData;
