import React from "react";
import { Image } from "react-bootstrap";
import DataCard from "components/Site/DataCard";
import DataList from "components/Site/DataList";
import { volumetric_liquid_filling_machines } from "assets/images/equipments";
import BulletPointContainer, { VARIANTS } from "components/Commons/BulletPoint";

const VolumetricLiquidFillingMachinesData = () => {

    const keyFeatures = [
        "± 1 % filling accuracy on a single dose ensures precise and consistent filling.",
        "No bottle, no fill system for enhanced operational control.",
        "Rigid, vibration - free construction with an elegant matte finish body.",
        "Easy - to - operate control panel for user - friendly interactions.",
        "Minimal changeover time, facilitating swift transitions between container sizes or fill sizes.",
        "Self - lubricating UHMW - PE guide profile for SS slate chain, offering a low - friction, low - wear surface, ensuring smooth and noiseless conveying.",
        "Error code display on AC frequency drive or control panel for quick issue resolution.",
        "Drain tray around the machine platform for easy maintenance.",
        "Drip tray under the conveyor to manage spills effectively.",
        "Mechanical bottle stopping arrangement for precise filling.",
        "Synchronized main motor and conveyor belt A / C drives for optimal control.",
    ];

    const optionalFeatures = [
        "Automatic chain washing system for the conveyor belt, ensuring thorough cleanliness.",
        "In - built pre - inspection of empty bottles, enhancing quality control.",
        "Acrylic cabinet for a polished and protective appearance.",
        "Acrylic cover on the conveyor, combining safety with visibility.",
        "Float tank(Holding tank) with or without a stirrer, providing flexibility in liquid handling.",
    ]

    return (
        <>
            <div>
                The Automatic Volumetric Liquid Filling machines are meticulously designed to automate the filling of bottles or jars with unparalleled precision. With a wide range of applications in industries such as food, pharmaceuticals, and the oil sector, our machines are engineered for optimal efficiency and effectiveness at their designed speed. Experience seamless and accurate liquid filling for enhanced productivity in your operations.
            </div>
            <div className="my-3 text-center">
                <Image src={volumetric_liquid_filling_machines} alt="Lab Blender Equipment" style={{ borderRadius: "10px", width: 350 }} />
            </div>
            <BulletPointContainer
                heading={<div className="text-start">{"Key Features"}</div>}
                bulletPointArray={keyFeatures.map(r => ({ description: r }))}
                variant={VARIANTS.BULLETS}
                justify="flex-start"
                lastRowFullWidth={true}
            />
            {/* <DataCard
                title="Key Features"
                component={<DataList dataList={keyFeatures} />}
            /> */}
            <div>
                These salient features highlight the precision, efficiency, and user-friendly design of our Automatic Volumetric Liquid Filling machines, making them an ideal choice for various industries.
            </div>
            <BulletPointContainer
                heading={<div className="text-start">{"Optional Features"}</div>}
                bulletPointArray={optionalFeatures.map(r => ({ description: r }))}
                variant={VARIANTS.BULLETS}
                justify="flex-start"
                lastRowFullWidth={true}
            />
            {/* <DataCard
                title="Optional Features"
                component={<DataList dataList={optionalFeatures} />}
            /> */}
            <div>
                Customize your Automatic Volumetric Liquid Filling machine with these optional features to meet specific operational needs and elevate overall functionality.
            </div>
            <div className="text-center">
                <Image className="w-75 d-md-none" src={volumetric_liquid_filling_machines} alt="Lab Blender Equipment" style={{ borderRadius: "10px" }} />
            </div>
        </>
    );
};

export default VolumetricLiquidFillingMachinesData;
