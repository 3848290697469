import React from "react";
import { Image } from "react-bootstrap";
import DataCard from "components/Site/DataCard";
import DataList from "components/Site/DataList";
import { lab_coater } from "assets/images/equipments";
import BulletPointContainer, { VARIANTS } from "components/Commons/BulletPoint";

const LabCoaterData = () => {

    const salientFeatures = [
        <div><b>Ergonomically Designed Compact Footprint:</b> The Lab Coater boasts a compact design that maximizes efficiency without compromising on space.</div>,
        <div><b>Easy to Clean and Maintain:</b> Simplified design for easy cleaning and maintenance, ensuring a seamless coating process.</div>,
        <div><b>PLC Based Operation with Color HMI:</b> Precision control through a Programmable Logic Controller (PLC) and a Color Human-Machine Interface (HMI) for user-friendly operation.</div>,
        <div><b>Interchangeable Pans:</b> Accommodates the smallest test batches with ease, thanks to interchangeable pans.</div>,
        <div><b>Easy Front Loading and Unloading:</b> Streamlined front-loading and unloading processes for enhanced convenience.</div>,
        <div><b>In-Built Process Air Handling System with HEPA Filter:</b> Ensures a controlled environment with an in-built air handling system equipped with a High-Efficiency Particulate Air (HEPA) filter.</div>,
        <div><b>Highly Efficient Non-Choking Type Spray Guns:</b> Enhances the coating process with non-choking type spray guns for optimal efficiency.</div>,
        <div><b>Automatic Controlled Process Parameters:</b> Precise control with automatic adjustment of process parameters for consistent coating results.</div>,
        <div><b>Spraying Interlocked with Pan Rotation & Blower Functions:</b> Ensures synchronized operation by interlocking spraying with pan rotation and blower functions.</div>,
        <div><b>Highly Efficient Solution Dosing System with Peristaltic Pump:</b> Achieves efficiency in solution dosing with a highly effective peristaltic pump system.</div>,
        <div><b>Variable Frequency Drive for Blowers:</b> Enhances control over the coating process with a Variable Frequency Drive (VFD) for blowers, allowing the regulation of air quantum.</div>,
    ];

    return (
        <>
            <div >
                <div className="text-justify">
                    Unleash the power of efficient film coating with our Lab Coater,
                    meticulously designed for tablet coating. Engineered to deliver
                    results of the highest quality, efficiency, and reliability, this
                    product is your gateway to precision in pharmaceutical processes.
                </div>
                <div className="text-center my-3">
                    <Image className="w-50" src={lab_coater} alt="Lab Coater Equipment" style={{ borderRadius: "10px" }} />
                </div>
                <BulletPointContainer
                    heading={"Salient Features"}
                    variant={VARIANTS.BULLETS}
                    numberOfColumns={2}
                    lastRowFullWidth={true}
                    bulletPointArray={salientFeatures.map(r => ({ description: r }))}
                />
                {/* <DataCard
                    title="Salient Features"
                    component={<DataList dataList={salientFeatures} />}
                /> */}
                <div className="text-center">
                    Elevate your coating processes with the Lab Coater—where precision,
                    efficiency, and reliability converge to deliver pharmaceutical excellence.
                </div>
            </div>
        </>
    );
};

export default LabCoaterData;
