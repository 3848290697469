import React, { useState } from "react";
import { Image } from "react-bootstrap";
import DataCard from "components/Site/DataCard";
import DataList from "components/Site/DataList";
import CustomTabs from "components/Site/CustomTabs";
import { lab_fluid } from "assets/images/equipments";
import BulletPointContainer, { VARIANTS } from "components/Commons/BulletPoint";

const LabFluidData = () => {

    const [active, setActive] = useState(0);

    const bedDryerSalientFeatures = [
        "Low Maintenance with a table-top trolley base.",
        "Unmatched flexibility for both LAB and Scale-Up Batches.",
        "Transparent Retarding Chamber for clear fluidization visualization.",
        "Variable Frequency Drive (VFD) for Blower Motor.",
        "FLP Electric Heaters for safe heating.",
        "Plug and Play Machine for seamless operation.",
        "PLC-based control system for precise process monitoring (Optional).",
        "Customized operator interface graphic (HMI) for user-friendly interactions (Optional).",
        "Safety interlocks ensure secure operation.",
    ];

    const bedProcessorSalientFeatures = [
        <div><b>3-in-1 Combo Machine:</b> Efficiently handles Drying, Granulating, and Pellet Coating in one powerful unit.</div>,
        <div><b>Low Maintenance:</b> Enjoy hassle-free operation with minimal maintenance requirements and a convenient table-top trolley base.</div>,
        <div><b>Versatility Unleashed:</b> Offers ultimate flexibility for both LAB and Scale-Up Batches, adapting to your evolving needs.</div>,
        <div><b>Transparent Retarding Chamber:</b> Clear Fluidization Visual for enhanced process visibility and control.</div>,
        <div><b>Precision Atomizing Air Control:</b> HMI-powered control ensures accuracy in atomizing air for optimal performance.</div>,
        <div><b>Efficient Heating:</b> Equipped with Flameproof (FLP) Electric Heaters for safe and efficient heating.</div>,
        <div><b>Plug and Play:</b> User-friendly setup with a plug-and-play design for quick and easy operation.</div>,
        <div><b>PLC-Based Control System:</b> Monitors processes effectively with a PLC-based control system.</div>,
        <div><b>Customized Operator Interface:</b> HMI allows for a customized operator interface graphic, ensuring ease of use.</div>,
        <div><b>Safety Interlocks:</b> Built-in safety interlocks for secure and reliable operation.</div>,
    ];

    const data = [
        {
            title: "Lab Fluid Bed Dryer",
            description: "Experience the epitome of efficiency and versatility in drying and agglomerating processes with our Lab Fluid Bed Dryer. Perfect for pharmaceuticals, nutraceuticals, chemicals, food, and allied industries.",
            firstSection: bedDryerSalientFeatures,
        },
        {
            title: "Lab Fluid Bed Processor",
            description: "Explore the perfect synergy of drying, granulating, and pellet coating with our 3-in-1 combo machine – the Lab Fluid Bed Processor. Engineered for seamless operation, this machine ensures optimal flexibility for both LAB and Scale-Up Batches.",
            firstSection: bedProcessorSalientFeatures,
            secondSection: "Experience the next level of processing efficiency with our Lab Fluid Bed Processor!",
        },
    ]

    return (
        <>
            <div className="p-2">
                <div className="d-flex align-items-center py-1 overflow-scroll scrollbar-none">
                    {data.map((tab, i) => {
                        return (
                            <div
                                className={`d-flex align-items-center px-2 px-sm-3 px-lg-4 pb-2 text-center ${active === i ? "text-primary active-tab px-sm-" : ""
                                    }`}
                                style={{ minWidth: 120, alignSelf: "stretch" }}
                                onClick={() => setActive(i)}
                            >
                                <p>{tab.title}</p>
                            </div>
                        )
                    })}
                </div>
                <div className="py-2">
                    <p className="text-justify">{data[active]?.description}</p>
                    {/* <DataCard
                        title="Silent Features:"
                        component={<DataList dataList={data[active]?.firstSection} />}
                    /> */}
                    <div className="d-flex justify-content-center my-3">
                        <Image className="w-50" src={lab_fluid} alt="Lab Fluid Equipment" style={{ borderRadius: "10px" }} />
                    </div>
                    <BulletPointContainer
                        heading={"Silent Features"}
                        variant={VARIANTS.BULLETS}
                        numberOfColumns={data[active]?.firstSection.length%3===0? 3 : 2}
                        bulletPointArray={data[active]?.firstSection?.map(r => ({ description: r }))}
                    />
                    <p className="text-center">{data[active]?.secondSection}</p>
                    <br />
                </div>
            </div>

        </>
    );
};

export default LabFluidData;
