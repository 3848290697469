import { clients_cover } from "assets";
import {
  Afcon,
  Alkem,
  Aurobindo,
  Axxelent,
  BPCL,
  BridgeAndRoof,
  Cipla,
  GHPL,
  Godrej,
  Hetero,
  HPCL,
  Intas,
  IOCL,
  LnT,
  Lupin,
  Mankind,
  MSN,
  Phenix,
  Tata,
} from "assets/images/clients";
import DiamondCard from "components/Site/DiamondCard";
import PageTitleCover from "components/Site/PageTitleCover";
import PrimaryLine from "components/Site/PrimaryLine";
import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";

const description =
  <div>
    Empowering Success Through Industry Partnerships and Connecting Innovation to Success,
    <br />
    One Client at a Time.
  </div>;

export const clientsData = [
  { title: "Cipla", image: Cipla },
  { title: "Alkem", image: Alkem },
  { title: "Aurobindo", image: Aurobindo },
  { title: "Mankind", image: Mankind },
  { title: "Hetero", image: Hetero },
  { title: "Lupin", image: Lupin },
  { title: "Intas", image: Intas },
  { title: "GHPL", image: GHPL },
  { title: "Godrej", image: Godrej },
  { title: "Tata", image: Tata },
  { title: "Axxelent", image: Axxelent },
  { title: "MSN", image: MSN },
  { title: "BridgeAndRoof", image: BridgeAndRoof },
  { title: "LnT", image: LnT },
  { title: "Phenix", image: Phenix },
  { title: "Afcon", image: Afcon },
  { title: "BPCL", image: BPCL },
  { title: "IOCL", image: IOCL },
  { title: "HPCL", image: HPCL },
];

const OurClients = () => {
  const xsmall = useMediaQuery({ query: "(min-width: 360px)" });
  const small = useMediaQuery({ query: "(min-width: 576px)" });
  const medium = useMediaQuery({ query: "(min-width: 768px)" });
  const large = useMediaQuery({ query: "(min-width: 992px)" });
  const xlarge = useMediaQuery({ query: "(min-width: 1200px)" });

  //   const [result, setResult] = useState(null);

  const diamondCardStyle = {
    width: xlarge
      ? 200
      : large
        ? 160
        : medium
          ? 140
          : small
            ? 120
            : xsmall
              ? 100
              : 80,
    height: xlarge
      ? 200
      : large
        ? 160
        : medium
          ? 140
          : small
            ? 120
            : xsmall
              ? 100
              : 80,
    // height: medium ? 200 : small ? 140 : 100,
  };

  // ---------- for creating sub arrays of required sizes ----------
  function divideArray(arr, sizes) {
    const dividedArrays = [];
    let currentIndex = 0;

    for (const size of sizes) {
      if (currentIndex >= arr.length) {
        break;
      }

      const subarray = arr.slice(currentIndex, currentIndex + size);
      dividedArrays.push(subarray);

      currentIndex += size;
    }

    return dividedArrays;
  }

  const subarraySizes = large ? [3, 4, 5, 4, 3] : [1, 2, 3, 2, 3, 2, 3, 2, 1];
  const result = divideArray(clientsData, subarraySizes);

  return (
    <>
      <PageTitleCover
        backgroundImage={clients_cover}
        title="Meet Our Clients"
        description={description}
      />
      {/* <div className="content-wrapper pt-5 pb-4">
        <p className="text-center text-uppercase mb-2">Our Clients</p>
        <h2 className="text-center mb-3">Meet Our Clients</h2>
        <PrimaryLine className="mx-auto mb-5" />
      </div> */}
      <section className="bg-grey py-5">
        <div
          className="content-wrapper justify-content-center"
        //   style={{ flexWrap: "wrap" }}
        >
          {/* {result &&
            result.map((subarray, i) => {
              return (
                <div className="d-flex flex-column mx-auto">
                  {i % 2 === 0 ? (
                    <div
                      className="d-flex mx-auto"
                      style={{
                        marginTop:
                          i === 0 ? 0 : medium ? -100 : small ? -70 : -50,
                      }}
                    >
                      {subarray.map((item) => (
                        <DiamondCard
                          title={item.title}
                          image={item.image}
                          style={{
                            width: medium ? 200 : small ? 140 : 100,
                            height: medium ? 200 : small ? 140 : 100,
                          }}
                        />
                      ))}
                    </div>
                  ) : (
                    <div
                      className="d-flex mx-auto"
                      style={{
                        marginTop:
                          i === 1
                            ? medium
                              ? -100
                              : small
                              ? -70
                              : -50
                            : medium
                            ? -100
                            : small
                            ? -70
                            : -50,
                      }}
                    >
                      {subarray.map((item) => (
                        <DiamondCard
                          title={item.title}
                          image={item.image}
                          style={{
                            width: medium ? 200 : small ? 140 : 100,
                            height: medium ? 200 : small ? 140 : 100,
                          }}
                        />
                      ))}
                    </div>
                  )}
                </div>
              );
            })} */}

          {result && (
            <div className="d-flex flex-column">
              <div className="d-flex mx-auto">
                {result[0]?.map((item) => (
                  <DiamondCard
                    title={item.title}
                    image={item.image}
                    style={diamondCardStyle}
                  />
                ))}
              </div>
              {result?.slice(1)?.map((res, i) => (
                <div
                  className="d-flex mx-auto"
                  style={{ marginTop: -diamondCardStyle.width / 2 }}
                >
                  {result[i + 1]?.map((item) => (
                    <DiamondCard
                      title={item.title}
                      image={item.image}
                      style={{ ...diamondCardStyle }}
                    />
                  ))}
                </div>
              ))}
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default OurClients;
