import styles from "./BulletPoint.module.css";
import { right_arrow } from "assets";

const CardBulletPoint = ({ title = "", description = "", number, numberOfColumns, hideNumbers = false }) => {

    return (
        <div className={`${styles['inidividual-bullet-point-container']} ${styles[`cols-${numberOfColumns}`]}`}>
            {
                !hideNumbers && <div className={styles['number-container']}>
                    <div>
                        <span>{number}</span>
                    </div>
                </div>
            }
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: '100%' }}>
                {title && <h6 className="text-center my-2" style={{ overflowWrap: "anywhere" }}>{title}</h6>}
                <span style={{ textAlign: "center", overflowWrap: "anywhere" }}>{description}</span>
            </div>
        </div>
    );
}

const ArrowBullet = ({ title, description, index, numberOfColumns, hideNumbers = true }) => {

    return (
        <div className={`d-flex ${styles['arrow-bullet-container']} ${styles[`arrow-cols-${numberOfColumns}`]}`}>
            <div className={`${styles['arrow-img-container']}`}>
                <div className={`${styles['arrow-img']}`}>

                </div>
            </div>
            <div className={`p-2 ${styles['arrow-bullet-content']}`}>
                {title && <h6 className="mt-1">
                    
                        {
                            !hideNumbers ? <span className="me-1">{index + 1}.</span> : null
                        }
                    
                    <span>
                        {title}
                    </span>
                </h6>}
                {description && <p className="pb-1">{description}</p>}
            </div>
        </div>
    )
}

export const VARIANTS = {
    "CARD": "CARD",
    "BULLETS": "BULLETS"
}

const BulletPointContainer = ({ heading, bulletPointArray = [], numberOfColumns = 3, hideNumbers = false, lastRowFullWidth = false, firstRowFullWidth = false, justify = "center", variant = VARIANTS.CARD }) => {

    if (bulletPointArray.length <= 0) {
        return <></>;
    }

    if (numberOfColumns > 4) {
        numberOfColumns = 4
    }

    const lengthOfBulletPoints = bulletPointArray.length;
    const columnsInLastRow = lengthOfBulletPoints % numberOfColumns;
    const indicesOfColumnsInLastRow = new Array(columnsInLastRow).fill().map((_, i) => lengthOfBulletPoints - i - 1);
    const indicesOfColumnsInFirstRow = new Array(columnsInLastRow).fill().map((_, i) => i);
    console.log({ indicesOfColumnsInLastRow });

    if (variant === VARIANTS.CARD) {
        return (
            <div className="my-3" style={{ display: "flex", flexDirection: "column", gap: 20 }}>
                {heading && <h5 className="text-center my-2" style={{ overflowWrap: "anywhere" }}>{heading}</h5>}
                <div style={{ display: "flex", flexWrap: "wrap", gap: 20, justifyContent: justify }}>
                    {
                        bulletPointArray?.map?.((bulletPoint, index) => {
                            let numberOfColumns_ = numberOfColumns;
                            if (lastRowFullWidth) {
                                numberOfColumns_ = indicesOfColumnsInLastRow.includes(index) ? columnsInLastRow : numberOfColumns;
                            } else if (firstRowFullWidth) {
                                numberOfColumns_ = indicesOfColumnsInFirstRow.includes(index) ? columnsInLastRow : numberOfColumns;
                            }

                            return (
                                <CardBulletPoint
                                    title={bulletPoint?.title}
                                    description={bulletPoint?.description}
                                    number={index + 1}
                                    numberOfColumns={numberOfColumns_}
                                    hideNumbers={hideNumbers}
                                />
                            )
                        })
                    }
                </div>
            </div>
        )
    }

    if (variant === VARIANTS.BULLETS) {
        return (
            <div className="my-3" style={{ display: "flex", flexDirection: "column", gap: 20 }}>
                {heading && <h5 className="text-center my-2" style={{ overflowWrap: "anywhere" }}>{heading}</h5>}
                <div style={{ display: "flex", flexWrap: numberOfColumns > 1 ? "wrap" : "nowrap", flexDirection: numberOfColumns > 1 ? "row" : "column", gap: 10, justifyContent: justify }}>
                    {
                        bulletPointArray?.map?.((bulletPoint, index) => {
                            let numberOfColumns_ = numberOfColumns;
                            if (lastRowFullWidth) {
                                numberOfColumns_ = indicesOfColumnsInLastRow.includes(index) ? columnsInLastRow : numberOfColumns;
                            } else if (firstRowFullWidth) {
                                numberOfColumns_ = indicesOfColumnsInFirstRow.includes(index) ? columnsInLastRow : numberOfColumns;
                            }
                            return (
                                <ArrowBullet
                                    title={bulletPoint?.title}
                                    description={bulletPoint?.description}
                                    index={index}
                                    numberOfColumns={numberOfColumns_}
                                    hideNumbers={hideNumbers}
                                />
                            )
                        })
                    }
                </div>
            </div>
        )
    }
}

export default BulletPointContainer;