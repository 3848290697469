import React from "react";
import { Image } from "react-bootstrap";
import DataCard from "components/Site/DataCard";
import DataList from "components/Site/DataList";
import { screw_capping_machine } from "assets/images/equipments";
import BulletPointContainer, { VARIANTS } from "components/Commons/BulletPoint";

const ScrewCappingMachineData = () => {

    const keyFeatures = [
        "No Container, No Machine Operation: Ensures that the machine operates only when a container is present, enhancing safety and efficiency.",
        "Low Noise Level, Low Power Consumption: Operates with minimal noise and consumes low power, contributing to a quieter and more energy - efficient work environment.",
        "Self - Lubricating Guide Profile: Utilizes self - lubricating UHMW - PE guide profiles for a low - friction wear surface, ensuring smooth and noiseless conveying during the capping process.",
        "Adjustable Conveyor Belt Height: Allows for manual adjustment of the conveyor belt height, facilitating seamless alignment with another off - machine in the production line.",
        "Synchronized A / C Drives: Employs synchronized A / C drives to coordinate the movement of the conveyor, star wheel, turret, and capping, ensuring a harmonized and efficient operation.",
        "Adjustable Bottle Height Gauge: Provides an adjustable bottle height gauge for easy and quick setup, enhancing user convenience during the capping process.",
        "Variable Sealing Pressure: Offers the flexibility to vary sealing pressure, catering to different cap gauges and sizes for optimal capping results.",
    ];

    return (
        <div className="text-justify">
            <div>
                <Image className="w-50 d-none d-md-block ms-2" src={screw_capping_machine} alt="Lab Blender Equipment" style={{ borderRadius: "10px", float: "right" }} />
                <div>
                    <div>
                        Automatic ROPP/Screw Capping Machine is a versatile and efficient solution for securing caps onto containers. The self-supported stainless steel structure with adjustable height, along with stainless-steel enclosures and doors, ensures durability and hygiene in its design.
                    </div>
                    <br />
                    <div>
                        In the operational flow, the feed containers move seamlessly on a conveyor belt and are directed into a star wheel. The star wheel positions the container below the sealing head during the subsequent indexing part. Simultaneously, the bottle picks up a cap from the delivery chute of the cap feeding bowl. This is a synchronized process, ensuring that the body and neck of the container are precisely positioned below the rotating head.
                    </div>
                    <br />
                    <div>
                        The sealing head then performs the crucial operations of threading and sealing, completing the capping process efficiently. Overall, your machine seems to offer a reliable and adaptable solution for ROPP/screw capping across various container sizes and shapes.
                    </div>
                </div>
            </div>
            <br />
            {/* <DataCard
                title="Key Features"
                component={<DataList dataList={keyFeatures} />}
            /> */}
            <BulletPointContainer
                heading={<div className="text-start">{"Key Features"}</div>}
                bulletPointArray={keyFeatures.map(r => ({ description: r }))}
                variant={VARIANTS.BULLETS}
                numberOfColumns={2}
                justify="flex-start"
                lastRowFullWidth={true}
            />
            <div className="text-center">
                <Image className="w-50 d-md-none" src={screw_capping_machine} alt="Lab Blender Equipment" style={{ borderRadius: "10px" }} />
            </div>
        </div>
    );
};

export default ScrewCappingMachineData;
