import { about_us_showcase } from "assets";
import PrimaryLine from "components/Site/PrimaryLine";
import SiteButton from "components/Site/SiteButton";
import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";

const AboutUsSection = ({ learnMoreRef }) => {

  const navigate = useNavigate()
  const location = useLocation();
  const params = location.pathname.split("/").filter((item) => item !== "");
  const param1 = params.length > 0 ? params[0] : "";

  useEffect(() => {
    if (location.hash !== "") {
      learnMoreRef.current.scrollIntoView()
    }
  }, [location])

  return (
    <section className="py-3">
      <Row lg={2}>
        <Col
          xs={{ order: 2 }}
          lg={{ span: 5, order: 1 }}
          className="d-flex align-items-center p-0"
        >
          <div>
            {/* <p className="text-uppercase mb-2">Learn More</p> */}
            <h2 className="mb-3">About Us</h2>
            <PrimaryLine />
            <p className="mb-3">
              Proactimo translates to “Your Professional Partner in Action”.
              We are a dynamic and swiftly expanding company specializing in
              end-to-end engineering solutions, catering to industries in Pharmaceuticals,
              Nutraceuticals and Food through turnkey project consultancy services.
            </p>
            <SiteButton
              title="Learn More"
              onClick={() => {
                if (!param1) {
                  navigate(`/about-us#learn-more`)
                } else {
                  learnMoreRef.current.scrollIntoView()
                }
              }}
            />
          </div>
        </Col>
        <Col
          xs={{ order: 1 }}
          lg={{ span: 7, order: 2 }}
          className="p-0 mb-3 mb-lg-0"
        >
          <img
            src={about_us_showcase}
            alt="about_us_showcase"
            className="img-fluid w-100 px-lg-4 mt-5"
          />
        </Col>
      </Row>
    </section>
  );
};

export default AboutUsSection;
