import { banner_construction, banner_engineering_consultancy, banner_design, banner_product } from "assets";
import PrimaryLine from "components/Site/PrimaryLine";
import SiteButton from "components/Site/SiteButton";
import React from "react";
import { Button, Carousel, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

const images = [
  {
    title: `Precision in Engineering, Excellence in Execution`,
    description: "Your Vision, Our Expertise",
    image: banner_engineering_consultancy,
    buttonTitle: "Explore",
    url: null,
  },
  {
    title: `Technical Services`,
    description: "Technical Brilliance, Seamless Solutions",
    image: banner_design,
    buttonTitle: "Our Services",
    url: "services/architectural-and-civil-work",
  },
  {
    title: `Equipment Sourcing`,
    description: "Maximizing Efficiency, Procurement Expertise",
    image: banner_product,
    buttonTitle: "Explore",
    url: "equipments/rnd/lab-sifter",
  },
  // {
  //   title: ``,
  //   description: "",
  //   image: banner_construction,
  //   buttonTitle: "",
  //   url: "",
  // },
];

export const HomeCarousel = () => {
  return (
    <Carousel data-bs-theme="dark" interval={4000} controls={false}>
      {images.map((item) => (
        <Carousel.Item>
          <img
            className="d-block w-100 img-fluid"
            src={item.image}
            alt="carousel item"
            style={{ height: 522, objectFit: "cover" }}
          />
          <Carousel.Caption>
            {/* <h5>{item.title}</h5>
            <p>{item.description}</p> */}
            <div className="content-wrapper w-100">
              <Col lg={7} className="text-start mb-5">
                <div className="text-white">
                  {/* <p>Word Press</p> */}
                  <h1 className="mb-4 mb-md-2 blur-text-background" style={{ lineHeight: 1.3 }}>
                    {item.title}
                  </h1>
                  <div className="d-none d-md-block mb-4">
                    <PrimaryLine />
                    <p className="blur-text-background">{item.description}</p>
                  </div>
                  {/* <SiteButton title={item.buttonTitle} /> */}
                  <Button
                    as={Link}
                    to={item.url}
                    className="bg-primary border-primary btn-radius px-4"
                    // style={{ height: 52, borderWidth: 1, }}
                    style={{ paddingTop: 12, paddingBottom: 12 }}
                  >
                    <p className="text-white">{item.buttonTitle}</p>
                  </Button>
                </div>
              </Col>
            </div>
          </Carousel.Caption>
        </Carousel.Item>
      ))}
    </Carousel>
  );
};
