import React from "react";

const PrimaryLine = ({ allowMargin = true, className, style }) => {
  return (
    <div
      className={`bg-primary ${allowMargin ? "mb-4" : ""} ${className}`}
      style={{ width: 75, height: 4, ...style }}
    />
    // <hr
    //   className={`bg-primary mb-4 ${className}`}
    //   style={{ width: 75, height: 4, ...style }}
    // />
  );
};

export default PrimaryLine;
