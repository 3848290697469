import PrimaryLine from "components/Site/PrimaryLine";
import React, { useEffect, useRef, useState } from "react";
import { Col, Row, Card, Image, Container } from "react-bootstrap";
import {
  service1,
  service2,
  service3,
  service4,
} from "assets/images/home_service_cards";
import { useMediaQuery } from "react-responsive";
import { about_us_second, visionmission, target } from "assets";
import { ParallaxBanner } from "react-scroll-parallax";

const serviceCardData = [
  { title: "Engineering Consultancy &   Project Management", image: service1 },
  { title: "Technical Services", image: service2 },
  { title: "Equipment Sourcing", image: service3 },
  { title: "Consumables Sourcing", image: service4 },
];

const MoreAboutUsSection = ({ learnMoreRef }) => {
  const small = useMediaQuery({ query: "(min-width: 576px)" });
  const medium = useMediaQuery({ query: "(min-width: 768px)" });
  const large = useMediaQuery({ query: "(min-width: 992px)" });

  return (
    <section className="py-3">
      <Container fluid className="d-flex justify-content-center align-items-center" style={{ minHeight: "70vh" }}>
        <div>
          <ParallaxBanner
            layers={[
              { image: visionmission, speed: -20 },
            ]}
            style={{
              // height: '400px',
              zIndex: 0,
            }}
            className="aspect-[2/1] d-flex flex-column align-items-center"
          >
            <div className="mt-5" style={{ zIndex: 999 }}>
              <h2 className="mb-3 text-center">Our Vision</h2>
              <div className="d-flex justify-content-center">
                <PrimaryLine allowMargin={false} />
              </div>
              <div className="text-center px-5">
                <span className="h1" style={{ color: "#EFC230" }}>" </span>
                <span className="fontSizeAboutUs">
                  To become a trusted global partner in time efficient
                  cost-effective solutions, driving excellence and sustainable
                  growth in pharmaceutical, food, and nutraceutical industries.
                </span>
                <span className="h1" style={{ color: "#EFC230" }}> "</span>
              </div>
            </div>
            <br />
            <div className="my-5" style={{ zIndex: 999 }}>
              <h2 className="mb-3 text-center">Our Mission</h2>
              <div className="d-flex justify-content-center">
                <PrimaryLine allowMargin={false} />
              </div>
              <div className="text-center mt-0">
                <span className="h1" style={{ color: "#EFC230" }}>" </span>
                <span className="fontSizeAboutUs">
                  We are committed to empower excellence and growth through
                  transparent value-based consulting in pharmaceutical, food,
                  and nutraceutical industries.
                </span>
                <span className="h1" style={{ color: "#EFC230" }}> "</span>
              </div>
            </div>
          </ParallaxBanner>
        </div>
      </Container>

      <Card ref={learnMoreRef} className="card-gradient-background border-0 mb-5 border-radius-20">
        <Row lg={2}>
          <Col
            xs={{ order: 2 }}
            lg={{ span: 5, order: 1 }}
            className="px-lg-5 py-3 m-0 d-flex justify-content-center align-items-center"
          >
            <Image
              src={about_us_second}
              alt="About Us"
              className="w-100 border-radius-20 mb-4"
              style={{ maxHeight: "500px", maxWidth: "320px" }}
            />
          </Col>
          <Col
            xs={{ order: 1 }}
            lg={{ span: 7, order: 2 }}
            className="p-5 ps-3"
          >
            <div>
              In a brief timeframe, Proactimo has successfully entered the market,
              establishing a robust presence in Process & Core Engineering. Our
              expertise spans diverse domains such as Architectural, Civil & Structural,
              Mechanical, Electrical, Process Engineering, Automation, and Waste Management.
            </div>
            <br />
            <div>
              What sets us apart is our unique position among a select group of engineering
              consultancy firms providing comprehensive solutions across all disciplines
              and verticals. This distinctive approach allows our clients to discover
              tailored solutions that meet their requirements, all under one roof.
            </div>
            <br />
            <div>
              Recognizing the applicability of our extensive experience to the process
              industry, we have collaboratively ventured into providing design and
              engineering services to process plants. At PPPL, we unite our collective
              expertise to deliver innovative solutions and drive excellence in every project.
            </div>
            <br />
            <div>
              Proactimo Projects Pvt. Ltd. is not merely a service provider; we are
              committed partners, working in tandem to actualize the visions of our
              clients and contribute to the success of their endeavours.
            </div>
          </Col>
        </Row>
      </Card>

      <div className="mt-5">
        <h2 className="mb-3">Commitment / Quality policy</h2>
        <PrimaryLine />
        {/* <ul class="commitment d-flex flex-column justify-content-center">
          <li>This is a list item</li>
          <li>This is another list item</li>
          <li>This is also another list item</li>
        </ul> */}
        <div className="mt-4 d-flex">
          <img src={target} style={{ widht: 23, height: 23 }} />
          <div className="ms-2 d-flex align-items-center">
            At Proactimo, our commitment is to consistently get it
            right the first time, every time, surpassing customer
            expectations in quality, price, delivery, service, solution,
            and technical support.
          </div>
        </div>
        <div className="mt-4 d-flex">
          <img src={target} style={{ widht: 23, height: 23 }} />
          <div className="ms-2 d-flex align-items-center">
            Positioned as an innovative center of excellence, we strive
            to understand and exceed our clients' expectations.
          </div>
        </div>
        <div className="mt-4 d-flex">
          <img src={target} style={{ widht: 23, height: 23 }} />
          <div className="ms-2 d-flex align-items-center">
            Our objective is to design, fabricate, construct, and
            deliver best-in-class quality products and services consistently
            and on time. Complying with all applicable statutory and
            regulatory requirements, we ensure a safe and healthy working
            environment in our pursuit of excellence.
          </div>
        </div>
      </div>

    </section>
  );
};

const styles = {
  blurOverlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    height: '400px',
    zIndex: 0,
  },
};

export default MoreAboutUsSection;
