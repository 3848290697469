import React from "react";
import { Card, Col, Form, Row } from "react-bootstrap";
import { contactInfoData } from "./ContactInfoData";
import PageTitleCover from "components/Site/PageTitleCover";
import { contact_us_cover } from "assets";
import SiteButton from "components/Site/SiteButton";
import ContactUsForm from "sections/ContactUs/ContactUsForm";
import Map from "./Map";

const ContactUs = () => {
  return (
    <div>
      <PageTitleCover
        centeredContent={false}
        backgroundImage={contact_us_cover}
        title="Contact Us"
      // description={description}
      />
      <div className="content-wrapper py-5">
        <Row>
          <Col md={8} lg={{ span: 5 }} className="mx-auto">
            <div className="mb-4">
              {/* <p className="text-primary text-uppercase mb-2">Contact Us</p> */}
              {/* <h3 className="me-3 mb-4 text-primary">Get In Touch</h3> */}
              <div className="d-flex flex-column">
                {contactInfoData?.map((item, i) => {
                  return (
                    <div key={i} className="d-flex flex-column mb-3 border p-3">
                      <div className="d-flex">
                        <div
                          className="d-flex justify-content-center align-items-center bg-primary text-white rounded me-3"
                          style={{ minWidth: 40, height: 40 }}
                        >
                          {item.icon}
                        </div>
                        <div className="">
                          <h5 className="">{item.type}</h5>
                          <p className="d-flex">{item.data}</p>
                        </div>
                      </div>
                      {item.type === "Address" && <Map />}
                    </div>
                  );
                })}
              </div>
            </div>
          </Col>
          <Col md={8} lg={{ span: 6, offset: 1 }} className="mx-auto">
            <h3 className="me-3 mb-4 text-primary">Get In Touch</h3>
            <div className="bg-grey p-3 p-sm-4 px-lg-5 py-lg-5">
              <ContactUsForm />
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default ContactUs;
