import React from "react";
import { Image } from "react-bootstrap";
import DataCard from "components/Site/DataCard";
import DataList from "components/Site/DataList";
import { volumetric_liquid_filling_machines } from "assets/images/equipments";
import BulletPointContainer, { VARIANTS } from "components/Commons/BulletPoint";

const TimeBasedLiquidFillingMachineData = () => {

    const keyFeatures = [
        "Robust steel structure ensures durability and stability.",
        "Drip - free nozzles with a filling range of 100ml to 1000ml, complemented by a drip tray for accidental spills.",
        "Configurations available from 4 to 20 heads, catering to diverse production needs.",
        "No Bottle – No Fill mechanism ensures precision in the filling process.",
        `PLC system featuring a 7" Touch Screen and VFD for seamless conveyor speed control.`,
        "Automatic intake of empty bottles onto the filling station with pneumatic discharge post - filling.",
        "Neck Entry Nozzle system prevents spillage by positioning the nozzle inside the container before filling.",
        "Conveyorized system equipped with a stainless steel slate for hygiene and efficiency.",
        "Optional pneumatically servo - operated Nozzle Up - Down movement and stopper gates for bottle entry and exit.",
        "In - built memory capable of storing 25 recipe data for convenient and quick setup.",
        "21 CFR Part 11 compliance available upon request, ensuring regulatory adherence.",
    ];

    return (
        <div className="text-justify">
            <div>
                The Automatic Volumetric Liquid Filling machines are meticulously designed to automate the filling of bottles or jars with unparalleled precision. With a wide range of applications in industries such as food, pharmaceuticals, and the oil sector, our machines are engineered for optimal efficiency and effectiveness at their designed speed. Experience seamless and accurate liquid filling for enhanced productivity in your operations.
            </div>
            <div className="text-center my-3">
                <Image src={volumetric_liquid_filling_machines} alt="Lab Blender Equipment" style={{ borderRadius: "10px", width: 400 }} />
            </div>
            <BulletPointContainer
                heading={<div className="text-start">{"Key Features"}</div>}
                bulletPointArray={keyFeatures.map(r => ({ description: r }))}
                variant={VARIANTS.BULLETS}
                justify="flex-start"
                lastRowFullWidth={true}
            />
            {/* <DataCard
                title="Key Features"
                component={<DataList dataList={keyFeatures} />}
            /> */}
            <div>
                These salient features highlight the precision, efficiency, and user-friendly design of our Automatic Volumetric Liquid Filling machines, making them an ideal choice for various industries.
            </div>
            < br />
            
        </div>
    );
};

export default TimeBasedLiquidFillingMachineData;
