import React, { useState } from "react";
import { Image } from "react-bootstrap";
import DataCard from "components/Site/DataCard";
import DataList from "components/Site/DataList";
import { bottle_washing_machine, vial_washing_machine, air_jet_cleaning_machine } from "assets/images/equipments";
import BulletPointContainer, { VARIANTS } from "components/Commons/BulletPoint";

const WashingAirJetCleaningMachineData = () => {

    const [active, setActive] = useState(0);

    const vialSalientFeatures = [
        "All contact parts of the machine are made from SS316, ensuring durability and corrosion resistance.",
        "The machine features 18 jets in total for six internal washing zones, providing thorough cleaning.",
        "With 15 cassettes of grippers, each cassette containing a minimum of 2 grippers, the machine offers efficient handling of ampoules and vials.",
        "Ideal for 1ml to 25ml ampoules and 2ml to 100ml vials, providing versatility in container size.",
        "The washing sequence can be easily customized to meet specific requirements.",
        "The machine includes 3 pumps and 3 tanks, each with a capacity of 25 litres, contributing to the washing process.",
        "Every washing zone has an independent circuit to prevent any contamination.",
        "PLC/HMI control with HMI allows for user-friendly and efficient operation.",
        "The machine is enclosed in an aluminum profile cabinet, ensuring a sleek and protective covering.",
    ]

    const airJetKeyFeatures = [
        "Elegant look and compact size with a Stainless Steel structure.",
        "Adjustable height for flexibility in operation.",
        "Multifunctional and multi - featured to cater to various bottle washing needs.",
        "Operates on the linear principle mechanism, ensuring efficient performance.",
        "Comes with standard features such as manual loading and automatic unloading of bottles, enhancing user convenience and productivity.",
    ]

    const airJetSalientFeatures = [
        "Clutch operated star wheel is provided to push the bottle, preventing breakage during the cleaning process.",
        "Stainless Steel pressure gauge for accurate monitoring.",
        "Stationary Stainless Steel nozzles ensure efficient and thorough cleaning.",
        "The machine can be equipped with both positive air pressure and vacuum cleaning options.",
        "Separate control for air pressure, allowing for customization based on specific cleaning requirements.",
        "Highly efficient blower ensures proper and effective cleaning of containers.",
        "Easy operating system, promoting user - friendly operation and maintenance.",
    ]

    const data = [
        {
            title: "Bottle Washing Machine",
            description:
                <div className="text-justify">
                    <div>
                        The Automatic Linear Tunnel Type Bottle/Vial Washing Machine is designed to handle both round and flat-shaped Glass, PET, and Plastic Bottles/Vials, achieving a maximum speed of 250 Bottle/Vials per minute. Widely applicable in pharmaceutical, food, distilleries, and various other industries, this machine ensures thorough cleaning of containers.
                    </div>
                    <div className="text-center my-3">
                        <Image src={bottle_washing_machine} alt="Bottle Washing Machine" style={{ borderRadius: "10px", width: 400 }} />
                    </div>
                    <b>
                        Here's a breakdown of the process:
                    </b>
                    {/* <Image className="d-none d-md-block float-right ms-2" src={bottle_washing_machine} alt="Bottle Washing Machine" style={{ borderRadius: "10px", width: 350 }} /> */}

                    <BulletPointContainer
                        bulletPointArray={[
                            {
                                description: "Bottles/Vials are fed from the Infeed tray and move along a Wire mesh conveyor belt, aligning them correctly with the help of oscillating guide rails"
                            },
                            { description: "An oscillating arm delivers the Bottle/Vial inside a carrier pocket in a mouth-down position, positioning it over the spraying nozzles" },
                            { description: "MultiJet sprayers fixed at the top ensure comprehensive washing of both external and internal surfaces. To prevent cross-contamination, each washing station is equipped with an independent circuit." },
                            { description: "The carrier pocket moves with indexing motion. When the baskets complete their motion, the needles are lifted, penetrating the Bottle/Vials. Spraying jets of water or compressed air are then released before returning down." },
                            { description: "The baskets' movement progresses by one station, allowing each station of Bottle/Vials to undergo the selected washing cycles." },
                            { description: "Washed Bottle/Vials are delivered to the Out-feed tray with the neck opening upward and are automatically reordered for the next operation." }]}
                    />
                    <div>
                        This process guarantees a thorough and efficient washing of containers, meeting the hygiene standards required for pharmaceutical and other industries.
                    </div>
                    <BulletPointContainer
                        heading={<div className="text-start">Features of the Automatic Linear Tunnel Type Bottle/Vial Washing Machine:</div>}
                        bulletPointArray={[{ description: "Machine structure is welded from SS 304; filter housing & nozzles are made from SS 316, ensuring durability and corrosion resistance." },
                        { description: "Oil-free gear motor, main drive, and conveyor contribute to a clean and efficient operation." },
                        { description: "Infeed conveyor belt system (optional) enhances the flexibility of the machine." },
                        { description: "AC Variable Drives for Main drive and conveyor allow for precise control of the washing process." },
                        { description: "Special Pocket system ensures easy and trouble-free washing of Bottles for both outer and inner sides." },
                        { description: "Water recycling arrangement is possible as per the client's requirement (optional), promoting sustainability." },
                        { description: "Special clutch device is available to stop the machine in the event of a bottle overturn or exceeding the diameter." },
                        { description: "External washing of Bottles is integrated into the machine, providing comprehensive cleaning." },
                        { description: "PLC control is available as an optional feature, offering advanced automation and control capabilities." }]}
                    />
                </div>
            ,
        },
        {
            title: "Ampoule and Vial Washing Machine",
            description:
                <div>
                    <div className="text-justify">
                        The High-Speed Automatic Rotary Ampoule and Vial Washing Machine play a vital role in industries such as Pharmaceutical, Biotech, and Veterinary. The machine is designed to wash Ampoules and Vials with minimal direct contact with the container's surface. Its customized gripper system securely holds the container from its neck, maintaining an inverted position throughout the washing process. Once the washing process is completed, the machine releases the container with the help of interchangeable parts, ensuring efficient and contamination-free cleaning.
                    </div>
                    <div className="text-center my-3">
                        <Image className="w-50" src={vial_washing_machine} alt="Bottle Washing Machine" style={{ borderRadius: "10px", width: 300 }} />
                    </div>
                    <b></b>
                    {/* <DataCard
                        title=""
                        component={<DataList dataList={vialSalientFeatures} />}
                    /> */}
                    <BulletPointContainer
                        heading={'Salient Features of the High-Speed Automatic Rotary Ampoule and Vial Washing Machine:'}
                        variant={VARIANTS.BULLETS}
                        bulletPointArray={vialSalientFeatures.map(r => ({description: r}))}
                    />
                </div>
            ,
        },
        {
            title: "Air Jet Cleaning Machine",
            description:
                <div>
                    <div className="text-justify">
                        The Automatic Inverted Vial Air Jet Cleaning Machine is an efficient and aesthetically pleasing device designed with a compact Stainless Steel structure and adjustable height. This multifunctional machine is equipped to meet the washing requirements for both glass and plastic bottles. Operating on the linear principle mechanism, it is designed for user convenience.
                    </div>
                    <div className="text-center my-3">
                        <Image className="w-50" src={air_jet_cleaning_machine} alt="Bottle Washing Machine" style={{ borderRadius: "10px" }} />
                    </div>
                    <b></b>
                    <BulletPointContainer
                        heading={<div className="text-start">Key Features:</div>}
                        bulletPointArray={airJetKeyFeatures.map(r => ({description: r}))}
                        variant={VARIANTS.BULLETS}
                        numberOfColumns={2}
                        lastRowFullWidth={true}
                    />
                    {/* <DataCard
                        title=""
                        component={<DataList dataList={airJetKeyFeatures} />}
                    /> */}
                    <b></b>
                    {/* <DataCard
                        title=""
                        component={<DataList dataList={airJetSalientFeatures} />}
                    /> */}
                    <BulletPointContainer
                        heading={<div className="text-start">Salient Features of the Automatic Inverted Vial Air Jet Cleaning Machine:</div>}
                        bulletPointArray={airJetSalientFeatures.map(r => ({description: r}))}
                        variant={VARIANTS.BULLETS}
                        numberOfColumns={2}
                        lastRowFullWidth={true}
                    />
                    
                </div>
            ,
        },
    ]

    return (
        <>
            <div className="d-flex align-items-center py-1 overflow-scroll scrollbar-none">
                {data.map((tab, i) => {
                    return (
                        <div
                            className={`d-flex align-items-center px-2 px-sm-3 px-lg-4 pb-2 text-center ${active === i ? "text-primary active-tab px-sm-" : ""
                                }`}
                            style={{ minWidth: 120, alignSelf: "stretch" }}
                            onClick={() => setActive(i)}
                        >
                            <p>{tab.title}</p>
                        </div>
                    )
                })}
            </div>
            <div className="d-flex flex-column py-2">
                {data[active]?.description}
            </div>
        </>
    );
};

export default WashingAirJetCleaningMachineData;
