import { error_404 } from "assets";
import SiteButton from "components/Site/SiteButton";
import React from "react";
import { useNavigate } from "react-router-dom";

const PageNotFound = () => {
  const navigate = useNavigate();
  return (
    <section
      className="d-flex flex-column justify-content-center align-items-center py-5"
      style={{ minHeight: "80vh" }}
    >
      <img
        src={error_404}
        alt="Page_not_found"
        className="img-fluid p-3 mb-3"
      />
      <h4 className="text-danger mb-4">Page not found</h4>
      {/* <a href="/" className="text-underlined">Go to home</a> */}
      <SiteButton title="Go to Home" onClick={() => navigate("")} />
    </section>
  );
};

export default PageNotFound;
