import React from "react";

const TechnologyTransferData = () => {
    return (
        <div className="text-justify">
            {/* <Image src="" alt="" /> */}
            <div>
                Technology transfer and scale-up are intricate processes that demand a deep
                understanding of the production line, whether it involves upstream, downstream,
                or small molecule production. At the core of these endeavors lies the crucial
                task of translating detailed process knowledge into a functional production line,
                especially when transitioning from lab or pilot scale to high-volume commercial
                production on a new site.
            </div>
            <br />
            <div>
                Proactimo recognizes the unique engineering challenges posed by such endeavors.
                Our expertise is particularly valuable in bridging the gap between innovative
                technology providers and large-scale international manufacturers and sellers.
                Positioned at the intersection of cutting-edge technologies and a global demand
                for advancements, we aim to bring substantial value to both technology providers
                and those seeking to implement these technologies on a larger scale. Our goal is
                to facilitate seamless technology transfer and scale-up processes, contributing
                to the successful integration of innovative solutions into large-scale production
                environments.
            </div>
        </div>
    );
};

export default TechnologyTransferData;
