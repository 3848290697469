import React from "react";

const DataCard = ({ title, description, img, imgOnTop, component }) => {
  return (
    <>
      {title && <h5 className="mb-2 my-4">{title}</h5>}
      {description &&
        typeof description === "object" &&
        React.isValidElement(description) ? (
        <div className="mb-2" style={{ order: imgOnTop ? 2 : 1 }}>
          {description}
        </div>
      ) : (
        <p className="mb-2" style={{ order: imgOnTop ? 2 : 1 }}>
          {description}
        </p>
      )}
      {img && (
        <img
          src={img}
          alt={img}
          className={`img-fluid w-100 ${imgOnTop ? "mb-2" : "mb-3"}`}
          style={{ order: imgOnTop ? 1 : 2 }}
        />
      )}
      {component && <div className="mt-2">{component}</div>}
    </ >
  );
};

export default DataCard;
