import React from "react";
import { Image } from "react-bootstrap";
import DataCard from "components/Site/DataCard";
import DataList from "components/Site/DataList";
import { granulation_introduction } from "assets/images/equipments";
import BulletPointContainer, { VARIANTS } from "components/Commons/BulletPoint";

const IntroductionData = () => {

    const keyFeatures = [
        "Optimized bowl capacity utilization for enhanced efficiency.",
        "Ensures leak-proof operation with effective sealing.",
        "Equipped with PLC and HMI-based control for seamless operation.",
        "Variable Frequency Drive (VFD) at both Impeller and Chopper for precise control.",
        "Pneumatically operated Top Lid for user-friendly access.",
        "Effortless product discharge through Pneumatically operated system.",
        "Vacuum-rated bowl design facilitates material charging via Vacuum Transfer System.",
        "Safety ensured with sensors at Top Lid and guard.",
        "3 Blade Impeller and 4 Blade Chopper design for superior granule formation.",
        "Air purging at both impeller and chopper shaft for cleanliness.",
        "Endpoint indication provided by Ampere measurement.",
        "21 CFR Part 11 compliance available on request for regulatory adherence.",
    ];

    const optionalEnhancements = [
        "Wet Co-mill at discharge with VFD, enabling efficient wet milling.",
        "Flame Proof Construction for enhanced safety in specific environments.",
        "Integration of Spray Ball Assembly for ease of Wet In-Place (WIP) cleaning.",
        "Swivel-type top lid design for convenient access and operation.",
        "Automatic Binder Addition System for precise and automated binder incorporation.",
        "SCADA connectivity for seamless monitoring and control.",
        "Closed integrated transfer system for airtight material transfer.",
        "Recipe Management System for systematic and automated recipe handling.",
    ];

    const technicalSpecifications = [
        "Enclosed drive transmission system ensures operational reliability.",
        "Lid interlock system prevents machine start if the lid is not closed; during operation, the machine stops if the lid is opened.",
        "Low air pressure alarm feature halts the machine for enhanced safety.",
        "Discharge interlocking guarantees the presence of a product container underneath.",
        "Overload relay protection and emergency stop button for added safety measures.",
        "Working volume is contingent on material characteristics and product bulk density.",
        "The company reserves the right to modify technical specifications to enhance performance and innovation.",
    ]

    return (
        <div>
            <div className="text-justify">
                <Image className="d-none d-md-block ms-2 float-right" src={granulation_introduction} alt="Lab Blender Equipment" style={{ borderRadius: "10px", width: 350 }} />
                The unparalleled capabilities of our Rapid Mixer Granulator (RMG) are
                ideal for processes requiring seamless mixing, precise granule size control,
                and significant time savings. The dynamic interplay of Mixing Blades and
                Chopper Blades, meticulously designed with the right profiles and speeds,
                guarantees uniformly mixed granules of active ingredients and excipients,
                seamlessly integrated with binders. Achieve precise dose distribution effortlessly
                through an automated binder addition system, paired with optimal speed
                configurations for the Mixing and Chopper Blades. <b>The result?</b>&nbsp;
                <i>
                    Compact, high-density granules that redefine efficiency in
                    pharmaceutical mixing and granulation. Experience the future of
                    pharmaceutical processing with our cutting-edge RMG technology.
                </i>
            </div>
            {/* <DataCard
                title="Key Features"
                component={<DataList dataList={keyFeatures} />}
            /> */}
            <BulletPointContainer
                heading={<div className="text-start">Key Features</div>}
                justify="flex-start"
                variant={VARIANTS.BULLETS}
                bulletPointArray={keyFeatures.map(r => ({description: r}))}
            />
            <div>
                Experience a blend of advanced technology and safety features with our
                state-of-the-art system.
            </div>

            <div className="text-center d-md-none">
                <Image className="w-50" src={granulation_introduction} alt="Lab Blender Equipment" style={{ borderRadius: "10px" }} />
            </div>

            {/* <DataCard
                title="Optional Enhancements"
                component={<DataList dataList={optionalEnhancements} />}
            /> */}
            <BulletPointContainer
                heading={<div className="text-start">Optional Enhancements</div>}
                variant={VARIANTS.BULLETS}
                bulletPointArray={optionalEnhancements.map(r => ({description: r}))}
                lastRowFullWidth={true}
                justify="flex-start"
            />
            <div>
                Elevate your processing capabilities with our optional features, designed
                to provide versatility, safety, and automation to meet your unique requirements.
            </div>

            {/* <DataCard
                title="Technical Specifications"
                component={<DataList dataList={technicalSpecifications} />}
            /> */}
            <BulletPointContainer
                heading={<div className="text-start">Technical Specifications</div>}
                variant={VARIANTS.BULLETS}
                justify="flex-start"
                bulletPointArray={technicalSpecifications.map(r => ({description: r}))}
                lastRowFullWidth={true}
            />
            <div>
                Rest assured, our machine is equipped with robust safety features and
                adaptable specifications to meet your evolving needs.
            </div>

        </div>
    );
};

export default IntroductionData;
