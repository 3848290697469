import React from "react";
import { Image } from "react-bootstrap";
import DataCard from "components/Site/DataCard";
import DataList from "components/Site/DataList";
import { linear_screw_capping_machine } from "assets/images/equipments";
import BulletPointContainer, { VARIANTS } from "components/Commons/BulletPoint";

const LinearScrewCappingMachineData = () => {

    const keyFeatures = [
        "Elegantly Matte Finished Stainless Steel Body: A sleek and sophisticated matte finish adds aesthetic appeal to the machine's stainless steel body.",
        "No Container – No Cap Arrangement: Ensures that the capping process is contingent upon the presence of a container, enhancing operational efficiency.",
        "Adjustable Conveyor Belt Height: Offers manual adjustment of the conveyor belt height, allowing seamless alignment with other machines in the production line.",
        "Variable Sealing Pressure: Allows for the variation of sealing pressure to accommodate different cap gauges and sizes, ensuring optimal capping results.",
        "Specially Designed Hopper: Features a specially designed hopper to enhance the storage capacity of the cap filling bowl, improving overall efficiency.",
        "Low Noise Level, Low Power Consumption: Operates with minimal noise and consumes low power, contributing to a quieter and more energy - efficient work environment.",
        "Self - Lubricating Guide Profile: Utilizes self - lubricating UHMW - PE guide profiles for a low - friction wear surface, ensuring smooth and noiseless conveying.",
        "Drain Tray: Incorporates a drain tray around the machine platform for easy cleanup and maintenance.",
        "Synchronized A / C Drives: Employs synchronized A / C drives to coordinate the movement of the conveyor, star wheel, platform turret, and capping bowl for seamless operation.",
        "Universal Coupling: Features a universal coupling for quick and easy setting of the in -feed worm, streamlining the setup process.",
        "Adjustable Bottle Height Gauge: Provides an adjustable bottle height gauge for effortless and quick setup, enhancing user convenience.",
        "Motorized Height Adjustment System(Optional): Offers an optional motorized height adjustment system for added convenience in adjusting machine height.",
        "Acrylic Cabinet(Optional): Optional acrylic cabinet for increased visibility and an additional layer of aesthetic appeal.",
    ];

    return (
        <div className="text-justify">
            <div>
                <Image className="w-50 d-none d-md-block ms-2" src={linear_screw_capping_machine} alt="Lab Blender Equipment" style={{ borderRadius: "10px", float: "right" }} />
                <div>
                    <div>
                        Automatic Linear Screw Capping Machine is an efficient and versatile solution for high-speed capping of various bottle shapes. The inclusion of a cap elevator to unscramble and feed caps adds to the efficiency of the capping process.
                    </div>
                    <br />
                    <div>
                        The linear capping unit, where feed containers move on a conveyor belt and align directly with the sealing heads, reflects a streamlined approach to the capping operation. The synchronized movement ensures that the bottle picks up a cap from the delivery chute of the cap filling bowl. This is particularly advantageous as the body and neck of the container are positioned in line with the rotating head during the sealing process.
                    </div>
                    <br />
                    <div>
                        The precision in torque screwing provided by the sealing head showcases the machine's capability to perform a perfect capping operation. Overall, this machine seems to offer a reliable and efficient solution for high-speed screw capping across a variety of bottle shapes.
                    </div>
                </div>
            </div>
            {/* <DataCard
                title="Key Features"
                component={<DataList dataList={keyFeatures} />}
            /> */}
            <BulletPointContainer
                heading={<div className="text-start">{"Key Features"}</div>}
                bulletPointArray={keyFeatures.map(r => ({ description: r }))}
                variant={VARIANTS.BULLETS}
                numberOfColumns={2}
                justify="flex-start"
                lastRowFullWidth={true}
            />
            <div className="text-center">
            <Image className="w-50 d-md-none" src={linear_screw_capping_machine} alt="Lab Blender Equipment" style={{ borderRadius: "10px" }} />
            </div>
        </div>
    );
};

export default LinearScrewCappingMachineData;
