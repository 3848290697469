import React, { useState } from "react";
import { Image } from "react-bootstrap";
import DataCard from "components/Site/DataCard";
import DataList from "components/Site/DataList";
import CustomTabs from "components/Site/CustomTabs";
import { conta_blender, double_cone_blender, octagonal_blender, ribbon_blender, v_blender, } from "assets/images/equipments";
import BulletPointContainer, { VARIANTS } from "components/Commons/BulletPoint";

const BlenderData = () => {

    const [active, setActive] = useState(0);

    const contaKeyFeatures = [
        "Outperforms other tumbling - type blenders in mixing efficiency.",
        "Flexible handling of different batch volumes with various bin sizes.",
        "The working area is secured by a railing with a bin entry gate.",
        "Bin entry gate and bin position interlock to prevent accidents.",
        "Convenient home position for easy loading and unloading of bins.",
        "Built -in butterfly valve for effortless discharge.",
        "PLC - based operation with optional color HMI for user - friendly control.",
    ];

    const doubleConeKeyFeatures = [
        "Conical shape at both ends ensures uniform mixing and easy discharge.",
        "Statically balanced cone safeguards the gearbox and motor from excessive loads.",
        "Wide opening for powder loading and a butterfly valve for convenient discharge.",
        "Optional paddle-type baffles on the shaft for enhanced mixing, uniform blending, and de-agglomeration.",
        "Flame-proof electrical components available as an option.",
        "Optional Variable Frequency Drive for speed control.",
    ]

    const octagonalKeyFeatures = [
        "Effective blending facilitated by mixing baffles.",
        "Quick cleaning of mixing baffles for enhanced efficiency.",
        "Easy and rapid cleaning of the product shell.",
        "Optional programmable logic controller (PLC) for advanced control.",
        "Optional flameproof construction for added safety.",
    ]

    const ribbonKeyFeatures = [
        "Available in two shapes - U & W.",
        "Monoblock design to prevent cross-contamination.",
        "Bearing mounted on lanterns outside the mixing zone.",
        "Air purge on side entry seals for enhanced sealing.",
        "Continuous ribbon design ensures complete discharge.",
        "Variable frequency drive for precise shear energy control.",
        "Belt-driven power transmission.",
        "Top-mounted charging port and side discharge arrangement reduce material handling.",
        "Optional paddle-style agitator for blending fragile materials.",
        "Optional lance-type injectors for liquid spraying.",
    ]

    const vKeyFeatures = [
        "Easy to clean with minimal maintenance requirements.",
        "Integrated Vacuum loading option for added convenience.",
        "Bin charging system can be incorporated for a dust - free closed environment during charging and discharging.",
        "Ideal for blending dry powders and granulated products.",
        "The V - blender's shape ensures near-complete discharge of product material.",
        "Absence of shaft projection eliminates the risk of product contamination.",
    ]

    const data = [
        {
            title: "Conta Blender",
            description: "The Conta Blender is your go-to solution for effective and uniform mixing, lubricating, and blending of dry powder and granules, ensuring a homogeneous blend that enhances the flowability of materials for tablet and capsule production. This blender shuffles the product thoroughly, resulting in a seamlessly blended mixture.",
            firstSection: contaKeyFeatures,
            secondSection: "Experience superior mixing capabilities and operational convenience with the Conta Blender, designed to elevate the blending process in pharmaceutical production.",
            image: conta_blender,
        },
        {
            title: "Double Cone Blenders",
            description: "Double Cone Blenders stand out as the ultimate equipment for achieving homogeneous blending of powders, serving diverse industries such as chemical, food, and pharmaceutical. With capacities ranging from 50 to 1500 liters, variable rotational speeds, and adaptable materials of construction, there's a Powder Blender tailored to suit every application. This efficient and versatile closed double cone blender excels in granular and dry powder blending, featuring either Direct or Variable Speed Drives that provide a low shear product mixing environment.",
            firstSection: doubleConeKeyFeatures,
            secondSection: "Experience the precision and flexibility of Double Cone Blenders, designed to meet the diverse blending needs of various industries.",
            image: double_cone_blender,
        },
        {
            title: "Octagonal Blender",
            description: "The Octagonal Blender is your solution for efficient and uniform mixing, as well as lubricating dry powders and granules. The resulting blend, with its improved flowability, is ideal for tabletting or capsulation processes. This blender ensures an intimate shuffle of the product, achieving a homogeneous blend.",
            firstSection: octagonalKeyFeatures,
            secondSection: "Experience the ease of blending and cleaning with the Octagonal Blender, designed to streamline your mixing and lubrication processes in pharmaceutical and related applications.",
            image: octagonal_blender,
        },
        {
            title: "Ribbon Blender",
            description: "The Ribbon Blender, a compact and light-duty mixer, is engineered to achieve a convection mechanism for blending pre-processed powder components like dried granules and pre-sieved powders. This low-shear mixer is an ideal choice for solid-solid mixing, and it can also handle solid-liquid mixing when high shearing force is not required.",
            firstSection: ribbonKeyFeatures,
            secondSection: "Experience efficient and versatile blending with the Ribbon Blender, designed for a range of applications in the processing of powders and granules.",
            image: ribbon_blender,
        },
        {
            title: "V Blender",
            description: "The Rotary, tumble-type V Blender, also known as the Y Blender, stands out with its innovative design featuring manholes for convenient product loading. The cylindrical 'V' curve not only allows easy access to internal areas but also facilitates better cleaning. The distinctive 'V' shape promotes higher shear mixing without attrition and particle regeneration, resulting in shorter mixing and blending times. This makes it particularly suitable for fragile granules where precise control over fines percentages is essential. Enjoy efficient and controlled blending with this thoughtfully designed V Blender.",
            firstSection: vKeyFeatures,
            secondSection: "Experience hassle-free maintenance, efficient blending, and a contamination-free environment with the salient features of the V Blender, designed to meet the stringent demands of modern processing.",
            image: v_blender,
        },
    ]

    return (
        <>
            <div className="p-2">
                <div className="d-flex align-items-center py-4 overflow-scroll scrollbar-none">
                    {data.map((tab, i) => {
                        return (
                            <div
                                className={`d-flex align-items-center px-2 px-sm-3 px-lg-4 pb-2 text-center ${active === i ? "text-primary active-tab px-sm-" : ""
                                    }`}
                                style={{ minWidth: 120, alignSelf: "stretch" }}
                                onClick={() => setActive(i)}
                            >
                                <p>{tab.title}</p>
                            </div>
                        )
                    })}
                </div>
                <div >
                    <span className="text-justify">{data[active]?.description}</span>
                    <div className="text-center my-3">
                        <Image src={data[active]?.image} alt={data[active]?.title} style={{ borderRadius: "10px", width: 300 }} />
                    </div>

                    {/* <DataCard
                        title="Silent Features:"
                        component={<DataList dataList={data[active]?.firstSection} />}
                    /> */}
                    <BulletPointContainer
                        heading={<div className="text-start">Silent Features</div>}
                        justify="flex-start"
                        variant={VARIANTS.BULLETS}
                        numberOfColumns={3}
                        lastRowFullWidth={true}
                        bulletPointArray={data[active]?.firstSection.map(r => ({ description: r }))}
                    />
                    <div>{data[active]?.secondSection}</div>
                    <br />
                </div>
            </div>
            <div className="text-center d-md-none">
                <Image className="w-75" src={data[active]?.image} alt={data[active]?.title} style={{ borderRadius: "10px" }} />
            </div>
        </>
    );
};

export default BlenderData;
