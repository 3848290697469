// ---------- engineering consultancy ----------
import {
  basic_engineering_cover,
  conceptual_design_cover,
  detail_engineering_cover,
  feasibility_report_cover,
} from "assets/images/engineering_consultancy";

// ---------- equipments ----------
import {
  ampoule_filling_cover,
  blister_packing_cover,
  bottle_filling_cover,
  capsule_filling_cover,
  cartoning_cover,
  spare_parts_cover,
  de_blistering_cover,
  end_of_line_solution_cover,
  eye_drops_cover,
  granulation_cover,
  labelling_cover,
  prefilled_syringe_cover,
  strip_packing_cover,
  tablet_coating_cover,
  tabletting_cover,
  tube_filling_cover,
  vial_filling_cover,
} from "assets/images/equipments";

// ---------- services ----------
import {
  architectural_and_civil_work_cover,
  clean_room_cover,
  ele_and_mech_work_cover,
  hvac_systems_cover,
  installation_and_commissioning_cover,
  it_services_cover,
  project_management_cover,
  utilities_cover,
  water_systems_cover,
} from "assets/images/services";

// ---------- Services Data ----------
import ArchitecturalAndCivilData from "dataComponents/Services/ArchitecturalAndCivilData";
import HVACSystemsData from "dataComponents/Services/HVACSystemsData";
import CleanRoomData from "dataComponents/Services/CleanRoomData";
import ElectricalMechanicalWorkData from "dataComponents/Services/ElectricalMechanicalWorkData";
import WaterSystemData from "dataComponents/Services/WaterSystemData";
import UtilitiesData from "dataComponents/Services/UtilitiesData";
import DocumentationData from "dataComponents/Services/DocumentationData";
import TechnologyTransferData from "dataComponents/Services/TechnologyTransferData";
import ProjectManagementData from "dataComponents/Services/ProjectManagementData";

// ---------- Equipments - R&D - Data ----------
import LabSifterData from "dataComponents/Equipments/rnd/LabSifterData";
import LabMixerData from "dataComponents/Equipments/rnd/LabMixerData";
import LabFluidData from "dataComponents/Equipments/rnd/LabFluidData";
import LabMultiMillData from "dataComponents/Equipments/rnd/LabMultiMillData";
import LabBlenderData from "dataComponents/Equipments/rnd/LabBlenderData";
import LabCoaterData from "dataComponents/Equipments/rnd/LabCoaterData";

// ---------- Equipments - Granulation - Data ----------
import IntroductionData from "dataComponents/Equipments/granulation/IntroductionData";
import BlenderData from "dataComponents/Equipments/granulation/BlenderData";
import TabletCoaterData from "dataComponents/Equipments/granulation/TabletCoaterData";
import RollerCompacterData from "dataComponents/Equipments/granulation/RollerCompacterData";
import VibroSifterData from "dataComponents/Equipments/granulation/VibroSifterData";
import FluidBedProcessorData from "dataComponents/Equipments/granulation/FluidBedProcessorData";

// ---------- Equipments - Liquid Manufacturing - Data ----------
import LiquidManufacturingPlantData from "dataComponents/Equipments/liquid_manufacturing/LiquidManufacturingPlantData";
import OintmentManufacturingPlantsData from "dataComponents/Equipments/liquid_manufacturing/OintmentManufacturingPlantsData";
import WashingAirJetCleaningMachineData from "dataComponents/Equipments/liquid_manufacturing/WashingAirJetCleaningMachineData";
import PowderFillingData from "dataComponents/Equipments/liquid_manufacturing/PowderFillingData";

// ---------- Equipments - Liquid Filling - Data ----------
import VolumetricLiquidFillingMachinesData from "dataComponents/Equipments/liquid_filling/VolumetricLiquidFillingMachinesData";
import GearPumpLiquidFillingMachineData from "dataComponents/Equipments/liquid_filling/GearPumpLiquidFillingMachineData";
import RinsingFillingAndCappingMachineData from "dataComponents/Equipments/liquid_filling/RinsingFillingAndCappingMachineData";
import TimeBasedLiquidFillingMachineData from "dataComponents/Equipments/liquid_filling/TimeBasedLiquidFillingMachineData";
import WeighMetricOilFillingMachineData from "dataComponents/Equipments/liquid_filling/WeighMetricOilFillingMachineData";
import ViscousPneumaticPistonFillingMachineData from "dataComponents/Equipments/liquid_filling/ViscousPneumaticPistonFillingMachineData";
import VolumetricLiquidVialFillingwithRubberStopperingMachineData from "dataComponents/Equipments/liquid_filling/VolumetricLiquidVialFillingwithRubberStopperingMachineData";
import SingleHeadTubeFillingMachineData from "dataComponents/Equipments/liquid_filling/SingleHeadTubeFillingMachineData";
import DoubleHeadTubeFillingMachineData from "dataComponents/Equipments/liquid_filling/DoubleHeadTubeFillingMachineData";
import SemiAutomaticTubeFillingMachineData from "dataComponents/Equipments/liquid_filling/SemiAutomaticTubeFillingMachineData";

// ---------- Equipments - Capping - Data ----------
import VialCappingMachineData from "dataComponents/Equipments/capping/VialCappingMachineData";
import LinearScrewCappingMachineData from "dataComponents/Equipments/capping/LinearScrewCappingMachineData";
import ScrewCappingMachineData from "dataComponents/Equipments/capping/ScrewCappingMachineData";

// ---------- Equipments - Labeling - Data ----------
import AutomaticRoundBottleLabelingMachineData from "dataComponents/Equipments/labeling/AutomaticRoundBottleLabelingMachineData";
import AutomaticSingleSideStickerLabellingMachineData from "dataComponents/Equipments/labeling/AutomaticSingleSideStickerLabellingMachineData";
import AutomaticDoubleSideStickerLabellingMachineData from "dataComponents/Equipments/labeling/AutomaticDoubleSideStickerLabellingMachineData";

import BlisterPackingData from "dataComponents/Equipments/oral_solid_dosage/BlisterPacking";
import CapsuleFillingData from "dataComponents/Equipments/oral_solid_dosage/CapsuleFilling";
import GranulationData from "dataComponents/Equipments/oral_solid_dosage/Granulation";
import LabellingData from "dataComponents/Equipments/oral_solid_dosage/Labelling";
import StripPackingData from "dataComponents/Equipments/oral_solid_dosage/StripPacking";
import TabletCoatingData from "dataComponents/Equipments/oral_solid_dosage/TabletCoating";
import TablettingData from "dataComponents/Equipments/oral_solid_dosage/Tabletting";
import TubeFillingData from "dataComponents/Equipments/semi_solid/TubeFilling";
import About from "pages/About/About";
import ContactUs from "pages/ContactUs/ContactUs";
import Home from "pages/Home/Home";
import OurClients from "pages/Home/OurClients";
import Blogs from "pages/Blogs/Blogs";
import Media from "pages/Media/Media";
import { Image } from "react-bootstrap";

const { blogs_cover, about_us_cover, contact_us_cover } = require("assets");

export const siteNavigation = [
  // ==================== HOME ====================
  { title: "Home", identifier: "home", path: "", component: <Home /> },

  // ==================== ABOUT US ====================
  {
    title: "About Us",
    // subtitle: "",
    identifier: "about_us",
    path: "about-us",
    cover_image: about_us_cover,
    component: <About />,
  },

  // ==================== ENGINEERING CONSULTANCY ====================
  // {
  //   title: "Engineering Consultancy",
  //   identifier: "engg_consultancy",
  //   path: "engineering-consultancy",
  //   cover_image: "",
  //   subroutes: [
  //     {
  //       title: "Feasibility Report & Gap Analysis",
  //       identifier: "feasibility_report_and_gap_analysis",
  //       path: "feasibility-report-and-gap-analysis",
  //       cover_image: feasibility_report_cover,
  //       dataComponent: "feasibility_report_and_gap_analysis",
  //     },
  //     {
  //       title: "Conceptual Design",
  //       identifier: "conceptual_design",
  //       path: "conceptual-design",
  //       cover_image: conceptual_design_cover,
  //       dataComponent: "conceptual_design",
  //     },
  //     {
  //       title: "Basic Engineering",
  //       identifier: "basic_engg",
  //       path: "basic-engineering",
  //       cover_image: basic_engineering_cover,
  //       dataComponent: "basic_engg",
  //     },
  //     {
  //       title: "Detail Engineering",
  //       identifier: "detail_engg",
  //       path: "detail-engineering",
  //       cover_image: detail_engineering_cover,
  //       dataComponent: "detail_engg",
  //     },
  //   ],
  // },

  // ==================== SERVICES ====================
  {
    title: "Services",
    cover_image: "",
    identifier: "services",
    path: "services",
    subroutes: [
      {
        title: "Architectural & Civil Work",
        identifier: "architectural_and_civil_work",
        path: "architectural-and-civil-work",
        cover_image: architectural_and_civil_work_cover,
        dataComponent: <ArchitecturalAndCivilData />,
      },
      {
        title: "HVAC Systems",
        identifier: "hvac_systems",
        path: "hvac-systems",
        cover_image: hvac_systems_cover,
        dataComponent: <HVACSystemsData />,
      },
      {
        title: "Clean Room - Partition & Flooring",
        identifier: "clean_room",
        path: "clean-room",
        cover_image: clean_room_cover,
        dataComponent: <CleanRoomData />,
      },
      {
        title: "Electrical & Mechanical Work",
        identifier: "electrical_and_mechanical_work",
        path: "electrical-and-mechanical-work",
        cover_image: ele_and_mech_work_cover,
        dataComponent: <ElectricalMechanicalWorkData />,
      },
      {
        title: "Water System",
        identifier: "water_systems",
        path: "water-systems",
        cover_image: water_systems_cover,
        dataComponent: <WaterSystemData />,
      },
      {
        title: "Utilities",
        identifier: "utitilities",
        path: "utitilities",
        cover_image: utilities_cover,
        dataComponent: <UtilitiesData />,
      },
      {
        title: "Installation, Commissioning, Documentation & Validation",
        identifier: "installation_commissioning_documentation_and_validation",
        path: "installation-commissioning-documentation-and-validation",
        cover_image: installation_and_commissioning_cover,
        dataComponent: <DocumentationData />,
      },
      {
        title: "Technology Transfer",
        identifier: "technology_transfer",
        path: "technology-transfer",
        cover_image: project_management_cover,
        dataComponent: <TechnologyTransferData />,
      },
      {
        title: "Project Management",
        identifier: "project_management",
        path: "project-management",
        cover_image: project_management_cover,
        dataComponent: <ProjectManagementData />,
      },
      // {
      //   title: "IT Services (Mfg & CRM)",
      //   identifier: "it_services",
      //   path: "it-services",
      //   cover_image: it_services_cover,
      //   dataComponent:
      //     <>
      //       <div>
      //         IT Services (Mfg & CRM)
      //       </div>
      //     </>
      //   ,
      // },
    ],
  },

  // ==================== EQUIPMENTS ====================
  {
    title: "Equipment",
    identifier: "equipments",
    path: "equipments",
    nopage: true,
    subroutes: [
      {
        title: "R&D",
        identifier: "rnd",
        path: "rnd",
        subroutes: [
          {
            title: "Lab Sifter",
            identifier: "lab_sifter",
            path: "lab-sifter",
            dataComponent: <LabSifterData />,
          },
          {
            title: "Lab Mixer (RMG)",
            identifier: "lab_mixer",
            path: "lab-mixer",
            dataComponent: <LabMixerData />,
          },
          {
            title: "Lab FBD/FBP",
            identifier: "lab_fluid",
            path: "lab-fluid",
            dataComponent: <LabFluidData />,
          },
          {
            title: "Lab Multi Mill : Milling Redefined",
            identifier: "lab_multi_mill",
            path: "lab-multi-mill",
            dataComponent: <LabMultiMillData />,
          },
          {
            title: "Lab Blender : Precision in Mixing and Blending",
            identifier: "lab_blender",
            path: "lab-blender",
            dataComponent: <LabBlenderData />,
          },
          {
            title: "Lab Coater: Precision Film Coating for Excellence",
            identifier: "lab_coater",
            path: "lab-coater",
            dataComponent: <LabCoaterData />,
          },
        ],
      },
      {
        title: "Granulation",
        identifier: "granulation",
        path: "granulation",
        subroutes: [
          {
            title: "Rapid Mixer Granulator",
            identifier: "rapid_mixer_granulator",
            path: "rapid-mixer-granulator",
            cover_image: granulation_cover,
            dataComponent: <IntroductionData />,
          },
          {
            title: "Fluid Bed Processor",
            identifier: "fluid_bed_processor",
            path: "fluid-bed-processor",
            cover_image: granulation_cover,
            dataComponent: <FluidBedProcessorData />,
          },
          {
            title: "Blender",
            identifier: "blender",
            path: "blender",
            cover_image: granulation_cover,
            dataComponent: <BlenderData />,
          },
          {
            title: "Tablet Coater",
            identifier: "tablet_coater",
            path: "tablet-coater",
            cover_image: granulation_cover,
            dataComponent: <TabletCoaterData />,
          },
          {
            title: "Roller Compacter",
            identifier: "roller_compacter",
            path: "roller-compacter",
            cover_image: granulation_cover,
            dataComponent: <RollerCompacterData />,
          },
          {
            title: "Vibro Sifter",
            identifier: "vibro_sifter",
            path: "vibro-sifter",
            cover_image: granulation_cover,
            dataComponent: <VibroSifterData />,
          },
        ],
      },
      // {
      //   title: "Oral Solid Dosage",
      //   identifier: "oral_solid_dosage",
      //   path: "oral-solid-dosage",
      //   subroutes: [
      //     {
      //       title: "Tabletting",
      //       identifier: "tabletting",
      //       path: "tabletting",
      //       cover_image: tabletting_cover,
      //       dataComponent: <TablettingData />,
      //     },
      //     {
      //       title: "Tablet Coating",
      //       identifier: "tablet_coating",
      //       path: "tablet-coating",
      //       cover_image: tablet_coating_cover,
      //       dataComponent: <TabletCoatingData />,
      //     },
      //     {
      //       title: "Capsule Filling",
      //       identifier: "capsule_filling",
      //       path: "capsule-filling",
      //       cover_image: capsule_filling_cover,
      //       dataComponent: <CapsuleFillingData />,
      //     },
      //     {
      //       title: "Blister packing & De-Blistering",
      //       identifier: "blister_packing",
      //       path: "blister-packing",
      //       cover_image: blister_packing_cover,
      //       dataComponent: <BlisterPackingData />,
      //     },
      //     {
      //       title: "Strip Packing",
      //       identifier: "strip_packing",
      //       path: "strip-packing",
      //       cover_image: strip_packing_cover,
      //       dataComponent: <StripPackingData />,
      //     },
      //     {
      //       title: "Cartoning",
      //       identifier: "cartoning",
      //       path: "cartoning",
      //       cover_image: cartoning_cover,
      //       dataComponent: "cartoning",
      //     },
      //     {
      //       title: "End of Line solution",
      //       identifier: "end_of_line_solution",
      //       path: "end-of-line-solution",
      //       cover_image: end_of_line_solution_cover,
      //       dataComponent: "end_of_line_solution",
      //     },
      //     {
      //       title: "Labelling",
      //       identifier: "labelling",
      //       path: "labelling",
      //       cover_image: labelling_cover,
      //       dataComponent: <LabellingData />,
      //     },
      //   ],
      // },
      {
        title: "Liquid Manufacturing",
        identifier: "liquid_manufacturing",
        path: "liquid-manufacturing",
        subroutes: [
          {
            title: "Liquid manufacturing plant",
            identifier: "liquid_manufacturing_plant",
            path: "liquid-manufacturing-plant",
            cover_image: bottle_filling_cover,
            dataComponent: <LiquidManufacturingPlantData />,
          },
          {
            title: "Ointment Manufacturing Plants",
            identifier: "ointment_manufacturing_plants",
            path: "ointment-manufacturing-plants",
            cover_image: bottle_filling_cover,
            dataComponent: <OintmentManufacturingPlantsData />,
          },
          {
            title: "Washing & Air jet Cleaning machine",
            identifier: "washing_and_air_jet_cleaning_machine",
            path: "washing-and-air-jet-cleaning-machine",
            cover_image: bottle_filling_cover,
            dataComponent: <WashingAirJetCleaningMachineData />,
          },
          {
            title: "Powder Filling",
            identifier: "powder_filling",
            path: "powder-filling",
            cover_image: bottle_filling_cover,
            dataComponent: <PowderFillingData />,
          },
        ],
      },
      {
        title: "Liquid Filling Machine",
        identifier: "liquid_filling_machine",
        path: "liquid-filling-machine",
        subroutes: [
          {
            title: "Volumetric Liquid Filling",
            identifier: "volumetric_Liquid_filling",
            path: "volumetric-Liquid-filling",
            cover_image: bottle_filling_cover,
            dataComponent: <VolumetricLiquidFillingMachinesData />,
          },
          {
            title: "Gear Pump Liquid Filling",
            identifier: "gear_pump_liquid_filling",
            path: "gear-pump-liquid-filling",
            cover_image: bottle_filling_cover,
            dataComponent: <GearPumpLiquidFillingMachineData />,
          },
          {
            title: "Rinsing, Filling and Capping",
            identifier: "rinsing_filling_and_capping",
            path: "rinsing-filling-and-capping",
            cover_image: bottle_filling_cover,
            dataComponent: <RinsingFillingAndCappingMachineData />,
          },
          {
            title: "Time-Based Liquid Filling",
            identifier: "time_based_liquid_filling",
            path: "time-based-liquid-filling",
            cover_image: bottle_filling_cover,
            dataComponent: <TimeBasedLiquidFillingMachineData />,
          },
          {
            title: "Weigh Metric Oil Filling",
            identifier: "weigh_metric_oil_filling",
            path: "weigh-metric-oil-filling",
            cover_image: bottle_filling_cover,
            dataComponent: <WeighMetricOilFillingMachineData />,
          },
          {
            title: "Viscous Pneumatic Piston Filling",
            identifier: "viscous_pneumatic_piston_filling",
            path: "viscous-pneumatic-piston-filling",
            cover_image: bottle_filling_cover,
            dataComponent: <ViscousPneumaticPistonFillingMachineData />,
          },
          {
            title:
              "Volumetric Liquid Vial Filling with Rubber Stoppering",
            identifier: "volumetric_liquid_vial_filling",
            path: "volumetric-liquid-vial-filling",
            cover_image: bottle_filling_cover,
            dataComponent: (
              <VolumetricLiquidVialFillingwithRubberStopperingMachineData />
            ),
          },
          {
            title: "Single Head Tube Filling",
            identifier: "single_head_tube_filling",
            path: "single-head-tube-filling",
            cover_image: bottle_filling_cover,
            dataComponent: <SingleHeadTubeFillingMachineData />,
          },
          {
            title: "Double Head Tube Filling",
            identifier: "double_head_tube_filling",
            path: "double-head-tube-filling",
            cover_image: bottle_filling_cover,
            dataComponent: <DoubleHeadTubeFillingMachineData />,
          },
          {
            title: "Semi-Automatic Tube filling",
            identifier: "semi_automatic_tube_filling",
            path: "semi-automatic-tube-filling",
            cover_image: bottle_filling_cover,
            dataComponent: <SemiAutomaticTubeFillingMachineData />,
          },
        ],
      },
      {
        title: "Screw/ROPP Capping",
        identifier: "screw_ropp_capping",
        path: "screw-ropp-capping",
        subroutes: [
          {
            title: "Vial Capping Machine",
            identifier: "vial_capping_machine",
            path: "vial-capping-machine",
            cover_image: bottle_filling_cover,
            dataComponent: <VialCappingMachineData />,
          },
          {
            title: "Linear Screw Capping Machine",
            identifier: "linear_screw_capping_machine",
            path: "linear-screw-capping-machine",
            cover_image: bottle_filling_cover,
            dataComponent: <LinearScrewCappingMachineData />,
          },
          {
            title: "Screw Capping Machine",
            identifier: "screw_capping_machine",
            path: "screw-capping-machine",
            cover_image: bottle_filling_cover,
            dataComponent: <ScrewCappingMachineData />,
          },
        ],
      },
      {
        title: "Labeling",
        identifier: "labeling",
        path: "labeling",
        subroutes: [
          {
            title: "Automatic Round Bottle Labeling machine",
            identifier: "automatic_round_bottle_labeling_machine",
            path: "automatic-round-bottle-labeling-machine",
            cover_image: labelling_cover,
            dataComponent: <AutomaticRoundBottleLabelingMachineData />,
          },
          {
            title: "Automatic Single Side (Sticker) Labelling machine",
            identifier: "automatic_single_side_sticker_labelling_machine",
            path: "automatic-single-side-sticker-labelling-machine",
            cover_image: labelling_cover,
            dataComponent: <AutomaticSingleSideStickerLabellingMachineData />,
          },
          {
            title: "Automatic Double Side (Sticker) Labelling machine",
            identifier: "automatic_double_side_sticker_labelling_machine",
            path: "automatic-double-side-sticker-labelling-machine",
            cover_image: labelling_cover,
            dataComponent: <AutomaticDoubleSideStickerLabellingMachineData />,
          },
        ]
      }
      // {
      //   title: "Semi Solid",
      //   identifier: "semi_solid",
      //   path: "semi-solid",
      //   subroutes: [
      //     {
      //       title: "Tube filling",
      //       identifier: "tube_filling",
      //       path: "tube-filling",
      //       cover_image: tube_filling_cover,
      //       dataComponent: <TubeFillingData />,
      //     },
      //   ],
      // },
      // {
      //   title: "Consumables",
      //   identifier: "consumables",
      //   path: "consumables",
      //   subroutes: [
      //     {
      //       title: "Spare parts, format parts and change parts",
      //       identifier: "spare_parts",
      //       path: "spare-parts",
      //       cover_image: spare_parts_cover,
      //       dataComponent: "consumables",
      //     },
      //   ],
      // },
    ],
  },

  // ==================== CLIENTS ====================
  {
    title: "Clients",
    identifier: "clients",
    path: "clients",
    cover_image: null,
    component: <OurClients />,
  },

  // ==================== CONTACT US ====================
  {
    title: "Contact Us",
    // subtitle: "",
    identifier: "contact_us",
    path: "contact-us",
    cover_image: contact_us_cover,
    component: <ContactUs />,
  },

  // ==================== UPDATES ====================
  // {
  //   title: "Updates",
  //   identifier: "updates",
  //   path: "updates",
  //   subroutes: [
  //     {
  //       title: "Blogs",
  //       identifier: "blogs",
  //       path: "blogs",
  //       cover_image: blogs_cover,
  //       component: <Blogs />,
  //     },
  //     {
  //       title: "Media",
  //       identifier: "media",
  //       path: "media",
  //       cover_image: blogs_cover,
  //       component: <Media />,
  //     },
  //   ],
  // },
];
